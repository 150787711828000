import PreviewScene from "@/phaser/scenes/PreviewScene";
import { SCREEN_WIDTH, SCREEN_HEIGHT } from "@/constants";
import LoadingScene from "./scenes/LoadingScene";

const gameConfig: Phaser.Types.Core.GameConfig = {
  type: Phaser.WEBGL,
  parent: "phaser-preview-container",
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
  backgroundColor: "#6A6A6A",
  scene: [LoadingScene, PreviewScene],
  dom: {
    createContainer: true,
  },
  scale: {
    parent: 'phaser-preview-container',
    mode: Phaser.Scale.FIT, // also see setAspectRatio() in scene
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
};

export default gameConfig;
