import { Select } from "@appkit4/react-components";
import { IAction, IRoomObject } from "escape-rooms-types/types/game";
import { useGameStore } from "@/store/game-store";
import { IRoomEditorObject } from "@/types/game";
import XPField from "../shared/XPField";
import styles from "../ObjectConfigAction.module.scss";
interface IActionChangeSelfProps {
  action: IAction;
  selected: IRoomObject;
  // updateActionChangeObjectPayload: () => void;
  updateActionProperties: (payload: Partial<IAction>) => void;
}

const ActionChangeSelf = ({
  action,
  selected,
  updateActionProperties,
}: IActionChangeSelfProps) => {
  const updateActionChangeObjectPayload = useGameStore(
    (state) => state.updateActionChangeObjectPayload
  );

  return (
    <>
      <div className="col-4">
        <span className="input-label">What does it change to?</span>
        <Select className={styles["select-dropdown"]}
          data={Object.values(selected.asset.states || {}).map((state) => ({
            label: state.name,
            value: state.name,
          }))}
          disabled={selected.asset.states === undefined}
          placeholder=""
          value={action?.changeObjectPayload?.newProperties?.currentState}
          onSelect={(e) =>
            updateActionChangeObjectPayload(selected.ref, action.ref, {
              ref: "",
              targetObjectRef: selected.ref,
              newProperties: {
                currentState: e,
                isVisible: true,
              },
            })
          }
        />
      </div>
      <div className="col-4">
        <span className="input-label">
          What is the visibility of this asset?
        </span>
        <Select className={styles["select-dropdown"]}
          placeholder=""
          data={[
            { label: "Visible", value: "visible" },
            { label: "Hidden", value: "hidden" },
          ]}
          value={
            action?.changeObjectPayload?.newProperties?.isVisible == null
              ? undefined
              : action?.changeObjectPayload?.newProperties?.isVisible
              ? "visible"
              : "hidden"
          }
          onSelect={(e) => {
            updateActionChangeObjectPayload(selected.ref, action.ref, {
              targetObjectRef: selected.ref,
              newProperties: {
                isVisible: e === "visible",
              },
            });
          }}
        />
      </div>
      <XPField
        action={action}
        updateActionProperties={(payload) => updateActionProperties(payload)}
      />
    </>
  );
};

export default ActionChangeSelf;
