import UIScene from "@/phaser/scenes/UIScene";
import Phaser from "phaser";

const BG_COLORS = {
  primary: 0x114ba3,
  danger: 0xdf5454,
  tertiary: 0x21812d,
  disabled: 0xd1d1d1,
};

export default class TextButton extends Phaser.GameObjects.Container {
  scene: UIScene;
  type: "primary" | "danger" | "tertiary" | "disabled" = "primary";
  callback: () => void;
  text: Phaser.GameObjects.Text;
  background: Phaser.GameObjects.Graphics;
  hitbox;

  constructor(
    scene: UIScene,
    x: number,
    y: number,
    text: string = "BUTTON",
    type: "primary" | "danger" | "tertiary" | "disabled" = "primary",
    callback: () => void
  ) {
    super(scene, x, y);
    this.scene = scene;
    this.type = type;
    this.callback = callback;

    this.text = new Phaser.GameObjects.Text(scene, 0, 0, text.toUpperCase(), {
      color: type === "disabled" ? "#696969" : "#FFFFFF",
      fontFamily: "Nunito-Bold",
      fontSize: "20px",
    }).setOrigin();

    if (type != "disabled") {
      this.text.setShadow(1, 2);
    }

    let width = this.text.width + 40;
    let height = this.text.height + 24;
    this.background = new Phaser.GameObjects.Graphics(scene)
      .fillStyle(BG_COLORS[type], 1)
      .fillRoundedRect(-width / 2, -height / 2, width, height, 10);

    this.add(this.background);
    this.add(this.text);

    if (type === "disabled") {
      return;
    }

    this.hitbox = new Phaser.GameObjects.Rectangle(
      scene,
      this.text.x,
      this.text.y,
      width,
      height
    ).setOrigin(0.5, 0.5);
    this.add(this.hitbox);

    this.hitbox
      .setInteractive()
      .on("pointerover", () => this.handleOver(this))
      .on("pointerout", () => this.handleOut(this))
      .on("pointerdown", () => callback());
  }

  addToScene() {
    this.scene.add.existing(this);
  }

  private handleOut(container: Phaser.GameObjects.Container) {
    document.body.style.cursor = "default";
    container.setAlpha(1);
  }

  private handleOver(container: Phaser.GameObjects.Container) {
    container.setAlpha(0.5);
  }
}
