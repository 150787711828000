import UIScene from "@/phaser/scenes/UIScene";
import BaseModal from "./BaseModal";
import Phaser from "phaser";
import BaseRoomObject from "../RoomObject/BaseRoomObject";
import EventBridge from "@/utils/EventBridge";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";

export default class ItemModal extends BaseModal {
  message;
  image;

  constructor(
    scene: UIScene,
    text: string,
    object: BaseRoomObject,
    env: string,
    executedActionId?: string
  ) {
    let continueCallback: () => void;
    if (env === "preview") {
      continueCallback = () =>
        EventBridge.emit("ui.completeItemModal", object);
    } else {
      continueCallback = () => {
        const game = this.scene.game as PhaserGameClass;
        game.socketEmit("pickupItem", {
          sessionId: game.session._id,
          sourceObject: object.gameObject,
          executedActionId,
        });
      };
    }
    super(scene, "PICK UP ITEM", continueCallback);

    this.message = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      140,
      text,
      {
        fontFamily: "Roboto-Regular",
        fontSize: "20px",
        color: "#FFFFFF",
        wordWrap: { width: 1000 },
        align: "center",
      }
    )
      .setOrigin(0.5, 0.5)
      .setLineSpacing(8);

    this.image = new Phaser.GameObjects.Image(
      scene,
      scene.renderer.width / 2,
      Math.max(
        this.message.getBottomCenter().y + 30,
        scene.renderer.height / 2 - 40
      ),
      object.getThumbnailFromState(object.currentState)
    ).setOrigin(0.5, 0.5);

    this.image.displayHeight = 270;
    this.image.scaleX = this.image.scaleY;

    this.add(scene.add.existing(this.message));
    this.add(scene.add.existing(this.image));
  }
}
