import api from "@/api";
import { IGameSession } from "escape-rooms-types/types/game";

export function getGameSessions(
  page: number = 1,
  limit: number = 8,
  sortBy: string = "_id",
  sortOrder: number = 1,
  searchString: string,
  status: string,
) {
  const query = new URLSearchParams({
    page: ((page - 1) * limit).toString(),
    limit: limit.toString(),
    sortOrder: sortOrder.toString(),
    sortBy,
  });

  if (searchString.length > 0) {
    query.append("query", searchString);
  }
  if (status.length > 0) {
    query.append("status", status);
  }

  return api.get(
    `/gamesessions${
      query.toString().length > 0 ? "?" + query.toString() : ""
    }`
  );
}

export function getGameSession(sessionId: string) {
  return api.get(`/gamesessions/${sessionId}`);
}

export function createGameSession(session: Partial<IGameSession>) {
  return api.post(`/gamesessions`, session);
}

export function updateGameSession(
  sessionId: string,
  session: Partial<IGameSession>
) {
  return api.put(`/gamesessions/${sessionId}`, session);
}

export function deleteGameSession(sessionId: string) {
  return api.delete(`/gamesessions/${sessionId}`);
}

export function startSession(sessionId: string) {
  return api.post(`/gamesessions/${sessionId}/startSession`);
}
