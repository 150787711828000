import { useLocation } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react";
import { getUserFromToken } from "@/api/auth";
import { useContext } from "react";

interface IUserInfoContext {
  id?: string;
  firstName?: string;
  lastName?: string;
  signOut: () => void;
}

const UserInfoContext = React.createContext<IUserInfoContext>({} as IUserInfoContext);

export const UserInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const { id, firstName, lastName, signOut } = useProvideUserInfo(pathname);

  return (
    <UserInfoContext.Provider value={{ id, firstName, lastName, signOut }}>
      {children}
    </UserInfoContext.Provider>
  );
};

const useProvideUserInfo = (returnPath: string) => {
  const [id, setId] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  const signOut = () => {
    localStorage.removeItem("id_token");
    setId(undefined);
    setFirstName(undefined);
    setLastName(undefined);
  };

  useEffect(() => {
    getUserFromToken()
      .then((res) => {
        const user = res.data;
        setId(user._id);
        setFirstName(user.firstName);
        setLastName(user.lastName);
      })
      .catch((err) => {
        console.error(err)
      });
  }, []);

  return { id, firstName, lastName, signOut };
};

const useUserInfo = () => {
  return useContext(UserInfoContext);
};

export default useUserInfo;
