import {
  createGameSession,
  deleteGameSession,
  getGameSessions,
} from "@/api/session";
import { Button } from "@appkit4/react-components";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./GameSessions.module.scss";

const GameSessions = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();

  const [sessions, setSessions] = useState<
    Array<{ name: string; _id: string }>
  >([]);

  const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(
    null
  );

  const getGameSessionsQuery = useQuery({
    queryKey: ["getGameSessions"],
    queryFn: () => getGameSessions(),
    onSuccess: (res) => {
      setSessions(
        res.data.data.filter((session: any) => session.gameId === gameId)
      );
    },
  });

  const deleteGameSessionQuery = useQuery({
    queryKey: ["deleteGameSessions"],
    queryFn: () => deleteGameSession(sessionIdToDelete!),
    onSuccess: (res) => {
      getGameSessionsQuery.refetch();
    },
    enabled: false,
  });

  const addGameSessionQuery = useQuery({
    queryKey: ["createGameSessions"],
    queryFn: () => createGameSession({ gameId: gameId! }),
    onSuccess: (res) => {
      getGameSessionsQuery.refetch();
    },
    enabled: false,
  });

  const openGameSession = (sessionId: string) => {
    return navigate(`/games/${gameId}/sessions/${sessionId}`);
  };

  const deleteSession = (sessionId: string | null) => {
    if (!sessionId) {
      return;
    }

    setSessionIdToDelete(sessionId);
    setTimeout(() => {
      deleteGameSessionQuery.refetch();
    }, 500);
  };

  const addSession = () => {
    addGameSessionQuery.refetch();
  };

  return (
    <div className="ap-container">
      <div className="row">
        <div className="col-6">
          <div className={styles.room_container}>
            {sessions.map((session) => (
              <div className={`${styles.room_card}`}>
                <div className={styles.room_card_header}>
                  <img src="/icons/drag.svg" />
                  <span className={`ap-typography-3 ap-font-weight-2`}>
                    {session._id}
                    {/* <FormattedMessage
                  id="location.room"
                  values={{ roomNumber: `${room.name}` }}
                /> */}
                  </span>
                </div>
                <div className={styles.room_card_buttons}>
                  <Button
                    className="Appkit4-icon icon-delete-outline"
                    kind="negative"
                    onClick={() => {
                      deleteSession(session._id);
                    }}
                  />
                  <Button
                    className="Appkit4-icon icon-play-outline"
                    kind="primary"
                    onClick={() => {
                      openGameSession(session._id);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="ap-mt-spacing-4">
            <Button onClick={() => addSession()} icon="icon-plus-outline">
              Add Game Session
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameSessions;
