import { IQuestion } from "escape-rooms-types/types/game";
import QuestionnairePlayer from "../questionnaire/QuestionnairePlayer";
import UIScene from "@/phaser/scenes/UIScene";
import IconButton from "../../ui/buttons/IconButton";
import GameScene from "@/phaser/scenes/GameScene";
import PreviewScene from "@/phaser/scenes/PreviewScene";
import QuestionnaireModal from "../questionnaire/QuestionnaireModal";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import ChallengeModal from "../challenge/ChallengeModal";
import TextButton from "../../ui/buttons/TextButton";
import { ScrollBar } from "../../ui/ScrollBar";

const titleBackgroundWidth = 527;

export default class MultipleChoiceQuestion extends Phaser.GameObjects.Group {
  scene: UIScene;
  sourceScene: GameScene | PreviewScene;
  parentModal: QuestionnaireModal | ChallengeModal;
  questionnaire?: QuestionnaireModal | null;
  challenge?: ChallengeModal | null;
  question: IQuestion;
  title: string;
  choiceStrings: string[];
  choices: Choice[];
  answer: number;
  questionContainer: Phaser.GameObjects.Container;
  questionText: Phaser.GameObjects.Text;
  background: Phaser.GameObjects.Graphics;
  choiceContainer;
  choiceBG;
  choiceMask?: Phaser.GameObjects.Graphics;
  selectedAnswer?: number;
  submitButtonDisabled: TextButton;
  submitButtonEnabled: TextButton;
  waitingForOtherPlayersMessage: Phaser.GameObjects.Container;
  incorrectAnswerMessage: Phaser.GameObjects.Container;
  inputDisabled: boolean;
  markAllAsFinished: () => void;
  waitingForOtherPlayers: boolean;

  isIndividualChallenge: boolean;
  assetImage?: Phaser.GameObjects.Image;

  choicesScrollOffet: number = 0;
  scrollBar: ScrollBar;

  constructor(
    scene: UIScene,
    parentModal: QuestionnaireModal | ChallengeModal,
    question: IQuestion,
    markAllAsFinished: () => void,
    assetTexture?: string
  ) {
    super(scene);
    this.parentModal = parentModal;
    this.inputDisabled = false;

    if (parentModal instanceof QuestionnaireModal) {
      this.questionnaire = parentModal;
      this.isIndividualChallenge = false;
    } else {
      this.challenge = parentModal;
      this.isIndividualChallenge = true;
    }

    this.scene = scene;
    this.sourceScene = scene.sourceScene;
    this.question = question;
    this.markAllAsFinished = markAllAsFinished;
    this.title = question.title!;
    this.choiceStrings = question.choices!.answers;
    this.answer = question.choices!.correctAnswer;
    this.waitingForOtherPlayers = false;

    if (assetTexture && this.isIndividualChallenge) {
      this.assetImage = new Phaser.GameObjects.Image(
        scene,
        scene.renderer.width / 2,
        125,
        assetTexture
      ).setOrigin(0.5, 0);
      const assetImageRatio = this.assetImage.width / this.assetImage.height;
      this.assetImage.setDisplaySize(130 * assetImageRatio, 130);
      this.add(this.assetImage, true);
    }

    this.questionContainer = new Phaser.GameObjects.Container(scene, 190, 300);

    this.questionText = new Phaser.GameObjects.Text(scene, 0, 0, this.title, {
      fontFamily: "Roboto-Regular",
      fontSize: "20px",
      color: "#000000",
      wordWrap: { width: titleBackgroundWidth - 30, useAdvancedWrap: true },
    }).setLineSpacing(8);

    this.background = new Phaser.GameObjects.Graphics(scene)
      .fillStyle(0xffffff, 1)
      .fillRoundedRect(
        -18,
        -20,
        titleBackgroundWidth,
        this.questionText.height + 40,
        10
      );
    this.questionContainer.add(this.background);
    this.questionContainer.add(this.questionText);
    scene.add.existing(this.questionContainer);
    this.add(this.questionContainer);

    this.choiceContainer = new Phaser.GameObjects.Container(scene, 738, 280);
    const lineCounter: number[] = [];
    this.choices = this.choiceStrings.map((choice, index) => {
      let heightOfPreviousChoices = 0;
      lineCounter.forEach((lineCount) => {
        switch (lineCount) {
          case 1:
            heightOfPreviousChoices += 60;
            break;
          case 2:
            heightOfPreviousChoices += 83;
            break;
          case 3:
            heightOfPreviousChoices += 106;
            break;
        }
      });
      const c = new Choice(
        scene,
        this,
        0,
        heightOfPreviousChoices,
        choice,
        index,
        () => this.unselectChoices(this),
        (answer: number) => this.selectAnswer(this, answer)
      ).setInteractive();
      lineCounter.push(c.lines);
      this.choiceContainer.add(c);
      return c;
    });

    this.choiceContainer.setInteractive(
      new Phaser.Geom.Rectangle(0, 0, titleBackgroundWidth, 305),
      Phaser.Geom.Rectangle.Contains
    );
    this.add(this.choiceContainer);
    scene.add.existing(this.choiceContainer);

    const choiceMask = new Phaser.GameObjects.Graphics(this.scene)
      .fillStyle(0xffffff, 0)
      .fillRect(738, 280, titleBackgroundWidth, 305);
    let mask = choiceMask.createGeometryMask();
    this.choiceContainer.setMask(mask);
    this.add(choiceMask);
    scene.add.existing(choiceMask);

    const firstChoiceTopY = this.choices[0].getBounds().top;
    const lastChoiceBottomY = this.choices.at(-1)!.getBounds().bottom;
    const choicesHeight = lastChoiceBottomY - firstChoiceTopY;

    const scrollYBound = Math.max(0, choicesHeight - 300 - this.choices.length * 4);

    this.scrollBar = new ScrollBar(
      scene,
      765 + titleBackgroundWidth,
      286,
      300,
      16,
      true
    );
    this.scrollBar.setOnScroll((y: number) => {
      this.setScroll(y);
    });
    this.scrollBar.setBounds(0, scrollYBound);
    this.add(this.scrollBar);
    scene.add.existing(this.scrollBar);

    this.submitButtonDisabled = new TextButton(
      scene,
      scene.renderer.width / 2,
      680,
      "SUBMIT",
      "disabled",
      () => {}
    );
    this.submitButtonEnabled = new TextButton(
      scene,
      scene.renderer.width / 2,
      680,
      "SUBMIT",
      "tertiary",
      () => this.submitAnswer(this)
    ).setVisible(false);
    scene.add.existing(this.submitButtonDisabled);
    scene.add.existing(this.submitButtonEnabled);
    this.add(this.submitButtonDisabled);
    this.add(this.submitButtonEnabled);

    this.waitingForOtherPlayersMessage = new Phaser.GameObjects.Container(
      scene,
      scene.renderer.width / 2,
      680 - 20
    );
    this.waitingForOtherPlayersMessage
      .add(
        this.isIndividualChallenge
          ? [
              new Phaser.GameObjects.Text(scene, 0, 0, "CORRECT!", {
                fontFamily: "Nunito-Bold",
                fontSize: "35px",
              }).setOrigin(0.5, 0),
            ]
          : [
              new Phaser.GameObjects.Text(scene, 0, 0, "CORRECT!", {
                fontFamily: "Nunito-Bold",
                fontSize: "35px",
              }).setOrigin(0.5, 0.5),
              new Phaser.GameObjects.Text(
                scene,
                0,
                40,
                "Waiting for all players to complete",
                { fontFamily: "Nunito-Bold", fontSize: "19px" }
              ).setOrigin(0.5, 0.5),
            ]
      )
      .setVisible(false);
    scene.add.existing(this.waitingForOtherPlayersMessage);
    this.add(this.waitingForOtherPlayersMessage);

    this.incorrectAnswerMessage = new Phaser.GameObjects.Container(
      scene,
      scene.renderer.width / 2,
      680 - 20
    );
    this.incorrectAnswerMessage
      .add([
        new Phaser.GameObjects.Text(scene, 0, 0, "Incorrect answer", {
          fontFamily: "Nunito-Bold",
          fontSize: "25px",
        }).setOrigin(0.5, 0),
        new Phaser.GameObjects.Text(scene, 0, 50, "Please try again", {
          fontFamily: "Roboto-Medium",
          fontSize: "19px",
        }).setOrigin(0.5, 0.5),
      ])
      .setVisible(false);
    scene.add.existing(this.incorrectAnswerMessage);
    this.add(this.incorrectAnswerMessage);
  }

  enableSubmitButton() {
    this.submitButtonDisabled.setVisible(false);
    this.submitButtonEnabled.setVisible(true);
  }

  disableSubmitButton() {
    this.submitButtonDisabled.setVisible(true);
    this.submitButtonEnabled.setVisible(false);
  }

  hideSubmitButton() {
    this.submitButtonDisabled.setVisible(false);
    this.submitButtonEnabled.setVisible(false);
  }

  disableInput() {
    this.inputDisabled = true;
  }

  enableInput() {
    this.inputDisabled = false;
  }

  unselectChoices(question: MultipleChoiceQuestion) {
    question.choices.forEach((choice) => choice.unselect());
  }

  selectAnswer(question: MultipleChoiceQuestion, answer: number) {
    question.selectedAnswer = answer;
    question.enableSubmitButton();
  }

  submitAnswer(question: MultipleChoiceQuestion) {
    const isCorrectAnswer = question.selectedAnswer === question.answer;

    if (this.isIndividualChallenge && isCorrectAnswer) {
      question.choices[question.selectedAnswer].markCorrect();
      question.setChoicesUninteractive(question);
      question.hideSubmitButton();
      question.markAllAsFinished();
      return;
    }
    if (this.isIndividualChallenge && !isCorrectAnswer) {
      this.handleWrongAnswer(this);
      return;
    }

    if (this.scene.env === "preview" && isCorrectAnswer) {
      question.choices[question.selectedAnswer].markCorrect();
      question.setChoicesUninteractive(question);
      question.hideSubmitButton();
      question.waitingForOtherPlayersMessage.setVisible(true);
      setTimeout(() => {
        question.markAllAsFinished();
      }, 1500);
      return;
    }
    if (this.scene.env === "preview" && !isCorrectAnswer) {
      this.handleWrongAnswer(question);
      return;
    }
    if (this.scene.env === "game" && isCorrectAnswer) {
      (this.sourceScene as GameScene).game.socketEmit("markUserAsDone", {
        sessionId: (this.sourceScene as GameScene).session!.id,
        questionId: this.question._id,
        roomId: (this.sourceScene as GameScene).session!.activeRoomId,
        roomObjectId: this.questionnaire!.sourceObject._id,
        actionId: this.questionnaire!.actionId,
      });
      (this.sourceScene as GameScene).game.socket.on(
        "markUserAsDone",
        (payload) => {
          if (
            payload.sourcePlayerId === (this.sourceScene as GameScene).user!.id
          ) {
            question.choices[question.selectedAnswer].markCorrect();
            question.setChoicesUninteractive(question);
            question.waitingForOtherPlayersMessage.setVisible(true);
            question.hideSubmitButton();
            this.waitingForOtherPlayers = true;
          }
        }
      );
      question.setChoicesUninteractive(question);
      question.disableSubmitButton();
      question.hideSubmitButton();
      setTimeout(() => {
        if (this.waitingForOtherPlayers) {
          return;
        }
        question.enableSubmitButton();
        question.setChoicesInteractive(question);
      }, 5000);
      return;
    }
    if (this.scene.env === "game" && !isCorrectAnswer) {
      this.handleWrongAnswer(question);
      (this.sourceScene as GameScene).game.socketEmit("incorrectPlayerAnswer", {
        sessionId: (this.sourceScene as GameScene).session!.id,
        playerId: (this.sourceScene as GameScene).user!.id,
      });
      return;
    }
  }

  handleWrongAnswer(question: MultipleChoiceQuestion) {
    question.choices[question.selectedAnswer!].markIncorrect();
    question.choices[question.selectedAnswer!].disableInteractive();
    question.hideSubmitButton();
    this.parentModal.incremenentAttemptsTaken();
    question.incorrectAnswerMessage.setVisible(true);
    this.disableInput();

    setTimeout(() => {
      question.incorrectAnswerMessage.setVisible(false);
      question.disableSubmitButton();
      this.enableInput();
    }, 2000);
  }

  setChoiceUninteractive(
    question: MultipleChoiceQuestion,
    choiceIndex: number
  ) {
    question.choices[choiceIndex].disableInteractive();
  }

  setChoicesUninteractive(question: MultipleChoiceQuestion) {
    question.choices.forEach((choice) => choice.disableInteractive());
  }

  setChoicesInteractive(question: MultipleChoiceQuestion) {
    question.choices.forEach((choice) => choice.setInteractive());
  }

  setScroll(y: number) {
    const multiplier = 1.2;
    this.choiceContainer.setY(280 - y * multiplier);
  }
}

class Choice extends Phaser.GameObjects.Container {
  backgroundDefault;
  backgroundFocus;
  backgroundCorrect;
  backgroundIncorrect;
  whiteBullet;
  textWhite;
  blackBullet;
  textBlack;
  lines;
  index;
  choiceString;
  parentQuestion: MultipleChoiceQuestion;
  unselectChoices: () => void;
  selectAnswer: (answer: number) => void;

  constructor(
    scene: UIScene,
    parentQuestion: MultipleChoiceQuestion,
    x: number,
    y: number,
    choiceString: string,
    index: number,
    unselectChoices: () => void,
    selectAnswer: (answer: number) => void
  ) {
    super(scene, x, y);
    this.index = index;
    this.choiceString = choiceString;
    this.parentQuestion = parentQuestion;
    this.unselectChoices = unselectChoices;
    this.selectAnswer = selectAnswer;

    this.whiteBullet = new Phaser.GameObjects.Text(
      scene,
      0,
      0,
      `${String.fromCharCode(index + 65)}.`,
      {
        fontFamily: "Roboto-Medium",
        fontSize: "20px",
        color: "#FFFFFF",
        wordWrap: { width: 500, useAdvancedWrap: true },
      }
    )
      .setOrigin(0, 0)
      .setPosition(22, 13)
      .setVisible(true);

    this.textWhite = new Phaser.GameObjects.Text(
      scene,
      54,
      13,
      `${choiceString}`,
      {
        fontFamily: "Roboto-Medium",
        fontSize: "20px",
        color: "#FFFFFF",
        wordWrap: { width: 450, useAdvancedWrap: true },
      }
    )
      .setOrigin(0, 0)
      .setVisible(true);

    this.blackBullet = new Phaser.GameObjects.Text(
      scene,
      0,
      0,
      `${String.fromCharCode(index + 65)}.`,
      {
        fontFamily: "Roboto-Medium",
        fontSize: "20px",
        color: "#000000",
        wordWrap: { width: 500, useAdvancedWrap: true },
      }
    )
      .setOrigin(0, 0)
      .setPosition(22, 13)
      .setVisible(false);

    this.textBlack = new Phaser.GameObjects.Text(
      scene,
      54,
      13,
      `${choiceString}`,
      {
        fontFamily: "Roboto-Medium",
        fontSize: "20px",
        color: "#000000",
        wordWrap: { width: 450, useAdvancedWrap: true },
      }
    )
      .setOrigin(0, 0)
      .setVisible(false);

    const textHeight = this.textWhite.height;
    this.lines = Math.ceil(textHeight / 23);

    this.backgroundDefault = new Phaser.GameObjects.Image(
      scene,
      0,
      0,
      `answer-box-${this.lines}-default`
    )
      .setOrigin(0, 0)
      .setVisible(true)
      .setScale(0.5);

    this.backgroundFocus = new Phaser.GameObjects.Image(
      scene,
      0,
      0,
      `answer-box-${this.lines}-selected`
    )
      .setOrigin(0, 0)
      .setVisible(false)
      .setScale(0.5);

    this.backgroundCorrect = new Phaser.GameObjects.Image(
      scene,
      0,
      0,
      `answer-box-${this.lines}-correct`
    )
      .setOrigin(0, 0)
      .setVisible(false)
      .setScale(0.5);

    this.backgroundIncorrect = new Phaser.GameObjects.Image(
      scene,
      0,
      0,
      `answer-box-${this.lines}-incorrect`
    )
      .setOrigin(0, 0)
      .setVisible(false)
      .setScale(0.5);

    this.add(this.backgroundDefault);
    this.add(this.backgroundFocus);
    this.add(this.backgroundCorrect);
    this.add(this.backgroundIncorrect);

    this.add(this.whiteBullet);
    this.add(this.textWhite);
    this.add(this.blackBullet);
    this.add(this.textBlack);

    this.setInteractive(this.backgroundDefault, Phaser.Geom.Rectangle.Contains)
      .on("pointerover", () => {
        this.handleMouseOver();
      })
      .on("pointerout", () => {
        this.handleMouseOut();
      })
      .on("pointerdown", () => this.handleMouseClick());
  }

  handleMouseOver() {
    if (this.parentQuestion.inputDisabled) {
      return;
    }
    if (this.getBounds().bottom > 670) {
      return;
    }
    const xBoundary = 730 + titleBackgroundWidth;
    if (this.parentQuestion.sourceScene.game.input.mousePointer!.x > xBoundary) {
      return
    }
    this.setAlpha(0.5);
  }

  handleMouseOut() {
    if (this.parentQuestion.inputDisabled) {
      return;
    }
    this.setAlpha(1);
    document.body.style.cursor = "default";
  }

  handleMouseClick() {
    if (this.parentQuestion.inputDisabled) {
      return;
    }
    if (this.getBounds().bottom > 670) {
      return;
    }
    const xBoundary = 730 + titleBackgroundWidth;
    if (this.parentQuestion.sourceScene.game.input.mousePointer!.x > xBoundary) {
      return
    }
    this.select();
  }

  select() {
    this.unselectChoices();
    this.backgroundDefault.setVisible(false);
    this.backgroundFocus.setVisible(true);
    this.textWhite.setVisible(false);
    this.textBlack.setVisible(true);
    this.textBlack.setVisible(true);
    this.whiteBullet.setVisible(false);
    this.blackBullet.setVisible(true);
    this.selectAnswer(this.index);
  }

  unselect() {
    this.backgroundDefault.setVisible(true);
    this.backgroundFocus.setVisible(false);
    this.textWhite.setVisible(true);
    this.textBlack.setVisible(false);
    this.whiteBullet.setVisible(true);
    this.blackBullet.setVisible(false);
  }

  markCorrect() {
    this.backgroundFocus.setVisible(false);
    this.backgroundCorrect.setVisible(true);
    this.textWhite.setVisible(false);
    this.textBlack.setVisible(true);
    this.whiteBullet.setVisible(false);
    this.blackBullet.setVisible(true);
  }

  markIncorrect() {
    this.backgroundFocus.setVisible(false);
    this.backgroundIncorrect.setVisible(true);
    this.textBlack.setVisible(false);
    this.textWhite.setVisible(true);
    this.blackBullet.setVisible(false);
    this.whiteBullet.setVisible(true);
  }
}
