import UIScene from "@/phaser/scenes/UIScene";
import { Display } from "phaser";
import TextButton from "../ui/buttons/TextButton";
import IconButton from "../ui/buttons/IconButton";
import BaseRoomObject from "../RoomObject/BaseRoomObject";

const BACKGROUND_WIDTH = 600;
const WORD_WRAP = 550;
const BUTTON_TOP_MARGIN = 60;
const BACKGROUND_PADDING_Y = 60;

export default class MessagePopup extends Phaser.GameObjects.Container {
  scene: UIScene;
  sourceObject: BaseRoomObject;
  textObject: Phaser.GameObjects.Text;
  background: Phaser.GameObjects.Image;
  continueBtn?: TextButton;
  callback: () => void;
  closeBtn?: IconButton;
  maskObject?: Phaser.GameObjects.Graphics;
  roundel?: Phaser.GameObjects.Image;

  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {},
    disableCloseButton: boolean = false,
    disableContinueButton: boolean = false,
    type?: null | "endRoom"
  ) {
    super(scene, scene.renderer.width / 2, scene.renderer.height / 2 - 20);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.textObject = new Phaser.GameObjects.Text(scene, 0, 0, text, {
      fontFamily: "Roboto-Regular",
      fontSize: "20px",
      color: "#FFFFFF",
      wordWrap: { width: WORD_WRAP, useAdvancedWrap: true },
      align: "center",
    })
      .setLineSpacing(8)
      .setOrigin(0.5, 0.5);

    const textHeight = this.textObject.height;
    let backgroundHeight;

    if (!disableContinueButton) {
      this.continueBtn = new TextButton(
        scene,
        0,
        textHeight,
        "Continue",
        "tertiary",
        () => this.continue()
      );
      const continueButtonHeight = this.continueBtn.hitbox.height;
      backgroundHeight =
        textHeight +
        continueButtonHeight +
        BUTTON_TOP_MARGIN +
        BACKGROUND_PADDING_Y;
    } else {
      backgroundHeight = textHeight + BACKGROUND_PADDING_Y + BUTTON_TOP_MARGIN;
    }

    this.background = new Phaser.GameObjects.Image(
      scene,
      0,
      0,
      "modalBackground"
    )
      .setDisplaySize(BACKGROUND_WIDTH, backgroundHeight)
      .setSize(BACKGROUND_WIDTH, backgroundHeight);

    const backgroundTopRight = this.background.getTopRight();

    const containerXinGlobalSpace = scene.renderer.width / 2;
    const containerYinGlobalSpace = scene.renderer.height / 2;

    // MASK TAKES GLOBAL POSITIONING NOT CONTAINER!
    this.maskObject = new Phaser.GameObjects.Graphics(scene)
      .fillRoundedRect(
        containerXinGlobalSpace - this.background.width / 2,
        containerYinGlobalSpace - this.background.height / 2 - 20,
        this.background.displayWidth,
        this.background.displayHeight
      )
      .fillStyle(0xffffff);

    const mask = this.maskObject.createGeometryMask();
    this.background.setMask(mask);

    Display.Align.In.Center(this.textObject, this.background, 0, -30);
    this.add(this.background);
    this.add(this.textObject);

    if (!disableContinueButton) {
      Display.Align.To.BottomCenter(
        this.continueBtn,
        this.textObject,
        0,
        BUTTON_TOP_MARGIN
      );
      this.add(this.continueBtn);
    }

    if (!disableCloseButton) {
      this.closeBtn = new IconButton(
        scene,
        backgroundTopRight.x - 25,
        backgroundTopRight.y + 25,
        "close-sm",
        () => this.closeModal()
      );
      this.add(this.closeBtn);
    }

    if (type && type === "endRoom") {
      this.roundel = new Phaser.GameObjects.Image(
        scene,
        this.background.getTopCenter().x,
        this.background.getTopCenter().y,
        "roundel-complete"
      );
      this.add(this.roundel);
      this.textObject.y += 30;
    }

    scene.add.existing(this);

    if (type && type === "endRoom") {
      setTimeout(() => {
        this.continue();
      }, 5000);
    }
  }

  continue() {
    this.closeModal();
    this.callback();
  }

  closeModal() {
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}
