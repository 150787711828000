import api from "@/api";
import axios from "axios";

export interface RegisterUserData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  registrationToken: string;
}

export function getToken(code: string) {
  return api.get(`/auth/callback`, { params: { code } });
}

export function getUserFromToken() {
  return api.get(`/user-management/getUser`);
}

export function signOut() {
  return axios.get(
    `https://signin.test.access.pwc.com/openam/XUI/?realm=%2Fventuressso#logout/&goto=`
  );
}

export function logOut() {
  return api.get(`/auth/logout`)
}

export function registerUser(data: RegisterUserData) {
  return api.post(`/auth/registerUser`, data);
}

export function inviteUserToPlatform(email: string) {
  return api.post(`/auth/inviteUser`, { email });
}

export function resendPlatformInvite(email: string) {
  return api.post(`/auth/resendInvite`, { email });
}

export function inviteUserToSession(gameSesssionId: string, email: string) {
  return api.post(`/gamesessions/${gameSesssionId}/players/create`, {
    email: email,
  });
}

export function deleteUserFromSession(gameSessionId: string, playerId: string) {
  return api.delete(`/gamesessions/${gameSessionId}/players/${playerId}`);
}
