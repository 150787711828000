import api from "@/api";

export function getUsers(
  page: number = 1,
  limit: number = 10,
  sortBy: string = "_id",
  sortOrder: number = -1,
  queryStr: string,
  role?: string,
  registered?: boolean
) {
  const query = new URLSearchParams({
    page: ((page - 1) * limit).toString(),
    limit: limit.toString(),
    sortOrder: sortOrder.toString(),
    sortBy,
  });

  if (role) {
    query.append("role", role);
  }
  if (queryStr) {
    query.append("query", queryStr);
  }
  if (registered != null) {
    query.append("registered", registered);
  }

  return api.get(
    `/user-management/users${
      query.toString().length > 0 ? "?" + query.toString() : ""
    }`
  );
}

export function deleteUser(userId: string) {
  return api.delete(`/user-management/users/${userId}`);
}
