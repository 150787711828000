import { useParams } from "react-router";
import PhaserGame from "../phaser-game/PhaserGame";
import useUserInfo from "@/context/UserInfoContext";
import { useEffect } from "react";
import { refreshToken } from "@/api";
import { useQuery } from "react-query";
import { getAssets } from "@/api/assets";

const GameSession = () => {
  const { sessionId } = useParams();
  const userInfo = useUserInfo();

  const assetData = useQuery({
    queryKey: ["getAssets"],
    queryFn: getAssets,
  });

  useEffect(() => {
    const refreshTokenEvery10Mins = setInterval(() => {
      refreshToken();
    }, 10 * 60 * 1000)

    return () => {
      clearInterval(refreshTokenEvery10Mins);
    }
  }, [])

  if (sessionId === null) {
    return <div>Session not found</div>;
  }

  if (userInfo.id == undefined || assetData.isLoading) {
    return <div>Loading...</div>;
  }

  const userObject = {
    id: userInfo.id,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
  };

  return (
    <>
      <PhaserGame sessionId={sessionId!} user={userObject} assets={assetData.data.data.data} />
    </>
  );
};

export default GameSession;
