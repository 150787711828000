import { useState } from "react";
import { useGameStore } from "@/store/game-store";
import { Badge, Button, Modal } from "@appkit4/react-components";
import { v4 as uuidv4 } from "uuid";
import styles from "./ObjectActions.module.scss";
import ObjectConfigAction from "./ObjectConfigAction";
import { IAction } from "escape-rooms-types/types/game";
import { ICreatorAction } from "@/types/game";
import { FormattedMessage, useIntl } from "react-intl";

const ObjectActions = () => {
  const intl = useIntl();
  const {
    selectedObjectIndex,
    updateObject,
    game,
    currentRoomIndex,
    removeObjectFromItemList,
  } = useGameStore((state) => state);
  const selectedObject =
    selectedObjectIndex != null
      ? game.rooms[currentRoomIndex].objects[selectedObjectIndex]
      : undefined;

  const [showDeleteObjectModal, setShowDeleteObjectModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState<number | undefined>(
    undefined
  );

  const generateNewAction = (): ICreatorAction => {
    return {
      ref: uuidv4(),
      trigger: undefined,
      type: undefined,
      changeObjectPayload: undefined,
      modalPayload: undefined,
      score: 0,
      triggerItemRef: undefined,
    };
  };

  const onAddAction = () => {
    if (!selectedObject) return;
    const newObject = {
      ...selectedObject,
      actions: [...selectedObject.actions, generateNewAction()],
    };
    updateObject(newObject as any);
  };

  const onDeleteAction = (actionIndex: number) => {
    if (!selectedObject) return;

    const action = selectedObject.actions[actionIndex];
    if (action.type === "addToInventory") {
      removeObjectFromItemList(selectedObject.ref);
    }

    const newObject = {
      ...selectedObject,
      actions: selectedObject.actions.filter(
        (a, index) => index != actionIndex
      ),
    };
    updateObject(newObject);

    setSelectedAction(undefined);
  };

  if (!selectedObject) return <></>;

  return (
    <>
      <div>
        {selectedObject.actions.map((action, index) => (
          <ObjectConfigAction
            key={action.ref}
            index={index}
            onDelete={() => {
              // onDeleteAction(action.ref);
              setSelectedAction(index);
              setShowDeleteObjectModal(true);
            }}
          />
        ))}
        <div className={`${styles.addButtonContainer} ap-mt-spacing-6`}>
          <Button onClick={onAddAction} icon="icon-plus-outline">
            Add new action
          </Button>
        </div>
      </div>
      <Modal
        visible={showDeleteObjectModal}
        title={intl.formatMessage({ id: "action.delete.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "action.delete.modal.title" })}
        onCancel={() => {
          setShowDeleteObjectModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowDeleteObjectModal(false);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              kind="negative"
              onClick={() => {
                if (selectedAction !== undefined) {
                  onDeleteAction(selectedAction);
                }
                setShowDeleteObjectModal(false);
              }}
            >
              <FormattedMessage id="button.remove" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="delete.sure" />
        </p>
      </Modal>
    </>
  );
};

export default ObjectActions;
