import { IRoomObject } from "escape-rooms-types/types/game";
import { IRoomEditorObject } from "@/types/game";
import EventBridge from "@/utils/EventBridge";
import CreatorScene from "@/phaser/scenes/CreatorScene";
import BaseRoomObject from "./BaseRoomObject";
import { deepClone } from "@vitest/utils";
import { getAssetSvg } from "@/api/assets";
import { areArraysEqual, insertCustomColorsIntoSvgText } from "@/utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { useGameStore } from "@/store/game-store";

export default class CreatorRoomObject extends BaseRoomObject {
  public lockIndicator?: Phaser.GameObjects.Image;
  public scene: CreatorScene;
  public uuid: string | null;

  constructor(
    scene: CreatorScene,
    xPos: number,
    yPos: number,
    gameObject: IRoomObject
  ) {
    super(scene, xPos, yPos, gameObject);
    this.uuid = null;
    this.scene = scene;
    EventBridge.remove(`creator.object.color.update.${this.gameObject.ref}`);
    EventBridge.on(
      `creator.object.color.update.${this.gameObject.ref}`,
      ({ index, colorHexValue }) => {
        const colors = [...this.gameObject.colorPalette];
        colors[index] = colorHexValue;

        const oldUuid = this.uuid;
        this.uuid = uuidv4();

        Object.values(this.gameObject.asset.states).forEach(
          (state: IAssetState) => {
            const loader = new Phaser.Loader.LoaderPlugin(this.scene);

            // FIXME: make it get the svgs from cache.
            Promise.all([getAssetSvg(`${state?.textureUrl}_scene`)]).then(
              (res) => {
                const sceneSvg = res[0].data;

                const blob = new Blob(
                  [insertCustomColorsIntoSvgText(sceneSvg, colors)],
                  { type: "image/svg+xml" }
                );
                const svgUrl = URL.createObjectURL(blob);
                const assetUrl = `${this.uuid}_${state.textureUrl}_scene`;
                loader.svg(assetUrl, svgUrl, {
                  scale: 1,
                });

                // FIXME: there is a memory leak because old textures are not unloaded.

                loader.once(Phaser.Loader.Events.COMPLETE, () => {
                  if (this.gameObject.currentState === state.name) {
                    this.changeState(this.gameObject.currentState);
                  }
                  if (oldUuid) {
                    this.scene.textures.remove(
                      `${oldUuid}_${state.textureUrl}_scene`
                    );
                  }
                });
                loader.start();
              }
            );
          }
        );
        const newObject: IRoomObject = {
          ...this.gameObject,
          isDisabled: this.isDisabled,
          isVisible: this.isVisible,
          colorPalette: colors,
        };
        useGameStore.getState().updateObject(newObject);
      }
    );
  }

  public addToScene(x: number, y: number) {
    this.sprite = this.scene.add.sprite(
      x,
      y,
      this.getTextureFromState(this.gameObject.currentState)
    );
    this.setVisible(this.gameObject.isVisible);
    this.sprite.setName(this.gameObject.ref);
    this.sprite.setInteractive();
    this.scene.input.setDraggable(this.sprite);
    this.addEventListeners();
    this.setIsDisabled(this.gameObject.isDisabled);
    return this.sprite;
  }

  public getTextureFromState(state: string) {
    // if default colors use default asset
    if (
      areArraysEqual(
        this.gameObject.colorPalette,
        this.gameObject.asset.defaultColorPalette
      )
    ) {
      return `${this.gameObject.asset.states[state].textureUrl}_scene`;
    }

    if (this.uuid) {
      return `${this.uuid}_${this.gameObject.asset.states[state].textureUrl}_scene`;
    }

    const refUrl = `${this.ref}_${this.gameObject.asset.states[state].name}_scene`;

    if (this.scene.textures.get(refUrl).key !== "__MISSING") {
      return `${this.ref}_${this.gameObject.asset.states[state].name}_scene`;
    }
    return `${this.gameObject.asset.states[state].textureUrl}_scene`;
  }

  public changeState(newState: string, oldState?: string) {
    if (this.scene === null) return;
    if (this.scene.sys.textures === null) {
      return;
    }
    this.sprite.setTexture(this.getTextureFromState(newState));
    if (oldState) {
      const xMovement =
        this.gameObject.asset.states[newState].xOffset -
        this.gameObject.asset.states[oldState].xOffset;
      const yMovement =
        this.gameObject.asset.states[newState].yOffset -
        this.gameObject.asset.states[oldState].yOffset;
      this.sprite.setX(this.sprite.x + xMovement);
      this.sprite.setY(this.sprite.y + yMovement);
    }
    this.currentState = newState;
  }

  public updateObject(object: IRoomEditorObject) {
    // TODO
    this.gameObject = object;
    this.setVisible(object.isVisible);
    this.setIsDisabled(object.isDisabled);
    this.changeState(object.currentState, this.currentState);
  }

  public setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.scene.input.setDraggable(this.sprite, false);
    } else {
      this.scene.input.setDraggable(this.sprite);
    }

    if (this.getIsSelected()) {
      this.setLockIndicator();
    }
  }

  public focus() {
    this.addGlow();
    this.setLockIndicator();
  }

  public blur() {
    this.removeGlow();
    this.lockIndicator?.destroy();
    this.lockIndicator = undefined;
  }

  public setLockIndicator() {
    if (this.lockIndicator) {
      this.lockIndicator.destroy();
      this.lockIndicator = undefined;
    }
    if (this.isDisabled) {
      const spriteHeight = this.sprite.height;
      this.lockIndicator = new Phaser.GameObjects.Image(
        this.scene,
        this.x,
        spriteHeight > 50 ? this.y : this.y - spriteHeight / 2 - 25,
        "lock"
      ).setAlpha(1);
      this.scene.add.existing(this.lockIndicator);
      return;
    }
  }

  public destroy() {
    this.lockIndicator?.destroy();
    this.sprite.destroy();
    EventBridge.remove(`creator.object.color.update.${this.gameObject.ref}`);
  }

  private addEventListeners() {
    this.sprite.on("pointerover", () => {
      this.sprite.setAlpha(0.7);
    });

    this.sprite.on("pointerout", () => {
      if (this.isVisible) {
        this.sprite.setAlpha(1);
      } else {
        this.sprite.setAlpha(0.2);
      }
    });

    this.sprite.on("dragend", () => {
      if (this.isDisabled) return;
      EventBridge.emit("roomEditor.updateObjectPosition", {
        objectRef: this.ref,
        xPos: this.sprite.x,
        yPos: this.sprite.y,
      });
      this.setPosition(this.sprite.x, this.sprite.y);
    });

    this.sprite.on("pointerdown", () => {
      EventBridge.emit("roomEditor.selectObject", {
        objectRef: this.gameObject.ref,
      });
    });
  }

  private removeEventListeners() {}

  private getIsSelected() {
    return (
      (this.scene as CreatorScene).targetRoomObject?.ref === this.gameObject.ref
    );
  }
}
