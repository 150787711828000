import { useSearchParams } from "react-router-dom";

const SignIn = () => {
  const [params, setParams] = useSearchParams();
  const final_redirect_uri = params.get("final_redirect_uri");

  const baseUrl = import.meta.env.VITE_PIAM_URL;
  const response_type = "code";
  const client_id = import.meta.env.VITE_PIAM_CLIENT_ID;
  const redirect_uri = import.meta.env.VITE_PIAM_REDIRECT_URL;
  const scope = "ventures openid";
  const realm = "/venturessso";

  const url = new URL(baseUrl);
  url.searchParams.append("response_type", response_type);
  url.searchParams.append("client_id", client_id);
  url.searchParams.append("redirect_uri", redirect_uri);
  url.searchParams.append("scope", scope);
  url.searchParams.append("realm", realm);
  url.searchParams.append("state", final_redirect_uri || "null");

  window.location.href = url.href;

  return <></>;
};

export default SignIn;
