import api from "@/api";
import axios from "axios";

export function getAssets() {
  return api.get(`/asset?limit=1000`);
}

// From CDN
export function getAssetSvg(textureUrl: string) {
  return axios.get(`/asset-state-textures/${textureUrl}.svg`);
}
