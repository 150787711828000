import Avatar from "../../Avatar";
import UIScene from "@/phaser/scenes/UIScene";
import Player from "../../session/Player";

export default class QuestionnairePlayer {
  id: string;
  player: Player;
  finished: boolean;
  avatar: Avatar;
  firstName: string;
  lastName: string;

  constructor(scene: UIScene, player: Player, x: number, y: number) {
    this.id = player.id;
    this.player = player;
    this.finished = false;
    this.avatar = new Avatar(scene, x, y, player, 36);
    this.firstName = player.firstName;
    this.lastName = player.lastName;
  }

  markAsFinished() {
    this.avatar.markAsFinished();
  }

  markAsUnfinished() {
    this.avatar.markAsUnfinished();
  }
}
