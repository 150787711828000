import {
  Button,
  Column,
  Input,
  Panel,
  Select,
  Table,
  TextArea,
} from "@appkit4/react-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./SessionSettings.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { emailPlaceholders } from "@/constants";
import { useEffect, useState } from "react";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { getGameSession, startSession, updateGameSession } from "@/api/session";
import { getGames } from "@/api/game";
import { IGame } from "escape-rooms-types/types/game";
import { useGameStore } from "@/store/game-store";
import {
  deleteUserFromSession,
  inviteUserToSession,
  resendPlatformInvite,
} from "@/api/auth";
import { renderNotification } from "../notification/renderFunctions";
import { addToClipboard } from "@/utils/helpers";

function delay(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

const SessionSettings = () => {
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionId } = useParams();
  const { session, setSession } = useGameStore();
  const [draft, setDraft] = useState<IGameSession>();
  const [games, setGames] = useState<Array<IGame>>([]);
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteEmailError, setInviteEmailError] = useState<string | null>(null);
  const [inviteTableData, setInviteTableData] = useState<Array<any>>([]);

  const getGamesQuery = useQuery({
    queryKey: "getGames",
    queryFn: () => getGames(),
    onSuccess: (res) => {
      if (res?.data?.data !== undefined) {
        setGames(res.data.data);
      }
    },
  });

  const getGameSessionQuery = useQuery({
    queryKey: "getGameSession",
    queryFn: () => getGameSession(sessionId!),
    onSuccess: (res) => {
      if (res?.data?.data !== undefined) {
        setSession(res.data.data);
        setDraft(res.data.data);
      }
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const saveGameSessionMutation = useMutation({
    mutationKey: "saveGameSession",
    mutationFn: () => updateGameSession(sessionId!, session!),
    onSuccess: (res) => {
      if (res?.data?.data !== undefined) {
        setSession(res.data.data);
        setDraft(res.data.data);
        renderNotification("Session saved successfully.", "success");
      }
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const linkGameToSessionMutation = useMutation({
    mutationKey: "linkGameToSession",
    mutationFn: () => updateGameSession(sessionId!, { gameId: draft?.gameId }),
    onSuccess: (res) => {
      if (res?.data?.data !== undefined) {
        setSession(res.data.data);
        setDraft(res.data.data);
      }
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const inviteUserToGameSession = useMutation({
    mutationKey: "inviteUserToSession",
    mutationFn: (email: string) => inviteUserToSession(sessionId!, email),
    onSuccess: (res) => {
      queryClient.invalidateQueries("getGameSession");
      renderNotification("Player invited to session.", "success");
      setInviteEmail("");
    },
    onError: (err) => {
      setInviteEmailError(err.response.data.message);
      renderNotification(err.message, "error");
    },
  });

  const resendPlayerInvite = useMutation({
    mutationKey: "resendPlayerInvite",
    mutationFn: (email: string) => resendPlatformInvite(email),
    onSuccess: (res) => {
      renderNotification("Invitation has been sent to user.", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const deleteUserFromGameSession = useMutation({
    mutationKey: "deleteUserFromGameSession",
    mutationFn: (playerId: string) =>
      deleteUserFromSession(sessionId!, playerId),
    onSuccess: (res) => {
      queryClient.invalidateQueries("getGameSession");
      renderNotification("Player deleted from session.", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const startGameSession = useMutation({
    mutationKey: "startGameSession",
    mutationFn: () => startSession(sessionId!),
    onSuccess: (res) => {
      queryClient.invalidateQueries("getGameSession");
      renderNotification("Session started", "success");
    },
    onError: (err) => {
      renderNotification(err.message, "error");
    },
  });

  const addAllEmailsToClipboard = () => {
    const emails = session!.players.map((player) => player.email);
    addToClipboard(emails?.join("\n"), () =>
      renderNotification("Added to clipboard", "success")
    );
  };

  useEffect(() => {
    if (session == null) return;
    if (session.players == null) return;
    setInviteTableData(session?.players.map((player) => ({ ...player })));
  }, [session?.players]);

  const handleKeyDown = (event: Event) => {
    if (event.code === "Enter") {
      inviteUserToGameSession.mutate(inviteEmail);
      event.currentTarget.blur();
    }
  };

  const renderActions = (row: any, field: string) => {
    return (
      <div className="flex gap-1">
        {row["status"] === "pending" && (
          <i
            className="Appkit4-icon icon-email-fill btn"
            onClick={() => resendPlayerInvite.mutate(row.email)}
          ></i>
        )}
        <i
          className="Appkit4-icon icon-delete-fill btn"
          onClick={() => deleteUserFromGameSession.mutate(row._id)}
        ></i>
      </div>
    );
  };

  const renderStatus = (row: any, field: string) => {
    const statusString =
      row["status"].charAt(0).toUpperCase() + row["status"].slice(1);
    return (
      <div className="flex align-center gap-1">
        <div
          className={`circle ${
            row.status.toLowerCase() === "pending"
              ? "ap-bg-color-background-warning"
              : "ap-bg-color-background-success"
          }`}
        ></div>
        <span>{statusString}</span>
      </div>
    );
  };

  const linkGame = () => {
    if (!session) return;
    setSession({ ...session, gameId: draft?.gameId! });
  };

  return (
    <div className="ap-container">
      <div className="row">
        <div className="col-6">
          <h2 className="ap-typography-4 ap-font-weight-3 ap-mt-spacing-6 ap-mb-spacing-6">
            {intl.formatMessage({ id: "session.configure.session" })}
          </h2>
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
            <div className="row ap-my-spacing-6">
              <div className="col-6">
                <p className="">
                  <span className="ap-font-weight-3">Client:</span>{" "}
                  {session && session.game ? session.game.client : "N/A"}
                </p>
                <p>
                  <span className="ap-font-weight-3">Session id:</span>{" "}
                  {session?._id}
                </p>
                <p>
                  <span className="ap-font-weight-3">Status:</span>{" "}
                  {session?.status.charAt(0).toUpperCase() +
                    session?.status.slice(1)}
                </p>
              </div>
            </div>
            <div className="row ap-my-spacing-6">
              <div className="col-12">
                <Input
                  type="text"
                  maxLength={100}
                  value={session?.name}
                  onChange={(e) => {
                    setSession({ ...session!, name: e });
                  }}
                  title="Name"
                />
              </div>
            </div>
            <div className="row ap-my-spacing-6">
              <div className="col-12">
                <TextArea
                  maxLength={420}
                  value={session?.description}
                  onChange={(e) => {
                    setSession({ ...session!, description: e });
                  }}
                  title="Additional notes"
                />
              </div>
            </div>
            <div className="row ap-my-spacing-6">
              <div className="col-12 flex align-center">
                <TextArea
                  maxLength={100}
                  value={session?.callLink}
                  title="Call link"
                  className={`w-full ap-mr-spacing-5 ${styles.callLink}`}
                  onChange={(e) => {
                    setSession({ ...session!, callLink: e });
                  }}
                />
                <i
                  className="Appkit4-icon icon-copy-outline ml-auto btn"
                  onClick={() =>
                    addToClipboard(session?.callLink!, () =>
                      renderNotification("Added to clipboard", "success")
                    )
                  }
                ></i>
              </div>
            </div>
            <div className="row ap-my-spacing-6">
              <div className="col-12">
                <div className={styles.footerConfigureSession}>
                  <p>
                    Created on{" "}
                    {new Date(session?.createdAt!).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <p>
                    Last edited on{" "}
                    {new Date(session?.updatedAt!).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                </div>
              </div>
            </div>
          </Panel>
          <h2 className="ap-typography-4 ap-font-weight-3 ap-mt-spacing-6 ap-mb-spacing-6">
            {intl.formatMessage({ id: "session.manage.session" })}
          </h2>
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
            <div className="ap-my-spacing-3 flex">
              <div>
                <p className="inline w-full word-break">
                  <span className="ap-font-weight-3">
                    Auto-generated game link:
                  </span>
                  &nbsp;
                  {`${window.location.origin}/sessions/${session?._id}/play`}
                </p>
              </div>
              <i
                className="Appkit4-icon icon-copy-outline ml-auto btn"
                onClick={() =>
                  addToClipboard(
                    `${window.location.origin}/sessions/${session?._id}/play`,
                    () => renderNotification("Added to clipboard", "success")
                  )
                }
              ></i>
            </div>
            <div className="flex">
              <Button
                disabled={session?.status != "pending"}
                className="ml-auto ap-mt-spacing-3"
                icon="icon-screen-mirroring-outline"
                onClick={() => {
                  window.open(
                    `${window.location.origin}/sessions/${session?._id}/play`,
                    "_blank"
                  );
                  startGameSession.mutate();
                }}
              >
                Start session
              </Button>
            </div>
          </Panel>
        </div>
        <div className="col-6">
          <h2 className="ap-typography-4 ap-font-weight-3 ap-mt-spacing-6 ap-mb-spacing-6">
            {intl.formatMessage({ id: "session.manage.team" })}
          </h2>
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
            <div
              className="flex align-start mt-2 mb-1 gap-2"
              style={{ alignItems: "center" }}
            >
              <Input
                className="col-8"
                type={"text"}
                title={"Enter email address"}
                error={inviteEmailError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    {inviteEmailError}
                  </div>
                }
                value={inviteEmail}
                onChange={(e) => {
                  setInviteEmail(e);
                  setInviteEmailError(null);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
              ></Input>
              <Button
                style={{ width: "100%", height: "100%" }}
                icon="icon-add-user-outline"
                kind="secondary"
                disabled={inviteEmail.length < 1}
                onClick={() => inviteUserToGameSession.mutate(inviteEmail)}
              >
                Invite to Game
              </Button>
            </div>
            <Table
              originalData={[...inviteTableData]}
              hasTitle
              className="data-table"
              style={{ padding: 0 }}
            >
              <Column field="name">Name</Column>
              <Column field="email">Email</Column>
              <Column field="status" renderCell={renderStatus}>
                Status
              </Column>
              <Column field="name" renderCell={renderActions}>
                Actions
              </Column>
            </Table>
            <div className="flex">
              <Button
                kind="tertiary"
                className="ml-auto ap-my-spacing-2"
                icon="icon-copy-outline"
                onClick={() => addAllEmailsToClipboard()}
              >
                Copy all email addresses
              </Button>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default SessionSettings;
