import BaseRoomObject from "../RoomObject/BaseRoomObject";
import UIScene from "@/phaser/scenes/UIScene";
import BaseModal from "./BaseModal";
import { Display } from "phaser";
import EventBridge from "@/utils/EventBridge";

export default class TextModal extends BaseModal {
  textObject: Phaser.GameObjects.Text;

  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    incrementActionIndex: boolean = true
  ) {
    let continueCallback: (() => void) | undefined;
    continueCallback = incrementActionIndex
      ? () => EventBridge.emit("ui.completeTextModal", sourceObject)
      : () => {};
    super(scene, "CONTINUE", continueCallback);

    this.textObject = new Phaser.GameObjects.Text(scene, 0, 0, text, {
      fontFamily: "Roboto-Regular",
      fontSize: "20px",
      color: "#FFFFFF",
      wordWrap: { width: 1000, useAdvancedWrap: true },
      align: "center",
    })
      .setLineSpacing(8)
      .setOrigin(0.5, 0.5);

    Display.Align.In.Center(this.textObject, this.background, 0, -30);
    this.add(this.textObject);
    scene.add.existing(this.textObject);
  }
}
