import { createNanoEvents, Emitter } from "nanoevents";

declare global {
  interface Window {
    eventEmitter: Emitter;
  }
}

interface Events {
  set: (name: string, count: number) => void;
  tick: () => void;
}

// Add event emitter to the global window
window.eventEmitter = createNanoEvents<Events>();

// Create an exportable singleton EventBridge that emits/listens to the global event emitter.
const EventBridge = {
  emit(event: string, payload?: {}) {
    if (!window.eventEmitter) return false;
    window.eventEmitter.emit(event, payload);
    return true;
  },
  on(event: string, callback: (payload: any) => void) {
    if (!window.eventEmitter) return false;
    window.eventEmitter.on(event, callback);
    return true;
  },
  emitFromPhaser(type: string, payload = null) {
    this.emit("phaser:storeon", { type, payload });
  },
  remove(event: string) {
    if (!window.eventEmitter) return;
    delete window.eventEmitter.events[event];
  },
};

export default EventBridge;
