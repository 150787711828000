import CreatorScene from "@/phaser/scenes/CreatorScene";
import { SCREEN_WIDTH, SCREEN_HEIGHT } from "@/constants";
import LoadingScene from "./scenes/LoadingScene";

const gameConfig: Phaser.Types.Core.GameConfig = {
  type: Phaser.WEBGL,
  parent: "phaser-creator-container",
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
  backgroundColor: "#6A6A6A",
  scene: [LoadingScene, CreatorScene],
  scale: {
    mode: Phaser.Scale.FIT,
  },
};

export default gameConfig;

