import { avatarColors } from "@/constants";

interface Props {
  id: string;
  clientId: string;
  firstName: string;
  lastName: string;
  score: number;
  color: number;
  inactive: boolean;
}

export default class Player {
  id: string;
  clientId: string;
  color: number;
  score: number;
  firstName: string;
  lastName: string;
  inactive: boolean;
  name: string;

  constructor({
    id,
    clientId,
    firstName,
    lastName,
    score,
    color,
    inactive,
  }: Props) {
    this.id = id;
    this.clientId = clientId;
    this.score = score;
    this.color = color;
    this.firstName = firstName;
    this.lastName = lastName;
    this.inactive = inactive;
    this.name = `${firstName} ${lastName}`;
  }

  getName() {
    return `${this.firstName} ${this.lastName}`;
  }

  setInactive(bool: boolean) {
    this.inactive = bool;
  }
}
