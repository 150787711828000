import { ListItem } from "@appkit4/react-components";
import { useGameStore } from "@/store/game-store";
import ClassNames from "classnames";
import styles from "./ObjectListItem.module.scss";
import { IRoomEditorObject } from "@/types/game";
import SvgComponent from "../svg/SvgComponent";
import { useQuery } from "react-query";
import { getAssetSvg } from "@/api/assets";

interface ObjectListItemProps {
  object: IRoomEditorObject;
  index: number;
}

const ObjectListItem = ({ object, index }: ObjectListItemProps) => {
  const {
    selectedObjectIndex,
    setSelectedObjectByRef,
  } = useGameStore((state) => state);
  const classes = ClassNames({ selected: index === selectedObjectIndex });
  const textureUrl = `${
    object.asset.states[object.currentState].textureUrl
  }_thumbnail`;

  const { data, isLoading } = useQuery({
    queryKey: ["getAssetSvg", textureUrl],
    queryFn: () => getAssetSvg(textureUrl),
  });

  if (isLoading || data == null) {
    return <></>;
  }

  return (
    <ListItem
      key={object.ref}
      role="option"
      className={`${classes} ${styles["list-item"]}`}
      onClick={() => {
        setSelectedObjectByRef(object.ref);
      }}
    >
      <div className={styles["item-container"]}>
        <div className={styles["thumbnail-container"]}>
          <SvgComponent
            svgString={data.data}
            colors={object.colorPalette}
            alt={object.asset.name}
          />
        </div>
        <p className={styles.itemName}>{object.name}</p>
      </div>
      {object.isDisabled && (
        <img src={`/icons/lock-closed-black.svg`} className={styles.icon} />
      )}
    </ListItem>
  );
};

export default ObjectListItem;
