import { Select } from "@appkit4/react-components";
import { IAction, IRoomObject } from "escape-rooms-types/types/game";
import styles from "../ObjectConfigAction.module.scss";
interface XPFieldProps {
  action: IAction;
  updateActionProperties: (payload: Partial<IAction>) => void;
}

const XPField = ({ action, updateActionProperties }: XPFieldProps) => {
  return (
    <div className="col-4">
      <span className="input-label">Add XP value to this action</span>
      <Select className={styles["select-dropdown"]}
        placeholder=""
        data={[
          { label: "None (default)", value: 0 },
          { label: "5", value: 5 },
          { label: "10", value: 10 },
          { label: "15", value: 15 },
        ]}
        value={action.score}
        onSelect={(e) => {
          updateActionProperties({
            score: e,
          });
        }}
      />
    </div>
  );
};

export default XPField;
