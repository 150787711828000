import AssetsTab from "@/components/assets-tab/AssetsTab";
import { Panel } from "@appkit4/react-components/panel";
import { Tabs, Tab } from "@appkit4/react-components/tabs";
import { Button } from "@appkit4/react-components/button";
import styles from "./AssetsPanel.module.scss";

const AssetsPanel = () => {
  return (
    <Panel title="Assets" className={styles.panel}>
      <div className={styles.container}>
        <AssetsTab />
      </div>
    </Panel>
  );
};

export default AssetsPanel;
