import { Select } from "@appkit4/react-components";
import { IAction, IRoomObject } from "escape-rooms-types/types/game";
import { useGameStore } from "@/store/game-store";
import XPField from "../shared/XPField";
import styles from "../ObjectConfigAction.module.scss";
interface IActionChangeOtherProps {
  action: IAction;
  selected: IRoomObject;
  objects: IRoomObject[];
  updateActionProperties: (payload: Partial<IAction>) => void;
}
const ActionChangeOther = ({
  action,
  selected,
  objects,
  updateActionProperties,
}: IActionChangeOtherProps) => {
  const updateActionChangeObjectPayload = useGameStore(
    (state) => state.updateActionChangeObjectPayload
  );

  return (
    <>
      <div className="col-4">
        <span className="input-label">What other object is changing?</span>
        <Select className={styles["select-dropdown"]}
          placeholder=""
          searchable
          data={(objects?? [])
            .filter((object) => object.ref !== selected.ref)
            .map((object) => ({
              label: object.name,
              value: object.ref,
            }))}
          value={action?.changeObjectPayload?.targetObjectRef}
          onSelect={(e) => {
            updateActionProperties({
              changeObjectPayload: {
                ref: "",
                targetObjectRef: e,
                newProperties: {
                  isVisible: true,
                },
              },
            });
          }}
        />
      </div>
      {action?.changeObjectPayload?.targetObjectRef != null &&
        action.changeObjectPayload?.targetObjectRef != selected.ref && (
          <div className="col-4">
            <span className="input-label">
              What is the visibility of this asset?
            </span>
            <Select className={styles["select-dropdown"]}
              placeholder=""
              data={[
                { label: "Visible", value: "visible" },
                { label: "Hidden", value: "hidden" },
              ]}
              value={
                action.changeObjectPayload?.newProperties.isVisible
                  ? "visible"
                  : "hidden"
              }
              onSelect={(e) => {
                updateActionChangeObjectPayload(selected.ref, action.ref, {
                  targetObjectRef: action.changeObjectPayload?.targetObjectRef!,
                  newProperties: {
                    isVisible: e === "visible" ? true : false,
                  },
                });
              }}
            />
          </div>
        )}
      <XPField
        action={action}
        updateActionProperties={(payload) => updateActionProperties(payload)}
      />
    </>
  );
};

export default ActionChangeOther;
