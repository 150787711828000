import { IRoomEditorObject } from "@/types/game";
import {
  IAsset,
  IAssetState,
} from "escape-rooms-types/types/game";
import { v4 as uuidv4 } from "uuid";

export const getObjectNameFromRef = (ref?: string) => {
  if (ref == null) {
    return "";
  }
  return ref.split(".")[0];
};

export function generateRoomObject(asset: IAsset): IRoomEditorObject {
  const states =
    asset.states instanceof Map
      ? asset.states
      : new Map<string, IAssetState>(Object.entries(asset.states || {}));
  return {
    name: asset.name,
    ref: `${asset.name.replaceAll(" ", "-")}_${uuidv4()}`.toLowerCase(),
    asset,
    assetId: asset._id,
    currentState: Array.from(states.values())[0].name,
    xPos: 50,
    yPos: 50,
    isVisible: true,
    isDisabled: false,
    isItem: false,
    actions: [],
    currentAction: 0,
    isLocked: false,
    colorPalette: asset.defaultColorPalette,
  };
}
