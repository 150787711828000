import React from "react";
import ReactDOM from "react-dom/client";
import "@/styles/globals.scss";
import "@appkit4/react-components/dist/styles/appkit4-react.min.css";
import "@appkit4/styles/themes/appkit.orange.min.css";
import { RouterProvider } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import router from "@/router";
import messages from "@/i18n/messages";

const LOCALE = "en";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <IntlProvider
      locale={LOCALE}
      defaultLocale="en"
      messages={messages[LOCALE]}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </IntlProvider>
  </React.StrictMode>
);
