import {FC, ReactNode} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";

type ProtectedRouteProps = { children: ReactNode[] };

const ProtectedRoute: FC<ProtectedRouteProps> = ({children}) => {
    const location = useLocation();

    const token = localStorage.getItem("id_token");

    if (!token) {
        return <Navigate to={`/login?final_redirect_uri=${encodeURIComponent(location.pathname)}`} replace={true} />;
    }

    return (children || <Outlet />);
}

export default ProtectedRoute;