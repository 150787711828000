import UIScene from "@/phaser/scenes/UIScene";
import { Display } from "phaser";
import Hud from "../../ui/hud/Hud";
import GameScene from "@/phaser/scenes/GameScene";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { getGameSession } from "@/api/session";
import Player from "../../session/Player";

export default class GameCompleteScreen extends Phaser.GameObjects.Group {
  scene: UIScene;
  background: Phaser.GameObjects.Rectangle;
  gameScene: GameScene;
  session: IGameSession;

  constructor(scene: UIScene, session: IGameSession) {
    super(scene);
    this.scene = scene;
    this.gameScene = scene.sourceScene as GameScene;
    this.session = session;

    this.background = new Phaser.GameObjects.Rectangle(
      scene,
      scene.renderer.width / 2,
      scene.renderer.height / 2,
      scene.renderer.width,
      scene.renderer.height,
      0xffffff
    ).setInteractive();
    this.add(scene.add.existing(this.background));

    let hud = new Hud(scene, scene.sourceScene, true);
    this.add(scene.add.existing(hud));

    let title = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      150,
      "MISSION ACCOMPLISHED!",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "30px",
        color: "#000000",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    // Display.Align.In.Center(this.text, this.background, 0, 0);
    this.add(scene.add.existing(title));

    let subtitle = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      title.y + 45,
      "Thank you for taking part in our escape room experience, we hope you have enjoyed it. Here’s a summary of how you did.",
      {
        fontFamily: "Roboto-Regular",
        fontSize: "18px",
        color: "#2d2d2d",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);

    this.add(scene.add.existing(subtitle));

    let levelCompletedTitle = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2 - subtitle.width / 2 - 10,
      subtitle.y + 150,
      "LEVELS COMPLETED",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "30px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    levelCompletedTitle.setX(levelCompletedTitle.x + levelCompletedTitle.width / 2);
    this.add(scene.add.existing(levelCompletedTitle));

    let levelCompletedText = new Phaser.GameObjects.Text(
      scene,
      levelCompletedTitle.x,
      levelCompletedTitle.y + 50,
      `${this.gameScene.activeRoomIndex + 1}`,
      {
        fontFamily: "Nunito-Bold",
        fontSize: "40px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(levelCompletedText));

    let timeTitle = new Phaser.GameObjects.Text(
      scene,
      levelCompletedText.x,
      levelCompletedText.y + 150,
      "TIME",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "30px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(timeTitle));

    let startDate = this.session.createdAt ? new Date(Date.parse(this.session.createdAt)) : new Date();
    let endDate = this.session.gameCompleted !== undefined ? new Date(Date.parse(this.session.gameCompleted)) : new Date();
    let timeDifference = endDate.getTime() - startDate.getTime();
    let seconds = Math.floor(timeDifference / 1000 % 60);
    let minutes = Math.floor((timeDifference / 1000 - seconds) / 60);

    // let timeText = new Phaser.GameObjects.Text(
    //   scene,
    //   timeTitle.x,
    //   timeTitle.y + 50,
    //   `${minutes}m ${seconds}s`,
    //   {
    //     fontFamily: "Nunito-Bold",
    //     fontSize: "40px",
    //     color: "#415385",
    //     align: "center",
    //   }
    // ).setOrigin(0.5, 0.5);
    // this.add(scene.add.existing(timeText));

    let timeText = new Phaser.GameObjects.DOMElement(
      scene,
      timeTitle.x,
      timeTitle.y + 50,
      this.createTimeDom(minutes, seconds)
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(timeText));

    let xpEarnedTitle = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      levelCompletedTitle.y,
      "XP EARNED",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "30px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(xpEarnedTitle));

    let myPlayer = this.session?.players?.find(player => player.playerId === this.gameScene.session?.myPlayer.id);

    // let xpEarnedYouText = new Phaser.GameObjects.Text(
    //   scene,
    //   xpEarnedTitle.x,
    //   xpEarnedTitle.y + 50,
    //   `${myPlayer?.playerScore ?? 0} you`,
    //   {
    //     fontFamily: "Nunito-Bold",
    //     fontSize: "40px",
    //     color: "#415385",
    //     align: "center",
    //   }
    // ).setOrigin(0.5, 0.5);
    // this.add(scene.add.existing(xpEarnedYouText));

    let xpEarnedYouText = new Phaser.GameObjects.DOMElement(
      scene,
      xpEarnedTitle.x,
      xpEarnedTitle.y + 50,
      this.createXpDom(myPlayer?.playerScore ?? 0, "you"),
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(xpEarnedYouText));

    // let xpEarnedTeamText = new Phaser.GameObjects.Text(
    //   scene,
    //   xpEarnedTitle.x,
    //   xpEarnedYouText.y + 50,
    //   `${this.session.teamScore || 0} team`,
    //   {
    //     fontFamily: "Nunito-Bold",
    //     fontSize: "40px",
    //     color: "#415385",
    //     align: "center",
    //   }
    // ).setOrigin(0.5, 0.5);
    // this.add(scene.add.existing(xpEarnedTeamText));

    let xpEarnedTeamText = new Phaser.GameObjects.DOMElement(
      scene,
      xpEarnedTitle.x,
      xpEarnedYouText.y + 50,
      this.createXpDom(this.session.teamScore ?? 0, "team")
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(xpEarnedTeamText));

    let questionsTitle = new Phaser.GameObjects.Text(
      scene,
      xpEarnedTitle.x + 430,
      levelCompletedTitle.y,
      "QUESTIONS",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "30px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    questionsTitle.setX(questionsTitle.x - questionsTitle.width / 2);
    this.add(scene.add.existing(questionsTitle));

    let answersGivenText = new Phaser.GameObjects.Text(
      scene,
      questionsTitle.x,
      questionsTitle.y + 50,
      `${this.session.gameStats[this.gameScene.user.id].answeredQuestions ?? 0}`,
      {
        fontFamily: "Nunito-Bold",
        fontSize: "40px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(answersGivenText));

    let answersGivenLabel = new Phaser.GameObjects.Text(
      scene,
      answersGivenText.x,
      answersGivenText.y + 40,
      "answers given",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "26px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(answersGivenLabel));

    let correctAttempts = 0;
    if(this.session.answersSubmitted > 0) {
      correctAttempts = Math.floor(((this.session.answersCorrectAtFirstAttempt ?? 0) / (this.session.answersSubmitted ?? 0) ) * 100);
    }

    let attemptCorrectText = new Phaser.GameObjects.Text(
      scene,
      answersGivenLabel.x,
      answersGivenLabel.y + 50,
      `${this.session.gameStats[this.gameScene.user.id].firstAttemptPercentage ?? 0}%`,
      {
        fontFamily: "Nunito-Bold",
        fontSize: "40px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(attemptCorrectText));

    let attemptCorrectLabel = new Phaser.GameObjects.Text(
      scene,
      attemptCorrectText.x,
      attemptCorrectText.y + 30,
      "correct at 1st attempt",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "26px",
        color: "#415385",
        align: "center",
        wordWrap: {width: 200}
      }
    ).setOrigin(0.5, 0.5);
    attemptCorrectLabel.setY(attemptCorrectLabel.y + attemptCorrectLabel.height / 2)
    this.add(scene.add.existing(attemptCorrectLabel));

    let topPlayersTitle = new Phaser.GameObjects.Text(
      scene,
      xpEarnedTitle.x,
      xpEarnedTeamText.y + 150,
      "TOP PLAYERS",
      {
        fontFamily: "Nunito-Medium",
        fontSize: "30px",
        color: "#415385",
        align: "center",
      }
    ).setOrigin(0.5, 0.5);
    this.add(scene.add.existing(topPlayersTitle));

    let lastPlayerText: Phaser.GameObjects.DOMElement | undefined = undefined;

    let players = [...(this.gameScene.session?.players || [])]
    if(this.gameScene.session?.myPlayer && !players.some(player => player.id === this.gameScene.session?.myPlayer.id)){
      players.push(this.gameScene.session.myPlayer);
    }
    players.slice(0,3).sort((a, b) => b.score - a.score).forEach(player => {
      // let playerText = new Phaser.GameObjects.Text(
      //   scene,
      //   topPlayersTitle.x,
      //   lastPlayerText ? lastPlayerText.y + 50 : topPlayersTitle.y + 50,
      //   `${player.firstName} ${player.lastName} - ${player.score}`,
      //   {
      //     fontFamily: "Nunito-Regular",
      //     fontSize: "20px",
      //     color: "#415385",
      //     align: "center",
      //   }
      // ).setOrigin(0.5, 0.5);
      // this.add(scene.add.existing(playerText));

      let playerText = new Phaser.GameObjects.DOMElement(
        scene,
        topPlayersTitle.x,
        lastPlayerText ? lastPlayerText.y + 50 : topPlayersTitle.y + 50,
        this.createTopPlayerDom(player)
      ).setOrigin(0.5, 0.5);
      this.add(scene.add.existing(playerText));

      lastPlayerText = playerText;
    });

    let graphics = new Phaser.GameObjects.Graphics(scene, {fillStyle: {color: 0xffffff}, lineStyle: {color: 0x415385, width: 1 }});
    this.drawBorder(graphics, levelCompletedTitle, levelCompletedText, "flag-checkered-solid");
    this.drawBorder(graphics, xpEarnedTitle, xpEarnedTeamText, "xp-mono-good");
    this.drawBorder(graphics, questionsTitle, attemptCorrectLabel, "question-solid");
    this.drawBorder(graphics, timeTitle, timeText, "stopwatch-solid");
    this.drawBorder(graphics, topPlayersTitle, lastPlayerText || topPlayersTitle, "trophy-solid");
    this.add(scene.add.existing(graphics));
  }

  drawBorder(graphics: Phaser.GameObjects.Graphics, title: Phaser.GameObjects.Text, lastText: Phaser.GameObjects.Text, icon?: string) {
    graphics.strokeRoundedRect(title.x - 324 / 2, title.y - title.height - 20, 324, (lastText.y + lastText.height) - (title.y - title.height) + 20, 10);
    graphics.fillCircle(title.x, title.y - title.height - 20 , 25);
    graphics.strokeCircle(title.x, title.y - title.height - 20 , 25);

    if(icon !== undefined) {
      let iconImage = new Phaser.GameObjects.Image(this.scene, title.x, title.y - title.height - 20, icon).setDepth(4000);
      this.add(this.scene.add.existing(iconImage));
    }
  }

  createTimeDom(minutes: number, seconds: number) {
    let dom = document.createElement("div");
    dom.classList.add("game-complete-medium");
    dom.innerHTML = `<span class="game-complete-bold">${minutes}</span>m <span class="game-complete-bold">${seconds}</span>s`
    return dom;
  }

  createXpDom(score: number, suffix: string) {
    let dom = document.createElement("div");
    dom.classList.add("game-complete-medium");
    dom.innerHTML = `<span class="game-complete-bold">${score}</span> ${suffix}`
    return dom;
  }

  createTopPlayerDom(player: Player) {
    let dom = document.createElement("div");
    dom.innerHTML = `<div class="game-complete-top-player"><span class="avatar" style="background: #${player.color.toString(16)}">${player.firstName[0] ?? ""}${player.lastName[0] ?? ""}</span><span>${player.firstName} ${player.lastName} - ${player.score ?? 0}</span></div>`
    return dom;
  }
}
