import { logOut } from "@/api/auth";
import { useQuery } from "react-query";
import { renderNotification } from "../notification/renderFunctions";

const SignOut = () => {
  const { isLoading } = useQuery({
    queryKey: ["logout"],
    queryFn: () => logOut,
    onSuccess: () => {
      window.location.href = `https://signin.test.access.pwc.com/openam/XUI/?realm=%2Fventuressso#logout`;
      localStorage.removeItem("id_token");
    },
    onError: (err) => {
      renderNotification(err, "error");
    },
  });

  if (isLoading) {
    return <div>Logging out...</div>;
  }

  return <div>You have signed out.</div>;
};

export default SignOut;
