import { getAssetSvg } from "@/api/assets";
import BaseScene from "@/phaser/scenes/BaseScene";
import { IRoomObject, IAssetState } from "escape-rooms-types/types/game";
import { areArraysEqual, insertCustomColorsIntoSvgText } from "./helpers";
import axios from "axios";

export const loadGameSpecificAssets = (
  scene: Phaser.Scene,
  objects: IRoomObject[]
) => {
  objects.forEach((object: IRoomObject) => {
    // If object is using default colors, don't load new asset. We will just use the default asset.
    if (areArraysEqual(object.colorPalette, object.asset.defaultColorPalette)) {
      return;
    }

    Object.values(object.asset.states).forEach((state: IAssetState) => {
      const sceneUrlSeg = `${state.textureUrl}_scene`.toLowerCase();
      const thumbnailUrlSeg = `${state.textureUrl}_thumbnail`.toLowerCase();

      axios.get(`/asset-state-textures/${sceneUrlSeg}.svg`).then((res) => {
        loadSvgFromText(
          scene,
          `${object.ref}_${state.name}_scene`.toLowerCase(),
          res.data,
          object.colorPalette,
          {
            scale: 1,
          }
        );
      });
      axios.get(`/asset-state-textures/${thumbnailUrlSeg}.svg`).then((res) => {
        loadSvgFromText(
          scene,
          `${object.ref}_${state.name}_thumbnail`.toLowerCase(),
          res.data,
          object.colorPalette,
          {
            scale: 1,
          }
        );
      });
    });
  });
};

const loadSvgFromText = (
  scene: Phaser.Scene,
  key: string,
  svgText: string,
  colors: string[],
  config?: any
) => {
  // TODO: Get the backend to return the svg as a string.
  const newSvg = insertCustomColorsIntoSvgText(svgText, colors);
  const blob = new Blob([newSvg], { type: "image/svg+xml" });
  const svgUrl = URL.createObjectURL(blob);
  return scene.load.svg(key, svgUrl, config);
}

export const createRandomString = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
