import { useEffect, useState } from "react";
import { useGameStore } from "@/store/game-store";
import { List, Search } from "@appkit4/react-components";
import ObjectListItem from "@/components/object-list/ObjectListItem";
import styles from "./ObjectList.module.scss";

const ObjectList = () => {
  const currentRoomIndex = useGameStore((state) => state.currentRoomIndex);
  const objects = useGameStore(
    (state) => state.game.rooms[currentRoomIndex].objects
  );
  const [searchText, setSearchText] = useState("");
  const [filteredObjects, setFilteredObjects] = useState(objects || []);

  useEffect(() => {
    updateFilteredObjects(searchText);
  }, [objects]);

  const updateFilteredObjects = (searchValue: string) => {
    setSearchText(searchValue);
    const searchResults = objects?.filter((object) =>
      object.asset.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredObjects(searchResults);
  };

  const onSearchHandler = (
    searchValue: string,
    event: React.SyntheticEvent
  ) => {
    updateFilteredObjects(searchValue);
  };

  return (
    <div>
      <Search
        data={objects}
        searchType={"primary"}
        onChange={onSearchHandler}
        placeholder={"Search In-game Objects"}
      />
      <div className={styles.objectList}>
        <List
          itemKey={Math.random().toString()}
          data={filteredObjects}
          renderItem={(object, index) => (
            <ObjectListItem object={object} index={index} />
          )}
        />
      </div>
    </div>
  );
};

export default ObjectList;
