import {useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Login.module.scss";
import { Button } from "@appkit4/react-components";

const Login = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const final_redirect_uri = params.get("final_redirect_uri");

  const loginLink = `/signin?final_redirect_uri=${final_redirect_uri}`;

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img src="/pwc-splash-logo.png" alt="" />
        </div>
        <div className={styles.containerTwo}>
          <div className={styles.containerThree}>
            <div className={styles.form}>
              <h2 className={styles.subheader}>Escape Room</h2>
              <h1 className={styles.header}>Welcome! Please sign in.</h1>
              <div className={styles.buttons}>
                <Button kind="tertiary" onClick={() => navigate(loginLink)}>Log In using PwC</Button>
                {/* <Button kind="tertiary">Sign Up</Button> */}
              </div>
            </div>
            <div className={styles.door}>
              <img src="/door-opened.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
