import { useState, useEffect } from "react";
import { List } from "@appkit4/react-components/list";
import { Search } from "@appkit4/react-components/search";
// TODO: Replace with API call that gets asset list
import { assets as placeholderAssets } from "@/constants";
import AssetListItem from "@/components/asset-list/AssetListItem";
import { useGameStore } from "@/store/game-store";
import { useIntl } from "react-intl";
import { IRoomObject, IAsset } from "escape-rooms-types/types/game";
import { generateRoomObject } from "@/utils/react-helpers";
import { useQuery } from "react-query";
import { getAssets } from "@/api/assets";
import styles from "./AssetList.module.scss";

const AssetList = () => {
  const intl = useIntl();
  const addObjectToRoom = useGameStore((state: any) => state.addObjectToRoom);
  const [filteredAssets, setFilteredAssets] = useState<IAsset[]>([]);

  const { data, isSuccess } = useQuery({
    queryKey: ["getAssets"],
    queryFn: getAssets,
  });

  useEffect(() => {
    if (isSuccess) {
      const allAssets = data.data.data;
      setFilteredAssets(allAssets);
      useGameStore.getState().setAssets(allAssets);
    }
  }, [isSuccess]);

  const onSearchHandler = (
    searchValue: string,
    event: React.SyntheticEvent
  ) => {
    const allAssets = data?.data.data;
    const searchResults = allAssets.filter((asset: IAsset) =>
      asset.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredAssets(searchResults);
  };

  const onClickHandler = (event: React.MouseEvent, asset: IAsset) => {
    addObjectToRoom(generateRoomObject(asset));
  };

  const renderAssetItem = (asset: IAsset, index: number) => {
    return (
      <AssetListItem
        key={asset._id}
        asset={asset}
        onClickHandler={(e) => {
          onClickHandler(e, asset);
        }}
      />
    );
  };

  return (
    <div className={styles.container}>
      <Search
        data={filteredAssets}
        searchType={"primary"}
        onChange={onSearchHandler}
        placeholder={intl.formatMessage({ id: "room.assets.search" })}
      />
      <div className={styles.assetList}>
        <List
          itemKey="id"
          bordered={false}
          data={filteredAssets}
          renderItem={renderAssetItem}
        ></List>
      </div>
    </div>
  );
};

export default AssetList;
