import EventBridge from "@/utils/EventBridge";
import PreviewRoomObject from "../classes/RoomObject/PreviewRoomObject";
import UIScene from "./UIScene";
import { PreviewPhaserGame } from "@/components/phaser-game/PreviewGame";
import BaseScene from "./BaseScene";
import gameConfig from "../preview-config";
import { IAsset, IRoomObject } from "escape-rooms-types/types/game";
import { loadRoomObjectsIntoSceneWithCustomColors } from "@/utils/phaser-helpers";

export default class PreviewScene extends BaseScene {
  public targetRoomObject?: PreviewRoomObject;
  public itemBeingUsed?: PreviewRoomObject;
  private userItemClickOnCooldown: boolean;
  public uiScene: UIScene;

  public totalActions: number = 0;
  public actionsDone: number = 0;

  constructor() {
    super("preview-scene");
    this.roomObjects = {};
    this.userItemClickOnCooldown = false;
  }

  async preload() {
    // count total actions.
    const objects = (this.game as PreviewPhaserGame).previewRoomObject.objects;
    objects.forEach((object) => {
      this.totalActions += object.actions.length;
    });
  }

  create() {
    this.input.on("pointerdown", () => {
      // Hack to fix stupid race condition. Basically this get executed before we can actually
      // set useItem to true
      setTimeout(() => {
        // If currently using an item and not clicking on anything cancel the useItem
        if (this.itemBeingUsed && this.userItemClickOnCooldown === false) {
          this.itemBeingUsed = undefined;
          EventBridge.emit("ui.stopUsingItem");
        }
      }, 50);
    });

    // populate room preview objects.
    (this.game as PreviewPhaserGame).previewRoomObject.objects.forEach(
      (object) => {
        const roomObject = new PreviewRoomObject(
          this,
          object.xPos,
          object.yPos,
          object
        );
        this.roomObjects[roomObject.ref] = roomObject;
      }
    );

    this.cameras.main.setBounds(
      0,
      0,
      this.renderer.width *
        (this.game as PreviewPhaserGame).previewRoomObject.length,
      this.renderer.height
    );

    this.uiScene = this.game.scene.add(
      "UIScene",
      new UIScene(this),
      true
    ) as UIScene;
    this.addEventListeners();
    this.events.once("destroy", this.removeEventListeners);
    this.scene.setVisible(true, "ui-scene");
  }

  addEventListeners() {
    // scrolling
    this.input.on(
      "wheel",
      (
        pointer: any,
        objects: any,
        deltaX: number,
        deltaY: number,
        deltaZ: number
      ) => {
        pointer.event.preventDefault();
        const maxScrollRange =
          ((this.game as PreviewPhaserGame).previewRoomObject.length - 1) *
          this.renderer.width;

        let delta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY;

        let targetScrollX = Math.min(
          this.cameras.main.scrollX + delta,
          maxScrollRange
        );
        targetScrollX = Math.max(0, targetScrollX);

        EventBridge.emit("camera.scrollX", targetScrollX);

        // this.cameras.main.setScroll(targetScrollX, 0);
        // useGameStore.getState().setCurrentXPosition(targetScrollX);
      }
    );

    EventBridge.on("camera.scrollX", (scrollX: number) => {
      this.cameras.main.setScroll(scrollX, 0);
    });

    EventBridge.on("preview.setUsingItem", (object: IRoomObject) => {
      this.itemBeingUsed = this.roomObjects[object.ref] as PreviewRoomObject;
      this.setUserItemClickOnCooldown(50);
    });

    EventBridge.on("preview.completeObjectAction", (sourceObject: any) => {
      sourceObject.completeAction();
    });

    EventBridge.on("preview.addedToInventory", (sourceObject: IRoomObject) => {
      this.roomObjects[sourceObject.ref].destroy();
      (
        this.roomObjects[sourceObject.ref] as PreviewRoomObject
      ).completeAction();
    });

    EventBridge.on("preview.incrementActionsDone", () => {
      this.actionsDone++;
      EventBridge.emit("ui.setProgress", this.actionsDone / this.totalActions);
    });

    EventBridge.on("object.hintUsed", () => {
      Object.values(this.roomObjects).forEach(object => {
        if (object.actions.length > 0 && object.currentAction < object.actions.length) {
          (object as PreviewRoomObject).hintUsed()
        }
      });
    })
  }

  removeEventListeners() {
    EventBridge.remove("camera.scrollX");
    EventBridge.remove("roomEditor.selectObject");
    EventBridge.remove("ui.completeTextModal");
    EventBridge.remove("ui.completeItemModal");
    EventBridge.remove("preview.completeObjectAction");
    EventBridge.remove("preview.addedToInventory");
    EventBridge.remove("preview.incrementActionsDone");
    EventBridge.remove("object.hintUsed");
  }

  destoryPhaser() {
    this.game.destroy(true);
    this.sys.game.destroy(true);
  }

  setUserItemClickOnCooldown(cooldown: number) {
    this.userItemClickOnCooldown = true;
    setTimeout(() => {
      this.userItemClickOnCooldown = false;
    }, cooldown);
  }
  public async getAssets(): Promise<any> {
      let assets = await super.getAssets();
      let uniqueObjectsName = new Set((this.game as PreviewPhaserGame).previewRoomObject.objects.map(object => object.asset.name).flat());
      return assets.filter((asset: IAsset) => uniqueObjectsName.has(asset.name));
  }

}
