import { API_URL } from "@/constants";
import axios from "axios";

// Seperate instance for refresh token so we can intercept it
// specifically
const refreshInstance = axios.create({
  baseURL: API_URL,
});

refreshInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("id_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

refreshInstance.interceptors.response.use((response) => {
  localStorage.setItem("next_refresh", (Date.now() + 10 * 60 * 1000).toString());
  localStorage.setItem("id_token", response.data.id_token);
  return response;
});

// Main axios instance
const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const nextRefresh = localStorage.getItem("next_refresh");
    const token = localStorage.getItem("id_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (nextRefresh && token && parseInt(nextRefresh) < Date.now()) {
      refreshToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
        const redirectUri = encodeURIComponent(window.location.pathname);
        window.location.href = `/login?final_redirect_uri=${redirectUri}`;
    }
    if (error.response.status === 403) {
        const errorMessage = encodeURIComponent(error.response.data.message);
        window.location.href = `/error?status=403&message=${errorMessage}`;
    }
    return Promise.reject(error);
  }
);

export default instance;

export function refreshToken() {
  return refreshInstance.get(`/auth/refreshToken`);
}
