import { useGameStore } from "@/store/game-store";
import {
  Badge,
  Button,
  List,
  ListItem,
  Modal,
  Panel,
  Select,
} from "@appkit4/react-components";
import styles from "@/components/object-properties/ObjectProperties.module.scss";
import { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { IRoomEditorObject, TObjectOrderDirection } from "@/types/game";
import { Directions } from "@/constants";
import { FormattedMessage } from "react-intl";
import { Tabs, Tab } from "@appkit4/react-components/tabs";
import { isValidColorInput } from "@/utils/helpers";
import { SketchPicker } from "react-color";
import useOnOutsideClick from "@/hooks/useOnOutsideClick";
import SvgComponent from "../svg/SvgComponent";
import { useQuery } from "react-query";
import { getAssetSvg } from "@/api/assets";
import { text } from "stream/consumers";

const ObjectProperties = () => {
  const intl = useIntl();
  const colorPickerRef = useRef(null);
  const {
    game,
    currentRoomIndex,
    selectedObjectIndex,
    updateObject,
    unselectObject,
    removeObjectFromRoom,
    toggleObjectLock,
    changeObjectOrder,
    updateObjectColor,
  } = useGameStore((state) => state);
  const selected =
    selectedObjectIndex != null
      ? game.rooms[currentRoomIndex].objects[selectedObjectIndex]
      : undefined;
  const locked: boolean = selected?.isDisabled || false;
  const colors = selected?.colorPalette!;
  const [showDeleteObjectModal, setShowDeleteObjectModal] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorPickerIndex, setColorPickerIndex] = useState<number>(0);
  const [popupCoords, setPopupCoords] = useState({ x: 0, y: 0 });
  const [textureUrl, setTextureUrl] = useState(undefined);

  const svgQuery = useQuery({
    queryKey: ["getAssetSvg", textureUrl],
    queryFn: () => getAssetSvg(textureUrl),
    enabled: textureUrl != null,
  });

  useOnOutsideClick(colorPickerRef, () => setShowColorPicker(false));

  const updateObjectProperty = (payload: any) => {
    const newObject: any = {
      ...selected,
      isVisible:
        payload.isVisible == undefined
          ? selected?.isVisible || false
          : payload.isVisible,
      isDisabled: payload.isDisabled || selected?.isDisabled || false,
      currentState: payload.currentState || selected?.currentState || null,
    };
    updateObject(newObject);
  };

  const changeOrder = (direction: TObjectOrderDirection) => {
    if (selected == null) {
      console.error("No object selected");
      return;
    }
    changeObjectOrder(selected, direction);
  };

  const toggleLocked = (selected?: IRoomEditorObject | null) => {
    if (selected == null) {
      console.error("No object selected");
      return;
    }
    toggleObjectLock(selected);
  };

  const removeObject = (selected: IRoomEditorObject) => {
    unselectObject();
    removeObjectFromRoom(selected);
  };

  const data = [
    {
      label: intl.formatMessage({ id: "object.properties.forward" }),
      onClick: () => {
        changeOrder(Directions.FORWARD);
      },
      icon: "send-forward",
      testId: "object-properties-send-forward",
    },
    {
      label: intl.formatMessage({ id: "object.properties.backward" }),
      onClick: () => {
        changeOrder(Directions.BACKWARD);
      },
      icon: "send-backward",
      testId: "object-properties-send-backward",
    },
    {
      label: intl.formatMessage({ id: "object.properties.front" }),
      onClick: () => {
        changeOrder(Directions.FRONT);
      },
      icon: "send-front",
      testId: "object-properties-send-front",
    },
    {
      label: intl.formatMessage({ id: "object.properties.back" }),
      onClick: () => {
        changeOrder(Directions.BACK);
      },
      icon: "send-back",
      testId: "object-properties-send-back",
    },
    {
      label: locked
        ? intl.formatMessage({ id: "object.properties.unlock" })
        : intl.formatMessage({ id: "object.properties.lock" }),
      onClick: () => {
        toggleLocked(selected);
      },
      icon: locked ? "lock" : "lock-closed",
      selected: locked,
      testId: "object-properties-lock",
    },
    {
      label: intl.formatMessage({ id: "object.properties.remove" }),
      onClick: () => {
        setShowDeleteObjectModal(true);
      },
      icon: "delete",
      testId: "object-properties-delete",
    },
  ];

  const deleteModalRef = useRef<any>();

  useEffect(() => {
    if (selected == null) {
      return;
    }
    setTextureUrl(
      `${selected.asset.states[selected.currentState].textureUrl}_thumbnail`
    );
  }, [selected]);

  useEffect(() => {
    if(deleteModalRef && deleteModalRef.current) {
      for(const ev of ['mouseup', 'mousedown', 'touchstart', 'touchmove', 'touchend', 'touchcancel']) {
        deleteModalRef.current.addEventListener(ev, e => {
          e.stopPropagation();
        })
      }
    }
  }, [deleteModalRef.current])

  if (selected == null) {
    return <></>;
  }

  const renderColorRow = (index: number) => {
    return (
      <div className={styles.colorRow}>
        <div className={styles.desc}>
          <button
            className={styles.btn}
            onClick={(e) => {
              setPopupCoords({
                x: e.clientX,
                y: e.clientY + window.scrollY,
              });
              setColorPickerIndex(index);
              setShowColorPicker(true);
            }}
            style={
              isValidColorInput(colors[index])
                ? { backgroundColor: `#${colors[index]}` }
                : { backgroundColor: "buttonface" }
            }
          />
          <p>
            <FormattedMessage id={`game.form.color${index + 1}`} />
          </p>
        </div>
        <button
          className={styles.resetBtn}
          onClick={() =>
            updateObjectColor({
              index,
              colorHexValue: selected.asset.defaultColorPalette[index],
            })
          }
        >
          Reset
        </button>
      </div>
    );
  };

  return (
    <>
      <Panel title="Properties" className={styles.panel}>
        <Tabs type="underline">
          <Tab label="Position">
            <div className={styles.container}>
              <span className="ap-p-spacing-3">{selected.name}</span>
              <div className={styles["img-container"]}>
                <SvgComponent
                  svgString={svgQuery.data?.data}
                  colors={colors}
                  alt={selected.asset.name}
                />
              </div>
            </div>
            <List
              data={data}
              renderItem={(item) => (
                <ListItem
                  data-testid={item.testId}
                  onClick={item.onClick}
                  className={`${styles.list_item} ${
                    item.selected ? "selected" : ""
                  }`}
                >
                  <img
                    src={`/icons/${item.icon}.svg`}
                    className={styles.icon}
                  />
                  <span>{item.label}</span>
                </ListItem>
              )}
              itemKey="label"
            />
            <p className="ap-my-spacing-4">
              <FormattedMessage id="object.properties.initialState" />
            </p>
            <Select
              className={`${styles.dropdown} ap-my-spacing-4`}
              placeholder=""
              data={[
                { label: "Visible", value: "visible" },
                { label: "Hidden", value: "hidden" },
              ]}
              value={selected.isVisible ? "visible" : "hidden"}
              onSelect={(e) =>
                updateObjectProperty({ isVisible: e == "visible" })
              }
            />
            <Select
              className={`${styles.dropdown} ap-mb-spacing-6`}
              placeholder=""
              data={
                selected.asset.states
                  ? Object.values(selected.asset.states).map((state) => ({
                      label: state.name,
                      value: state.name,
                    }))
                  : [{ label: "Stateless", value: "" }]
              }
              onSelect={(e) => updateObjectProperty({ currentState: e })}
              value={selected.currentState}
              disabled={selected.asset.states == null}
            />
            <Modal
              ref={deleteModalRef}
              visible={showDeleteObjectModal}
              title={intl.formatMessage({ id: "object.delete.modal.title" })}
              ariaLabel={intl.formatMessage({
                id: "object.delete.modal.title",
              })}
              onCancel={() => {
                setShowDeleteObjectModal(false);
              }}
              modalStyle={{ width: "33.75rem" }}
              footerStyle={{
                paddingTop: "8px",
              }}
              header={<Badge type="danger" value="Warning"></Badge>}
              icons={""}
              footer={
                <div className="modal-buttons">
                  <Button
                    kind="secondary"
                    onClick={() => {
                      setShowDeleteObjectModal(false);
                    }}
                  >
                    <FormattedMessage id="button.cancel" />
                  </Button>
                  <Button
                    data-testid="object-properties-modal-delete"
                    kind="negative"
                    onClick={() => {
                      removeObject(selected);
                      setShowDeleteObjectModal(false);
                    }}
                  >
                    <FormattedMessage id="object.properties.remove" />
                  </Button>
                </div>
              }
            >
              <p>
                <FormattedMessage id="object.delete.modal.description" />
              </p>
            </Modal>
          </Tab>
          <Tab label="Color">
            <div className={styles.container}>
              <span className="ap-p-spacing-3">{selected.name}</span>
              <div className={styles["img-container"]}>
                <SvgComponent svgString={svgQuery.data?.data} colors={colors} />
              </div>
            </div>
            <div className={styles.colorSection}>
              <p>Current Colors</p>
              {colors.map((color, index) => renderColorRow(index))}
            </div>
          </Tab>
        </Tabs>
      </Panel>
      {showColorPicker && (
        <div
          className={styles.colorPicker}
          style={{ top: popupCoords.y, left: popupCoords.x }}
          ref={colorPickerRef}
        >
          <SketchPicker
            color={selected.colorPalette[colorPickerIndex]}
            onChangeComplete={(color: any) => {
              updateObjectColor({
                index: colorPickerIndex,
                colorHexValue: color.hex.split("#")[1],
              });
            }}
            disableAlpha={true}
            // Add preset colors if they are defined on game.colorPalette
            presetColors={game.colorPalette.map((color, index) => {
              return {
                color: `#${color}`,
                title: intl.formatMessage({ id: `level.${index + 1}` }),
              };
            })}
          />
        </div>
      )}
    </>
  );
};

export default ObjectProperties;
