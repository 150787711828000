import UIScene from "@/phaser/scenes/UIScene";

export default class ProgressBar extends Phaser.GameObjects.Container {
  public scene: UIScene;
  public progress: number = 0;

  public background: Phaser.GameObjects.Graphics;
  public bar: Phaser.GameObjects.Graphics;

  public progressBarWidth: number;
  public progressBarHeight: number;

  constructor(scene: UIScene, width: number, height: number) {
    super(scene);
    this.scene = scene;

    this.progressBarWidth = width;
    this.progressBarHeight = height;

    this.background = new Phaser.GameObjects.Graphics(scene);
    this.background.lineStyle(1, 0xffffff);
    this.background.strokeRect(0, 0, width, height);

    this.bar = new Phaser.GameObjects.Graphics(scene);

    this.add(this.background);
    this.add(this.bar);
  }

  public setProgress(progress: number) {
    this.progress = Math.min(1, progress);
    this.progress = Math.max(0, this.progress);

    this.bar.clear();
    this.bar.fillStyle(0x00ff76);
    this.bar.fillRect(
      0,
      0,
      this.progressBarWidth * this.progress,
      this.progressBarHeight
    );
  }
}
