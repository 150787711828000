import { Footer } from "@appkit4/react-components/footer";
import styles from "./AppFooter.module.scss";

const AppFooter = () => {
  const footerContent =
    "© 2023 PricewaterhouseCoopers LLP. All rights reserved. In this document, 'PwC' refers to the UK member firm, and may sometimes refer to the PwC network.  Each member firm is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.";
  const footerType = "links";
  const footerLinks = [
    {
      name: "Privacy Statement",
      href: "https://signin.test.access.pwc.com/productInfo/privacy-statement?goto=https:%2F%2Fsignin.test.access.pwc.com:443%2Fopenam%2Foauth2%2Fventuressso%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3Dpwc_escape_room_test%26redirect_uri%3Dhttps:%2F%2Fescape-rooms-creator-dev.azureedge.net%2Fauthorize%26scope%3Dventures%2520openid%26realm%3D%2Fventuressso%26state%3D%2F&realm=%2Fventuressso",
      target: "_blank",
    },
    {
      name: "Terms of Use",
      href: "https://signin.test.access.pwc.com/productInfo/terms-of-use?goto=https:%2F%2Fsignin.test.access.pwc.com:443%2Fopenam%2Foauth2%2Fventuressso%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3Dpwc_escape_room_test%26redirect_uri%3Dhttps:%2F%2Fescape-rooms-creator-dev.azureedge.net%2Fauthorize%26scope%3Dventures%2520openid%26realm%3D%2Fventuressso%26state%3D%2F&realm=%2Fventuressso",
      target: "_blank",
    },
  ];

  return (
    <Footer
      className={styles.footerWrapper}
      content={footerContent}
      type={footerType}
      links={footerLinks}
    ></Footer>
  );
};

export default AppFooter;
