import UIScene from "@/phaser/scenes/UIScene";
import BaseModal from "../BaseModal";

import { v4 as uuidv4 } from "uuid";

export default class VideoModal extends BaseModal {
  message?;
  videoPlayer;
  video;
  isAudio: boolean;

  constructor(
    scene: UIScene,
    mediaUrl: string,
    isAudio: boolean | undefined = false,
    optionalMessage: string | undefined = undefined,
    continueCallback?: () => void
  ) {
    super(scene, continueCallback && "CONTINUE", continueCallback);
    this.isAudio = isAudio;
    const messageExists =
      optionalMessage == null || optionalMessage.length == 0 ? false : true;
    const videoWidth = messageExists ? 640 : 960;
    const videoHeight = messageExists ? 360 : 540;

    if (messageExists) {
      this.message = new Phaser.GameObjects.Text(
        scene,
        scene.renderer.width / 2,
        isAudio ? 150 : 100,
        optionalMessage!,
        {
          fontFamily: "Roboto-Regular",
          fontSize: "20px",
          color: "#FFFFFF",
          wordWrap: { width: 900, useAdvancedWrap: true },
          align: "center",
        }
      )
        .setOrigin(0.5, 0)
        .setLineSpacing(8);
      this.add(scene.add.existing(this.message));
    }

    this.videoPlayer = document.createElement("video");
    this.videoPlayer.playsInline = true;
    this.videoPlayer.src = mediaUrl;
    this.videoPlayer.width = videoWidth;
    this.videoPlayer.height = videoHeight;
    this.videoPlayer.autoplay = true;
    this.videoPlayer.controls = true;
    this.videoPlayer.disablePictureInPicture = true;
    (this.videoPlayer as any).controlsList = "nodownload";

    let videoY: number;

    if (isAudio) {
      this.videoPlayer.width = 900;
      this.videoPlayer.height = 50;
      videoY = this.message
        ? scene.renderer.height / 2
        : scene.renderer.height / 2 - 40;
    } else {
      videoY = this.message
        ? Math.max(
            this.message.getBottomCenter().y + 60,
            scene.renderer.height / 2 - videoHeight / 2 - 10
          )
        : 100;
    }

    this.video = scene.add
      .dom(scene.renderer.width / 2, videoY, this.videoPlayer)
      .setOrigin(0.5, 0);

    this.add(scene.add.existing(this.video));
  }
}
