export default class IconButton extends Phaser.GameObjects.Image {
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    callback: () => void,
    // tint: number = 0xffffff,
  ) {
    super(scene, x, y, texture);
    this.setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, callback, this)
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, this.handleOut, this)
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, this.handleOver, this);
  }

  private handleOut(pointer: Phaser.Input.Pointer) {
    this.setAlpha(1);
  }

  private handleOver(pointer: Phaser.Input.Pointer) {
    this.setAlpha(0.5);
  }
}
