import BaseScene from "@/phaser/scenes/BaseScene";
import GameScene from "@/phaser/scenes/GameScene";
import UIScene from "@/phaser/scenes/UIScene";
import EventBridge from "@/utils/EventBridge";

export default class Minimap extends Phaser.GameObjects.Container {
  public scene: UIScene;
  public sourceScene: BaseScene;
  public renderTexture: Phaser.GameObjects.RenderTexture;
  public background: Phaser.GameObjects.Rectangle;
  public cameraRect: Phaser.GameObjects.Graphics;
  public isPressed: boolean = false;

  constructor(scene: UIScene, sourceScene: BaseScene) {
    super(scene, scene.renderer.width / 2, scene.renderer.height - 75);

    this.scene = scene;
    this.sourceScene = sourceScene;

    let roomWidth = sourceScene.cameras.main.getBounds().width;
    let heightRatio = roomWidth / this.scene.renderer.height;
    let widthRatio = this.scene.renderer.width / roomWidth;

    this.scene.input.on("pointerdown", (pointer: any, objects: any, y: any) => {
      if (
        objects.findIndex((object: any) => object === this.background) !== -1
      ) {
        this.isPressed = true;

        this.moveCamera(
          pointer.downX -
            (this.scene.renderer.width / 2 - this.background.displayWidth / 2)
        );
      }
    });

    this.scene.input.on("pointerup", (pointer: any, objects: any, y: any) => {
      this.isPressed = false;
    });

    this.renderTexture = new Phaser.GameObjects.RenderTexture(
      scene,
      0,
      0,
      roomWidth,
      this.scene.renderer.height
    )
      .setOrigin(0.5, 0.5)
      .setDisplaySize(80 * heightRatio, 80);

    this.background = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      this.renderTexture.displayWidth,
      this.renderTexture.displayHeight,
      0x383838,
      0.7
    )
      .setOrigin(0.5, 0.5)
      .setStrokeStyle(2, 0)
      .setInteractive()

      // FIXME: fix click then drag bug.
      .on("pointermove", (pointer: any, x: number, y: number) => {
        if (this.isPressed) this.moveCamera(x);
      });

    EventBridge.on("camera.scrollX", (scrollX: number) => {
      this.cameraRect.setX(
        (this.renderTexture.displayHeight * scrollX) /
          this.scene.renderer.height
      );
    });

    this.cameraRect = new Phaser.GameObjects.Graphics(scene);
    this.cameraRect
      .lineStyle(3, 0xffffff, 0.7)
      .strokeRect(
        this.renderTexture.getTopLeft().x,
        this.renderTexture.getTopLeft().y,
        this.renderTexture.displayWidth * widthRatio,
        this.renderTexture.displayHeight
      );

    this.add(this.background);
    this.add(this.renderTexture);
    this.add(this.cameraRect);

    scene.add.existing(this);
  }

  public draw() {
    this.renderTexture.clear();
    this.sourceScene.children.each((item) => {
      if (item.constructor.name === "Rectangle2") {
        // Don't draw the blackout rectangle.
        return;
      }
      this.renderTexture.draw(
        item,
        (item as Phaser.GameObjects.Sprite).x,
        (item as Phaser.GameObjects.Sprite).y
      );
    });
  }

  private moveCamera(x: number) {
    let roomWidth = this.sourceScene.cameras.main.getBounds().width;
    let widthRatio = this.scene.renderer.width / roomWidth;

    let offsetX = x - (this.renderTexture.displayWidth * widthRatio) / 2;

    if (offsetX < 0) {
      offsetX = 0;
    }

    EventBridge.emit(
      "camera.scrollX",
      (offsetX / this.renderTexture.displayHeight) * this.scene.renderer.height
    );
    this.cameraRect.setX(
      Math.min(
        offsetX,
        this.background.displayWidth -
          this.renderTexture.displayWidth * widthRatio
      )
    );
  }

  public removeEventListeners(fromScene?: boolean | undefined): void {
    EventBridge.remove("camera.scrollX");
    this.scene.input.removeListener("pointerdown");
  }
}
