import BaseScene from "@/phaser/scenes/BaseScene";
import UIScene from "@/phaser/scenes/UIScene";
import Avatar from "../../Avatar";
import ProgressBar from "../ProgressBar";
import { avatarColors } from "@/constants";
import EventBridge from "@/utils/EventBridge";
import { TextModalPayload } from "../../modals/ModalPayloads";
import GameScene from "@/phaser/scenes/GameScene";
import Player from "../../session/Player";
import { createRandomString } from "@/utils/phaser-helpers";

export default class Hud extends Phaser.GameObjects.Container {
  public scene: UIScene;
  public sourceScene: BaseScene;

  public background: Phaser.GameObjects.Rectangle;
  public logo: Phaser.GameObjects.Image;

  public progressBar: ProgressBar;
  public progressBarLabel: Phaser.GameObjects.Text;

  public hintCounter: Phaser.GameObjects.Container;
  public hintCounterLabel: Phaser.GameObjects.Text;
  public hintButton: Phaser.GameObjects.Image;
  public hintsAvailable: number = 3;

  public playerList: Phaser.GameObjects.Container;
  public avatar?: Avatar;

  public teamScoreIcon: Phaser.GameObjects.Image;
  public teamScoreText: Phaser.GameObjects.Text;
  public teamScoreBackground: Phaser.GameObjects.Image; 
  public playerScoreIcon: Phaser.GameObjects.Image;
  public playerScoreText: Phaser.GameObjects.Text;
  public playerScoreBackground: Phaser.GameObjects.Image; 

  constructor(
    scene: UIScene,
    sourceScene: BaseScene,
    gameCompleted: boolean = false
  ) {
    super(scene);
    this.scene = scene;
    this.sourceScene = sourceScene;

    this.background = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      scene.renderer.width,
      100,
      0x060b16,
      0.9
    ).setOrigin(0, 0);

    this.add(this.background);

    this.logo = new Phaser.GameObjects.Image(
      scene,
      25,
      this.background.y + this.background.height / 2,
      "pwc-logo"
    ).setOrigin(0, 0.5).setScale(0.55);
    this.add(this.logo);

    this.progressBar = new ProgressBar(scene, 150, 6);
    this.progressBar.setProgress(0);
    this.progressBar.setPosition(
      this.background.x + 550,
      this.background.y + this.background.height / 2 + 15
    );

    this.progressBarLabel = new Phaser.GameObjects.Text(
      scene,
      this.progressBar.x + this.progressBar.progressBarWidth / 2,
      this.progressBar.y - 30,
      "PROGRESS",
      { color: "white", fontSize: "20px", fontFamily: "Nunito-Medium" }
    ).setOrigin(0.5, 0.5);

    this.hintCounter = new Phaser.GameObjects.Container(scene);
    Array.from({ length: 3 }).forEach((_, index) => {
      let hintIcon = new Phaser.GameObjects.Image(
        scene,
        index * 40,
        0,
        "hint-enabled"
      );
      this.hintCounter.add(hintIcon);
    });

    if (this.sourceScene.game.env === "game") {
      this.hintsAvailable = (
        this.sourceScene as GameScene
      ).game.session.game.rooms[
        (this.sourceScene as GameScene).activeRoomIndex
      ].hintsCounter;
      for (let i = 2; i >= this.hintsAvailable; i--) {
        (this.hintCounter.getAt(i) as Phaser.GameObjects.Image).setTexture(
          "hint-disabled"
        );
      }
    }

    this.hintCounter.setPosition(this.progressBar.x + 250, this.progressBar.y);

    this.hintCounterLabel = new Phaser.GameObjects.Text(
      scene,
      this.hintCounter.x + (40 * 2) / 2,
      this.hintCounter.y - 30,
      "HINTS",
      { color: "white", fontSize: "20px", fontFamily: "Nunito-Medium" }
    ).setOrigin(0.5, 0.5);

    this.hintButton = new Phaser.GameObjects.Image(
      scene,
      scene.renderer.width - 30,
      scene.renderer.height - 30,
      "hint-button"
    )
      .setOrigin(1, 1)
      .setInteractive()
      .on("pointerdown", () => {
        if (this.sourceScene.game.env === "preview") {
          EventBridge.emit("ui.hintUsed");
        } else {
          EventBridge.emit("game.useHint");
        }
      })
      .on("pointerover", () => {
        this.hintButton.setAlpha(0.7);
      })
      .on("pointerout", () => {
        this.hintButton.setAlpha(1);
      });

    this.avatar = this.loadMyPlayerAvatar();
    this.playerList = new Phaser.GameObjects.Container(scene);
    this.loadPlayersAvatars();

    this.add(this.avatar);
    this.add(this.playerList);

    this.add(this.progressBar);
    this.add(this.progressBarLabel);

    this.add(this.hintCounter);
    this.add(this.hintCounterLabel);
    this.add(this.hintButton);

    this.teamScoreIcon = new Phaser.GameObjects.Image(
      scene,
      30,
      this.background.y + 140,
      "xp-team"
    );
    this.teamScoreText = new Phaser.GameObjects.Text(
      scene,
      this.teamScoreIcon.x + 30,
      this.teamScoreIcon.y,
      "0",
      {
        color: "white",
        fontSize: "20px",
        fontFamily: "Nunito-Medium",
      }
    ).setOrigin(0, 0.5);
    this.teamScoreBackground = new Phaser.GameObjects.Image(
      scene,
      this.teamScoreIcon.x - 55,
      this.teamScoreIcon.y,
      "score-shadow"
    )
      .setOrigin(0, 0.5)
      .setScale(0.5)

    this.add(this.teamScoreBackground);
    this.add(this.teamScoreIcon);
    this.add(this.teamScoreText);

    this.playerScoreIcon = new Phaser.GameObjects.Image(
      scene,
      this.teamScoreIcon.x,
      this.teamScoreIcon.y + 50,
      "xp-player"
    );
    this.playerScoreText = new Phaser.GameObjects.Text(
      scene,
      this.playerScoreIcon.x + 30,
      this.playerScoreIcon.y,
      "0",
      { color: "white", fontSize: "20px", fontFamily: "Nunito-Medium" }
    ).setOrigin(0, 0.5);
    this.playerScoreBackground = new Phaser.GameObjects.Image(
      scene,
      this.playerScoreIcon.x - 55,
      this.playerScoreIcon.y,
      "score-shadow"
    )
      .setOrigin(0, 0.5)
      .setScale(0.5)

    this.add(this.playerScoreBackground);
    this.add(this.playerScoreIcon);
    this.add(this.playerScoreText);

    scene.add.existing(this);

    if (!gameCompleted) {
      this.addEventListeners();
    } else {
      this.progressBar.setVisible(false);
      this.progressBarLabel.setVisible(false);
      this.hintButton.setVisible(false);
      this.hintCounter.setVisible(false);
      this.hintCounterLabel.setVisible(false);
      this.playerScoreIcon.setVisible(false);
      this.playerScoreText.setVisible(false);
      this.playerScoreBackground.setVisible(false);
      this.teamScoreIcon.setVisible(false);
      this.teamScoreText.setVisible(false);
      this.teamScoreBackground.setVisible(false);
    }
  }

  private addEventListeners() {
    EventBridge.on("ui.hintUsed", () => {
      if (this.hintsAvailable <= 0) return;
      (
        this.hintCounter.getAt(
          this.hintsAvailable - 1
        ) as Phaser.GameObjects.Image
      ).setTexture("hint-disabled");
      this.hintsAvailable--;
      EventBridge.emit("object.hintUsed");

      const textModalPayload = {
        text:
          this.sourceScene.game.env === "preview"
            ? "These objects seem interesting!"
            : "Someone used a hint!",
      } satisfies TextModalPayload;
      EventBridge.emit("ui.openMessagePopup", textModalPayload);
    });

    EventBridge.on("ui.setProgress", (progress: number) => {
      this.progressBar.setProgress(progress);
    });

    EventBridge.on("ui.addToTeamScore", (score: number) => {
      let newScore = Number(this.teamScoreText.text) + score;
      this.teamScoreText.setText(`${newScore}`);
    });

    EventBridge.on("ui.addToPlayerScore", (score: number) => {
      let newScore = Number(this.playerScoreText.text) + score;
      this.playerScoreText.setText(`${newScore}`);
    });

    EventBridge.on(
      "ui.setScore",
      ({
        playerScore,
        teamScore,
      }: {
        playerScore?: number;
        teamScore?: number;
      }) => {
        if (playerScore !== undefined) {
          this.playerScoreText.setText(`${playerScore}`);
        }
        if (teamScore !== undefined) {
          this.teamScoreText.setText(`${teamScore}`);
        }
      }
    );
  }

  private removeEventListeners() {
    EventBridge.remove("ui.hintUsed");
    EventBridge.remove("ui.setProgress");
    EventBridge.remove("ui.addToTeamScore");
    EventBridge.remove("ui.addToPlayerScore");
    EventBridge.remove("ui.setScore");
  }

  destroy(fromScene?: boolean | undefined): void {
    super.destroy(fromScene);
    this.removeEventListeners();
  }

  createDummyAvatar(x: number, y: number) {
    return new Avatar(
      this.scene,
      x,
      y,
      {
        firstName: "X",
        lastName: "X",
        id: createRandomString(10),
        clientId: createRandomString(10),
        color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
      } as Player,
      40
    );
  }

  loadMyPlayerAvatar() {
    this.avatar?.destroy();
    this.avatar?.removeAll();

    if (this.sourceScene.session == null) {
      console.error("No session on scene");
      return this.createDummyAvatar(
        this.background.x + this.background.width - 50,
        this.background.y + this.background.height / 2
      );
    }

    const myPlayer = this.sourceScene.session.myPlayer;
    if (myPlayer == null) {
      return this.createDummyAvatar(
        this.background.x + this.background.width - 50,
        this.background.y + this.background.height / 2
      );
    }

    return new Avatar(
      this.scene,
      this.background.x + this.background.width - 50,
      this.background.y + this.background.height / 2,
      myPlayer,
      40,
      this.scene.openUserMenu
    );
  }

  loadPlayersAvatars() {
    this.playerList.setPosition(50, this.scene.renderer.height - 50);
    if (this.sourceScene instanceof GameScene) {
      const players = (this.sourceScene as GameScene).session!.players;
      // TODO: Decide whether to use all players vs other players
      const myPlayer = players.filter(
        (player) => player.id === this.sourceScene.user.id
      );
      const inactivePlayers = players.filter(
        (player) =>
          player.id !== this.sourceScene.user.id && player.inactive == true
      );
      const otherPlayers = players.filter(
        (player) =>
          player.id !== this.sourceScene.user.id && player.inactive == false
      );
      [...inactivePlayers, ...myPlayer, ...otherPlayers].forEach(
        (player, index) => {
          let avatar = new Avatar(this.scene, index * 30, 0, player, 40);
          this.playerList.add(avatar);
        }
      );
    }
  }

  removePlayerAvatars() {
    this.playerList.list.forEach((avatar) => avatar.destroy());
  }

  refreshPlayerAvatars(hud: Hud) {
    if (
      hud &&
      hud.removePlayerAvatars != null &&
      hud.loadPlayersAvatars != null
    ) {
      hud.removePlayerAvatars();
      hud.loadPlayersAvatars();
    }
  }
}
