import Phaser from "phaser";
import PreviewRoomObject from "@/phaser/classes/RoomObject/PreviewRoomObject";
import IconButton from "../buttons/IconButton";
import Item from "./Item";
import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import { IRoomObject } from "escape-rooms-types/types/game";
import EventBridge from "@/utils/EventBridge";

const TRAY_WIDTH = 30;
const MAX_ITEMS = 6;

export default class Inventory extends Phaser.GameObjects.Container {
  private items: Array<Item | null>;
  private tray;
  private openButton;
  private closeButton;
  private isTrayOpen: boolean;
  declare scene: UIScene;

  constructor(scene: UIScene) {
    super(scene);

    this.items = new Array(MAX_ITEMS).fill(null);
    this.isTrayOpen = false;

    // TODO: Make this invisible and not off to the side.
    this.tray = new Phaser.GameObjects.Image(
      scene,
      scene.renderer.width + 30,
      scene.renderer.height / 2,
      "inventory-tray"
    );

    this.openButton = new IconButton(
      scene,
      scene.renderer.width - 10,
      scene.renderer.height / 2,
      "open-inventory",
      () => this.openInventory(scene, this)
    );

    this.closeButton = new IconButton(
      scene,
      scene.renderer.width - 70,
      scene.renderer.height / 2,
      "close-inventory",
      () => this.closeInventory(scene, this)
    ).setVisible(false);
    // .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () =>
    //   this.tray.setAlpha(0.5)
    // )
    // .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () =>
    //   this.tray.setAlpha(1)
    // );

    this.add([this.tray, this.openButton, this.closeButton]);
  }

  // Used for inital population of inventory. Does not emit any events
  public loadInventory(inventoryObj: IRoomObject[]) {
    inventoryObj.forEach((itemObj, index) => {
      const newItem = new Item(this.scene as UIScene, itemObj, index);
      this.items[index] = newItem;
    })
    this.openInventory(this.scene, this);
  }

  public addToInventory(object: IRoomObject) {
    // Find index of first open inventory slot
    let inventoryIsFull = true;
    let index = 0;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i] == null) {
        inventoryIsFull = false;
        index = i;
        break;
      }
    }

    if (inventoryIsFull) {
      console.error("Inventory is full!");
      return;
    }

    const newItem = new Item(this.scene as UIScene, object, index);
    this.items[index] = newItem;
    this.openInventory(this.scene, this);
    EventBridge.emit(`${this.scene.env}.addedToInventory`, object);
  }

  public removeFromInventory(objectRef: string) {
    const item = this.items.find((item) => {
      if (item == null) {
        return;
      }
      return item.object.ref === objectRef;
    });

    item?.container.destroy();

    this.items = this.items.map((i) => {
      if (i == null) {
        return null;
      }
      if (i.object.ref == objectRef) {
        return null;
      }
      return i;
    });
  }

  public setInventory(items: Array<IRoomObject>) {
    this.items.forEach((item) => this.removeFromInventory(item?.object.ref!));
    items.forEach((item) => this.addToInventory(item));
  }

  public openInventory(scene: Phaser.Scene, inventory: Inventory) {
    inventory.openButton.setVisible(false);
    inventory.closeButton.setVisible(true);
    inventory.tray.setX(scene.renderer.width - 30);
    inventory.setItemsVisible();
    inventory.isTrayOpen = true;
  }

  public closeInventory(scene: Phaser.Scene, inventory: Inventory) {
    inventory.openButton.setVisible(true);
    inventory.closeButton.setVisible(false);
    inventory.tray.setX(scene.renderer.width + 30);
    inventory.setItemsInvisible();
    inventory.isTrayOpen = false;
  }

  private setItemsVisible() {
    this.items.forEach((item) => {
      if (item == null) {
        return;
      }
      item.container.setVisible(true);
    });
  }

  private setItemsInvisible() {
    this.items.forEach((item) => {
      if (item == null) {
        return;
      }
      item.container.setVisible(false);
    });
  }
}
