export default class XPAlert extends Phaser.GameObjects.Container {
  scene: Phaser.Scene;

  constructor(scene: Phaser.Scene, x: number, y: number, score: number) {
    super(scene, x, y);
    this.setAlpha(0);

    this.scene = scene;

    let xpSign = new Phaser.GameObjects.Image(
      scene,
      score < 10 ? -20 : -25,
      0,
      "xp-sign"
    );
    let xpText = new Phaser.GameObjects.Text(scene, 18, 0, `+${score}!`, {
      color: "white",
      fontStyle: "bold",
      fontSize: 20,
    }).setOrigin(0.5, 0.5);
    let background = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      score < 10
        ? xpSign.width + xpText.width + 26
        : xpSign.width + xpText.width + 18,
      40,
      0x4ead58
    );

    this.add(background);
    this.add(xpSign);
    this.add(xpText);

    scene.add
      .tween({ targets: this, y: y - 10, alpha: 1, duration: 200 })
      .on("complete", () => {
        scene.add
          .tween({ targets: this, alpha: 0, delay: 1000, y: y, duration: 200 })
          .on("complete", () => {
            this.destroy(true);
          });
      });
  }
}
