import { useState } from "react";
import { Tab, Tabs } from "@appkit4/react-components";
import AssetList from "@/components/asset-list";
import ObjectList from "@/components/object-list/ObjectList";
import styles from "./AssetsTab.module.scss";
import { useIntl } from "react-intl";

const AssetsTab = () => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState<number>(0);

  const onTabChange = (i: number, value: string) => {
    setActiveTab(i);
  };

  return (
    <div className={styles.container}>
      <Tabs type="filled" activeIndex={activeTab} onTabChange={onTabChange} stretched>
        <Tab label={intl.formatMessage({id: "room.assets.tab.library"})}>
          <AssetList />
        </Tab>
        <Tab label={intl.formatMessage({id: "room.assets.tab.game"})}>
          <ObjectList />
        </Tab>
      </Tabs>
    </div>
  );
};

export default AssetsTab;
