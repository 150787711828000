import UIScene from "@/phaser/scenes/UIScene";

interface IField {
  label: string;
  fn: () => void;
}

class Dropdown extends Phaser.GameObjects.Container {
  private closeTimeout: NodeJS.Timeout | null;
  public scene: UIScene;

  constructor(scene: UIScene, x: number, y: number, field: IField) {
    // Only 1 field atm, will refactor if we need >1.
    super(scene, x, y);
    this.scene = scene;
    this.closeTimeout = null;

    const background = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      130,
      40,
      0x060b16
    )
      .setOrigin(1, 0)
      .setInteractive()
      .on("pointerout", () => this.startCloseTimer())
      .on("pointerover", () => this.clearCloseTimer());

    const button = new Phaser.GameObjects.Rectangle(
      scene,
      -3,
      3,
      124,
      34,
      0xd3d3d3
    )
      .setOrigin(1, 0)
      .setAlpha(0.01)
      .setInteractive()
      .on("pointerover", () => {
        button.setAlpha(0.2);
        this.clearCloseTimer();
      })
      .on("pointerout", () => button.setAlpha(0.01))
      .on("pointerdown", () => field.fn());

    const text = new Phaser.GameObjects.Text(scene, -30, 10, field.label, {
      fontFamily: "Roboto-Medium",
      fontSize: "16px",
    }).setOrigin(1, 0);

    this.add(background);
    this.add(button);
    this.add(text);
  }

  startCloseTimer() {
    if (this.closeTimeout != null) {
      return;
    }
    this.closeTimeout = setTimeout(() => {
      this.close();
    }, 1000);
  }

  clearCloseTimer() {
    if (this.closeTimeout == null) {
      return;
    }
    clearTimeout(this.closeTimeout);
    this.closeTimeout = null;
  }

  close() {
    this.destroy();
  }
}

export default Dropdown;
