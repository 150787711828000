import {
  Avatar,
  Button,
  Column,
  Modal,
  Pagination,
  PaginationRef,
  Search,
  Select,
  Table,
} from "@appkit4/react-components";
import { IGame } from "escape-rooms-types/types/game";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  createGameSession,
  getGameSessions,
  updateGameSession,
} from "@/api/session";
import { getGames } from "@/api/game";

const SessionManager = () => {
  const paginationRef = useRef<PaginationRef>(null);
  const navigate = useNavigate();
  const [sessions, setSessions] = useState<IGameSession[]>([]);
  const [games, setGames] = useState<Array<IGame>>([]);
  const [gameId, setGameId] = useState<string>();

  const [searchString, setSearchString] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortBy, setSortBy] = useState<string>("_id");

  const [totalPages, setTotalPages] = useState<number>(1);

  const [showAddSessionModal, setShowAddSessionModal] =
    useState<boolean>(false);

  const resetFilters = useCallback(() => {
    setSearchString("");
    setStatus("");
  }, []);

  const getGameSessionsQuery = useQuery({
    queryKey: ["getGameSessions", searchString],
    queryFn: () =>
      getGameSessions(page, limit, sortBy, sortOrder, searchString, status),
    onSuccess: (res) => {
      setSessions(res.data.data);

      const totalPages = Math.max(Math.ceil(res.data.count / limit), 1);
      setTotalPages(totalPages);
      setPage(Math.min(page, totalPages));
      if (paginationRef.current) {
        paginationRef.current.setPageNumber(Math.min(page, totalPages));
      }
    },
  });

  const createGameSessionQuery = useQuery({
    queryKey: ["createGameSession"],
    queryFn: () => createGameSession({ gameId }),
    onSuccess: (res) => {
      getGameSessionsQuery.refetch();
    },
    enabled: false,
  });

  const getGamesQuery = useQuery({
    queryKey: ["getGames"],
    queryFn: () => getGames(1, 1000, "_id", 1, "status", "published"),
    onSuccess: (res) => {
      setGames(res.data.data);
    },
  });

  const openGameSession = (session: IGameSession) => {
    return navigate(`/sessions/${session._id}`);
  };

  useEffect(() => {
    getGameSessionsQuery.refetch();
  }, [page, limit, sortOrder, sortBy, searchString, status]);

  const addSession = () => {
    createGameSessionQuery.refetch();
    setShowAddSessionModal(false);
    setGameId(undefined);
  };

  const renderDate = (row: any, field: string) => {
    if (!row[field]) return "";

    let date = new Date(row[field]);
    return (
      <div className="ap-font-weight-2">
        {date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </div>
    );
  };

  const renderClient = (row: any, field: string) => {
    return "PWC";
  };

  const renderAction = (row: any, field: string) => {
    return (
      <Button
        className="Appkit4-icon icon-setting-outline"
        kind="primary"
        onClick={() => {
          openGameSession(row);
        }}
      />
    );
  };

  const renderStatus = (row: any, field: string) => {
    if (row.status === "pending") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle ap-bg-color-background-warning"></div>
          <div>Not Started</div>
        </div>
      );
    }
    if (row.status === "started") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle bg-blue"></div>
          <div>Started</div>
        </div>
      );
    }
    if (row.status === "finished") {
      return (
        <div className="flex align-center gap-1">
          <div className="circle ap-bg-color-background-success"></div>
          <div>Completed</div>
        </div>
      );
    }
  };

  const renderName = (row: any, field: string) => {
    if (row.name == "") {
      return "N/A"
    }
    return row.name;
  };

  const renderCreator = (row: any, field: string) => {
    const creator = row["creator"] || "N/A";
    const label =
      creator === "N/A"
        ? "NA"
        : creator
            .split(" ")
            .map((word: string) => word[0])
            .join("")
            .toUpperCase();
    return (
      <div className="flex gap-1 align-center">
        <Avatar label={label} />
        <div>{creator}</div>
      </div>
    );
  };

  const renderCreatedAt = (row) => {
    return new Date(row.createdAt).toLocaleString();
  };

  const renderStartedAt = (row) => {
    if (row.gameStarted == null) {
      return "N/A";
    }
    return new Date(row.gameStarted).toLocaleString();
  };

  const renderCompletedAt = (row) => {
    if (row.gameCompleted == null) {
      return "N/A";
    }
    return new Date(row.gameCompleted).toLocaleString();
  };

  const onSort = (sortKey: string, sortingPhase: number) => {
    if (sortingPhase == 0) {
      setSortOrder(1);
      return;
    }
    setSortOrder(sortingPhase === 1 ? -1 : 1);
  };

  return (
    <div className="ap-container">
      <h2 className="ap-typography-4 ap-font-weight-3 ap-mt-spacing-6 ap-mb-spacing-6">
        Game sessions
      </h2>
      <div className="row">
        <div className="col-12 flex gap-1 align-center">
          <Search
            placeholder="Search by name or creator"
            className="max-content"
            searchValue={searchString}
            onChange={(e) => {
              setSearchString(e);
            }}
          />
          <Select
            data={[
              { value: "", label: "All" },
              { value: "pending", label: "Not started" },
              { value: "started", label: "Active" },
              { value: "finished", label: "Completed" },
            ]}
            value={status}
            onSelect={(e) => setStatus(e)}
            placeholder="Status"
          />
          <Button kind="secondary" className="ml-auto" onClick={resetFilters}>
            Reset filters
          </Button>
        </div>
        <div className="col-12">
          <Table
            originalData={sessions}
            hasTitle
            className="data-table"
            onSort={onSort}
          >
            <Column field="_id" renderCell={renderName}>
              Name
            </Column>
            <Column field="_id" renderCell={renderStatus}>
              Status
            </Column>
            <Column field="_id" renderCell={renderCreator}>
              Creator
            </Column>
            <Column field="createdAt" renderCell={renderCreatedAt} sortKey="_id">
              DateTime created
            </Column>
            <Column field="gameStarted" renderCell={renderStartedAt}>
              DateTime started
            </Column>
            <Column field="gameCompleted" renderCell={renderCompletedAt}>
              DateTime completed
            </Column>
            <Column field="_id" renderCell={renderAction}>
              Actions
            </Column>
          </Table>
        </div>

        <div className="col-12 flex justify-center ap-my-spacing-6">
          <Pagination
            ref={paginationRef}
            current={page}
            onPageChange={(page) => {
              setPage(page);
            }}
            total={totalPages}
          />
        </div>
      </div>
      <Modal
        visible={showAddSessionModal}
        title={"Add a new session?"}
        ariaLabel={"Add a new session?"}
        onCancel={() => {
          setShowAddSessionModal(false);
          setGameId(undefined);
        }}
        modalStyle={{ width: "33.75rem", overflow: "visible" }}
        bodyStyle={{ overflow: "visible" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowAddSessionModal(false);
                setGameId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowAddSessionModal(false);
                addSession();
              }}
            >
              Create session
            </Button>
          </div>
        }
      >
        <div className="ap-py-spacing-8">
          <Select
            data={games.map((game) => ({ value: game._id, label: game.name }))}
            placeholder="Game"
            value={gameId}
            onSelect={(e) => setGameId(e)}
            noResultFound="No published games found."
          />
        </div>
      </Modal>
    </div>
  );
};

export default SessionManager;
