import { IRoomObject } from "escape-rooms-types/types/game";
import BaseAction from "../actions/BaseAction";
import EventBridge from "@/utils/EventBridge";

export default class BaseRoomObject {
  public _id?: string;
  public ref: string;
  public gameObject: IRoomObject;
  public x: number;
  public y: number;
  public scene: Phaser.Scene;
  public sprite: Phaser.GameObjects.Sprite;
  public actions: Array<BaseAction>;
  public currentAction: number;
  public isVisible: boolean;
  public isDisabled: boolean;
  public currentState: string;
  public isUnsaved: boolean;
  public glow?: Phaser.FX.Glow;

  constructor(
    scene: Phaser.Scene,
    xPos: number,
    yPos: number,
    gameObject: IRoomObject
  ) {
    this._id = gameObject._id;
    this.ref = gameObject.ref;
    this.scene = scene;
    this.gameObject = gameObject;
    this.x = xPos;
    this.y = yPos;
    this.isVisible = gameObject.isVisible;
    this.isDisabled = gameObject.isDisabled;
    this.currentAction = gameObject.currentAction;
    this.actions = [];
    this.currentState = gameObject.currentState;
    this.isUnsaved = !this.gameObject._id;
    this.sprite = this.addToScene(xPos, yPos);
  }

  public setVisible(isVisible: boolean) {
    if (isVisible) {
      this.sprite.alpha = 1;
    }
    if (!isVisible) {
      this.sprite.alpha = 0.1;
    }
    this.isVisible = isVisible;
  }

  public setIsDisabled(isDisabled: boolean) {
    if (!isDisabled) {
      this.sprite.setInteractive();
    } else {
      this.sprite.disableInteractive();
    }
    this.isDisabled = isDisabled;
  }

  public toggleIsDisabled() {
    if (this.isDisabled) {
      this.setIsDisabled(false);
    } else {
      this.setIsDisabled(true);
    }
  }

  public getTextureFromState(state: string) {
    const textureKey =
      `${this.ref}_${this.gameObject.asset.states[state].name}_scene`.toLowerCase();
    return textureKey;
  }

  public getThumbnailFromState(state: string) {
    const textureKey =
      `${this.ref}_${this.gameObject.asset.states[state].name}_thumbnail`.toLowerCase();
    return textureKey;
  }

  public changeState(newState: string | null, oldState: string) {
    if (this.scene === null) return;
    if (newState == null || this.scene.sys.textures === null) {
      return;
    }
    this.sprite.setTexture(this.getTextureFromState(newState) || "placeholder");
    const xMovement = this.gameObject.asset.states[newState].xOffset - this.gameObject.asset.states[oldState].xOffset
    const yMovement = this.gameObject.asset.states[newState].yOffset - this.gameObject.asset.states[oldState].yOffset
    this.sprite.setX(this.sprite.x + xMovement)
    this.sprite.setY(this.sprite.y + yMovement)
    this.currentState = newState;
    EventBridge.emit("minimap.redraw");
  }

  public updateObject(object: IRoomObject) {
    if (object.isVisible != null) {
      this.setVisible(object.isVisible);
    }
    if (object.isDisabled != null) {
      this.setIsDisabled(object.isDisabled);
    }
    if (object.currentState != null) {
      this.changeState(object.currentState, this.currentState);
    }
    this.gameObject = object;

    EventBridge.emit("minimap.redraw");
  }

  public setPosition(x: number, y: number) {
    this.sprite.setPosition(x, y);
    this.x = x;
    this.y = y;
  }

  public focus() {
    // when selected
  }

  public blur() {
    // when deselected
  }

  public destroy() {
    this.sprite.destroy();
    EventBridge.emit("minimap.redraw");
  }

  protected addToScene(xPos: number, yPos: number): Phaser.GameObjects.Sprite {
    return {} as Phaser.GameObjects.Sprite;
  }

  public addGlow(
    glowColor: number = 0xffffff,
    outerStrength: number = 4,
    innerStrength: number = 1
  ) {
    if (this.glow != null) {
      this.removeGlow();
    }
    this.glow = this.sprite.preFX!.addGlow( glowColor, outerStrength, innerStrength)
  }

  public removeGlow() {
    if (this.glow == null) {
      return
    }
    this.glow.setActive(false);
    this.glow = undefined;
  }

  public glowIfInteractive() {
    if (this.currentAction < this.actions.length) {
      this.addGlow();
    }
  }
}
