import { IRoom } from "escape-rooms-types/types/game";

type ThrottleFunction<T extends (...args: any[]) => any> = (
  func: T,
  delay: number
) => T & { cancel: () => void };


export const hexToDecimal = (hex: string) => {
  return parseInt(hex, 16);
};

export const isValidColorInput = (string: string) => {
  if (string == "") {
    return true;
  }
  const hexRegex = /[0-9A-Fa-f]{6}/g;
  return hexRegex.test(string);
};

export const isValidHexColor = (string: string) => {
  if (string.length != 3 && string.length != 6) {
    return false;
  }
  const hexRegex = /[0-9A-Fa-f]{6}/g;
  return hexRegex.test(string);
};

export const insertCustomColorsIntoSvgText = (
  svgText: string,
  customColors: string[]
): string => {
  let newSvg = svgText;
  customColors.forEach((color, index) => {
    const regexStr = `color-${
      index + 1
    }\{fill:#([0-9a-fA-F]{6}|[0-9a-fA-F]{3});}`;
    const regex = new RegExp(regexStr);
    newSvg = newSvg.replace(regex, `color-${index + 1}{fill:#${color};}`);
  });
  return newSvg;
};

export const getIdOfNextRoom = (currentRoomId: string, rooms: IRoom[]) => {
  const currentRoomIndex = rooms.findIndex(
    (room) => room._id === currentRoomId
  );
  const nextRoom = rooms[currentRoomIndex + 1];
  return nextRoom ? nextRoom._id : undefined;
};

export const throttle: ThrottleFunction<any> = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timerId: NodeJS.Timeout | null;
  let lastExecTime = 0;

  const throttled = (...args: any[]): ReturnType<T> | undefined => {
    const currentTime = Date.now();
    const timeSinceLastExec = currentTime - lastExecTime;

    if (timeSinceLastExec > delay) {
      clearTimeout(timerId!);
      lastExecTime = currentTime;
      return func.apply(this, args);
    } else if (!timerId) {
      timerId = setTimeout(() => {
        lastExecTime = Date.now();
        timerId = null;
        func.apply(this, args);
      }, delay - timeSinceLastExec);
    }
  };

  throttled.cancel = () => {
    clearTimeout(timerId!);
    timerId = null;
  };

  return throttled as T & { cancel: () => void };
};

export const addToClipboard = (
  text: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      onSuccess && onSuccess();
    })
    .catch((error) => {
      onError && onError();
    });
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const areArraysEqual = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((item, index) => {
    return item === arr2[index];
  });
}
