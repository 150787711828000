export class ScrollBar extends Phaser.GameObjects.Container {
  public background: Phaser.GameObjects.Rectangle;
  public bar: Phaser.GameObjects.Rectangle;
  public width: number = 10;

  public scrollY: number = 0;
  public minScrollY: number = 0;
  public maxScrollY: number = 0;

  public startAtTop: boolean = false;

  public onScroll: (y: number) => void;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    height: number,
    width: number = 10,
    startAtTop: boolean = false
  ) {
    super(scene, x, y);
    this.height = height;
    this.startAtTop = startAtTop;
    this.width = width;

    this.background = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      this.width,
      this.height,
      0,
      0.5
    );
    if (this.startAtTop) {
      this.background.setOrigin(0, 0);
    } else {
      this.background.setOrigin(0, 1);
    }

    this.bar = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      this.width,
      this.height,
      0xffffff
    );
    if (this.startAtTop) {
      this.bar.setOrigin(0, 0);
      this.bar.on("drag", (pointer: any, x: number, y: number) => {
        const newy = Math.min(
          Math.max(y, 0),
          this.height - this.bar.displayHeight
        );
        this.bar.setY(newy);
        this.onScroll(newy);
      });
    } else {
      this.bar.setOrigin(0, 1);
      this.bar.on("drag", (pointer: any, x: number, y: number) => {
        this.bar.setY(
          Math.max(Math.min(y, 0), -(this.height - this.bar.displayHeight))
        );
        let ratio = this.height / (this.maxScrollY + this.height);
        this.onScroll(-(this.bar.y / ratio));
      });
    }
    this.bar.setInteractive();
    scene.input.setDraggable(this.bar);

    this.add(this.background);
    this.add(this.bar);

    this.onScroll = (y: number) => {};
  }

  public setScroll(scrollY: number) {
    this.scrollY = scrollY;
    let ratio = this.height / (this.maxScrollY + this.height);
    this.bar.setY(scrollY * ratio);
  }

  public setBounds(minScrollY: number, maxScrollY: number) {
    this.minScrollY = minScrollY;
    this.maxScrollY = maxScrollY;

    let ratio = this.height / (maxScrollY + this.height);

    // uncomment this to make scrollbar only appear when it's scrollable.
    const visible = ratio !== 1
    this.background.setVisible(visible);
    this.bar.setVisible(visible);

    this.bar.setDisplaySize(this.bar.displayWidth, this.height * ratio);
    this.bar.setSize(this.bar.width, this.height * ratio);
  }

  public setOnScroll(onScroll: (y: number) => void) {
    this.onScroll = onScroll;
  }

  public scroll(y: number) {}
}
