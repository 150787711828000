import { getAssets } from "@/api/assets";
import { IAssetState } from "escape-rooms-types/types/game";
import { loadGameSpecificAssets } from "@/utils/phaser-helpers";
import { useGameStore } from "@/store/game-store";
import { PreviewPhaserGame } from "@/components/phaser-game/PreviewGame";
import { Loader } from "phaser";
import { CreatorPhaserGame } from "@/components/phaser-game/CreatorGame";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";

export default class LoadingScene extends Phaser.Scene {
  constructor() {
    super({ key: "loading-scene" });
  }

  async preload() {
    const background = new Phaser.GameObjects.Rectangle(
      this,
      0,
      0,
      10000,
      10000,
      0x000000
    );
    this.add.existing(background).setDepth(-1000);
    this.loadDefaultAssets(this.game.env);

    if (this.game.env === "creator") {
      const store = useGameStore.getState();
      loadGameSpecificAssets(
        this,
        store.game.rooms[store.currentRoomIndex].objects
      );
    }
    if (this.game.env === "game") {
      const allObjects = this.game.session.game.rooms.flatMap(
        (room) => room.objects
      );
      loadGameSpecificAssets(this, allObjects);
    }
    if (this.game.env === "preview") {
      loadGameSpecificAssets(
        this,
        (this.game as PreviewPhaserGame).previewRoomObject.objects
      );
    }

    let container = this.add.container(
      this.renderer.width / 2,
      this.renderer.height / 2
    );
    let progressBar = this.add
      .graphics()
      .fillStyle(0xffffff)
      .lineStyle(1, 0xffffff);
    progressBar.strokeRect(-50, -3, 100, 6);
    container.add(progressBar);

    let progressText = this.add
      .text(
        this.renderer.width / 2,
        this.renderer.height / 2 + 30,
        "Loading: assets...",
        { color: "white" }
      )
      .setOrigin(0.5, 0.5);

    this.load.on("progress", (value: number) => {
      progressBar.clear();
      progressBar.fillStyle(0xffffff).lineStyle(1, 0xffffff);
      progressBar.strokeRect(-50, -3, 100, 6);
      progressBar.fillRect(-50, -3, 100 * value, 6);
    });

    this.load.on("fileprogress", (file: any) => {
      progressText.setText(`Loading: ${file.key}...`);
    });

    this.load.svg("close-modal", "/icons/close-modal.svg");
    this.load.svg("close-sm", "/icons/close-sm.svg");
    this.load.svg("open-inventory", "/icons/open-inventory-btn.svg");
    this.load.svg("close-inventory", "/icons/close-inventory-btn.svg");
    this.load.svg("inventory-tray", "/phaser-assets/inventory-tray.svg");
    this.load.image("modalBackground", "/phaser-assets/modalbg.png");
    this.load.svg("help", "/icons/help.svg");
    this.load.svg(
      "avatar-overlay-finished",
      "/icons/avatar-overlay-finished.svg"
    );
    this.load.image("answer-box-1-default", "/phaser-assets/multiple-choice-question/answer-box-1-default.png");
    this.load.image("answer-box-1-correct", "/phaser-assets/multiple-choice-question/answer-box-1-correct.png");
    this.load.image("answer-box-1-disabled", "/phaser-assets/multiple-choice-question/answer-box-1-disabled.png");
    this.load.image("answer-box-1-hover", "/phaser-assets/multiple-choice-question/answer-box-1-hover.png");
    this.load.image("answer-box-1-incorrect", "/phaser-assets/multiple-choice-question/answer-box-1-incorrect.png");
    this.load.image("answer-box-1-selected", "/phaser-assets/multiple-choice-question/answer-box-1-selected.png");
    this.load.image("answer-box-2-default", "/phaser-assets/multiple-choice-question/answer-box-2-default.png");
    this.load.image("answer-box-2-correct", "/phaser-assets/multiple-choice-question/answer-box-2-correct.png");
    this.load.image("answer-box-2-disabled", "/phaser-assets/multiple-choice-question/answer-box-2-disabled.png");
    this.load.image("answer-box-2-hover", "/phaser-assets/multiple-choice-question/answer-box-2-hover.png");
    this.load.image("answer-box-2-incorrect", "/phaser-assets/multiple-choice-question/answer-box-2-incorrect.png");
    this.load.image("answer-box-2-selected", "/phaser-assets/multiple-choice-question/answer-box-2-selected.png");
    this.load.image("answer-box-3-default", "/phaser-assets/multiple-choice-question/answer-box-3-default.png");
    this.load.image("answer-box-3-correct", "/phaser-assets/multiple-choice-question/answer-box-3-correct.png");
    this.load.image("answer-box-3-disabled", "/phaser-assets/multiple-choice-question/answer-box-3-disabled.png");
    this.load.image("answer-box-3-hover", "/phaser-assets/multiple-choice-question/answer-box-3-hover.png");
    this.load.image("answer-box-3-incorrect", "/phaser-assets/multiple-choice-question/answer-box-3-incorrect.png");
    this.load.image("answer-box-3-selected", "/phaser-assets/multiple-choice-question/answer-box-3-selected.png");
    this.load.svg(
      "wordmatch-char-empty",
      "/phaser-assets/wordmatch-char-empty.svg"
    );
    this.load.svg(
      "wordmatch-char-correct",
      "/phaser-assets/wordmatch-char-correct.svg"
    );
    this.load.svg(
      "wordmatch-char-incorrect",
      "/phaser-assets/wordmatch-char-incorrect.svg"
    );
    this.load.svg("submit", "/buttons/submit.svg");
    this.load.svg("submit-disabled", "/buttons/submit-disabled.svg");
    this.load.svg("back-to-game", "/buttons/back-to-game.svg");
    this.load.svg(
      "inventory-item-bg-filled",
      "/phaser-assets/inventory-item-bg-filled.svg"
    );

    // UIScene
    this.load.image("pwc-logo", "/pwc-logo.png");
    this.load.svg("avatar-cursor", "/phaser-assets/avatar-cursor.svg", {
      scale: 1.3,
    });
    this.load.svg("hint-enabled", "/phaser-assets/hint-enabled.svg");
    this.load.svg("hint-disabled", "/phaser-assets/hint-disabled.svg");
    this.load.svg("hint-button", "/phaser-assets/hint-button.svg", {
      scale: 0.9,
    });
    this.load.svg("xp-sign", "/phaser-assets/xp-sign.svg", { scale: 1.3 });
    this.load.svg("xp-team", "/phaser-assets/xp-team.svg");
    this.load.svg("xp-player", "/phaser-assets/xp-player.svg");

    this.load.svg("xp-mono-good", "/phaser-assets/xp-mono-good.svg");
    this.load.svg(
      "flag-checkered-solid",
      "/phaser-assets/flag-checkered-solid.svg"
    );
    this.load.svg("question-solid", "/phaser-assets/question-solid.svg");
    this.load.svg("stopwatch-solid", "/phaser-assets/stopwatch-solid.svg");
    this.load.svg("trophy-solid", "/phaser-assets/trophy-solid.svg");

    this.load.svg("chevron-circle", "/phaser-assets/chevron-circle.svg", {
      scale: 1.3,
    });
    this.load.svg(
      "roundel-complete",
      "/phaser-assets/message-popup/roundel-complete.svg"
    );
    this.load.image("score-shadow", "/phaser-assets/score-shadow.png");

    this.load.on("complete", () => {
      progressText.setText(
        `Finished loading assets! Preparing the next scene...`
      );
      setTimeout(() => {
        this.goToNextScene();
      }, 1000);
    });
  }

  create() {

  }

  private goToNextScene() {
    this.scene.manager.scenes[1].scene.start();
    this.scene.remove();
  }

  private async loadDefaultAssets(env: string) {
    let assetList = undefined;
    switch (env) {
      case "creator":
        assetList = (this.game as CreatorPhaserGame).assets;
        break;
      case "game":
        assetList = (this.game as PhaserGameClass).assets;
        break;
      case "preview":
        assetList = (this.game as PhaserGameClass).assets;
        break;
    }

    if (assetList == undefined) {
      throw new Error(`No asset list found for this env: ${env}`);
    }

    for (const asset of assetList) {
      for (const state of Object.values(asset.states) as Array<IAssetState>) {
        const sceneUrlSeg = `${state.textureUrl}_scene`.toLowerCase();
        const thumbnailUrlSeg = `${state.textureUrl}_thumbnail`.toLowerCase();
        this.load.svg(sceneUrlSeg, `/asset-state-textures/${sceneUrlSeg}.svg`, {
          scale: 1,
        });
        this.load.svg(
          thumbnailUrlSeg,
          `/asset-state-textures/${thumbnailUrlSeg}.svg`,
          { scale: 1 }
        );
      }
    }
  }
}
