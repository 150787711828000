import React from "react";
import { ListItem } from "@appkit4/react-components";
import styles from "./AssetListItem.module.scss";
import { IAsset } from "escape-rooms-types/types/game";
import SvgComponent from "../svg/SvgComponent";
import { useQuery } from "react-query";
import { getAssetSvg } from "@/api/assets";
import { Loading } from "@appkit4/react-components";

interface AssetListItemProps {
  asset: IAsset;
  onClickHandler: (e: React.MouseEvent) => void;
}

const AssetListItem = ({ asset, onClickHandler }: AssetListItemProps) => {
  const textureUrl = `${Object.values(asset.states)[0].textureUrl}_thumbnail`;

  const { data, isLoading } = useQuery({
    queryKey: ["getAssetSvg", textureUrl],
    queryFn: () => getAssetSvg(textureUrl),
  });

  if (isLoading || data == null) {
    return <></>;
  }

  return (
    <ListItem
      key={asset._id}
      role="option"
      onClick={onClickHandler}
      className={styles.listItem}
      style={{ width: "100%" }}
    >
      <div className={styles.itemContainer}>
        <div className={styles.thumbnailContainer}>
          <SvgComponent svgString={data.data} alt={asset.name} />
        </div>
        <p className={styles.itemName}>{asset.name}</p>
      </div>
    </ListItem>
  );
};

export default AssetListItem;
