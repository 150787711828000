import { useSearchParams } from "react-router-dom";

const Error = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");
  const title = searchParams.get("title");
  const message = searchParams.get("message");
  const suggestion = searchParams.get("suggestion");
  const destination = searchParams.get("destination");

  return (
    <div>
      <h1>Error</h1>
      { status &&
        <h2>{title ? `${status}: ${title}` : `${status}`}</h2>
      }
      <p>{message}</p>
      {suggestion === "login" && (
        <a href={`/login`}>Sign in</a>
      )}
    </div>
  );
};

export default Error;
