import { Select, Switch, TextArea } from "@appkit4/react-components";
import { IAction, IRoomObject } from "escape-rooms-types/types/game";

import { modalTextChoices as textChoices } from "@/constants";

interface IActionDisplayMessageProps {
  action: IAction;
  selected: IRoomObject;
  updateActionProperties: (payload: Partial<IAction>) => void;
}

const ActionDisplayMessage = ({
  action,
  selected,
  updateActionProperties,
}: IActionDisplayMessageProps) => {
  return (
    <>
      <div className="col-4">
        <span className="input-label">What's the message?</span>
        <Select
          placeholder=""
          data={[
            ...textChoices.map((choice) => ({
              label: choice,
              value: choice,
            })),
            { label: "Custom", value: "" },
          ]}
          value={
            textChoices.includes(action?.modalPayload?.message || "")
              ? action?.modalPayload?.message
              : ""
          }
          onSelect={(e) => {
            updateActionProperties({
              modalPayload: {
                ref: action?.modalPayload?.ref || "",
                message: e,
              },
            });
          }}
        />
      </div>
      {(action?.modalPayload?.message === undefined ||
        !textChoices.includes(action.modalPayload.message)) && (
        <div className="col-12 ap-mt-spacing-6">
          <span className="input-label">Type this message here</span>
          <TextArea
            maxLength={500}
            value={action?.modalPayload?.message}
            onChange={(e) => {
              updateActionProperties({
                modalPayload: {
                  ref: Math.random().toString(),
                  message: e,
                },
              });
            }}
          />
        </div>
      )}
      <div className="col-12 ap-mt-spacing-6">
        <Switch
          checked={action.repeatable ?? false}
          onChange={(value) => {
            updateActionProperties({ repeatable: value });
          }}
        >
          <span>Make repeatable (message shows on every click)</span>
        </Switch>
      </div>
    </>
  );
};

export default ActionDisplayMessage;
