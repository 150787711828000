import { useEffect, useState } from "react";
import PreviewGame from "@/components/phaser-game/PreviewGame";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getGame } from "@/api/game";
import { IRoom } from "escape-rooms-types/types/game";
import styles from "./RoomPreview.module.scss";

const RoomPreview = () => {
  const params = useParams();
  const [roomObject, setRoomObject] = useState<IRoom | null>(null);

  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(params.gameId!),
    enabled: false,
    onSuccess: (res) => {
      const roomId = location.pathname.split("/").at(-2);
      setRoomObject(
        res.data.data.rooms.find((room: IRoom) => room._id == roomId)
      );
    },
    onError: (err) => {
      console.error(err);
    },
  });

  useEffect(() => {
    // If game in local storage. Fetch it, else refetch from BE
    const room = localStorage.getItem("roomPreview");

    if (room == null) {
      getGameQuery.refetch();
      return;
    }

    setRoomObject(JSON.parse(room));
    localStorage.removeItem("roomPreview");
  }, []);

  if (!roomObject) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.container}>
      <PreviewGame roomObject={roomObject} />
    </div>
  );
};

export default RoomPreview;
