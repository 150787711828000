import gameConfig from "@/phaser/creator-config";
import { useGameStore } from "@/store/game-store";
import { Slider } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import styles from "./RoomSlider.module.scss";
import EventBridge from "@/utils/EventBridge";
import { IRoom } from "escape-rooms-types/types/game";

interface RoomSliderProps {
  sliderValue: number;
  setSliderValue: React.Dispatch<React.SetStateAction<number>>;
}

const RoomSlider = ({ sliderValue, setSliderValue }: RoomSliderProps) => {
  const rooms = useGameStore((state) => state.game.rooms);
  const currentRoomIndex = useGameStore((state) => state.currentRoomIndex);

  const [oldLength, setOldLength] = useState<number | undefined>(undefined);

  const onSliderMove = () => {
    EventBridge.emit("cameraMove", {
      xScroll:
        (rooms[currentRoomIndex].length - 1) *
        (Number(gameConfig.width) / 100) *
        sliderValue,
    });
  };

  useEffect(() => {
    onSliderMove();
  }, [sliderValue]);

  useEffect(() => {
    if (rooms[currentRoomIndex].length === oldLength) return;

    let newSliderValue = 0;
    if (rooms[currentRoomIndex].length >= 1) {
      if (oldLength) {
        newSliderValue = 100;
      }
    }

    onSliderMove();

    setSliderValue(newSliderValue);
    setOldLength(rooms[currentRoomIndex].length);
  }, [rooms[currentRoomIndex]]);

  if (rooms[currentRoomIndex].length < 2) {
    return <></>;
  }

  return (
    <Slider
      className={`ap-my-spacing-6 ${styles.slider}`}
      onValueChange={(e) => setSliderValue(e)}
      value={sliderValue}
    />
  );
};

export default RoomSlider;
