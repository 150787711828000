import GameScene from "@/phaser/scenes/GameScene";
import EventBridge from "@/utils/EventBridge";
import {
  IAction,
  IModal,
  IQuestionnaire,
  IRoomObjectPropertyUpdate,
  IMediaPayload,
} from "escape-rooms-types/types/game";
import GameRoomObject from "../RoomObject/GameRoomObject";
import BaseAction from "./BaseAction";
import { TextModalPayload, ItemModalPayload } from "../modals/ModalPayloads";
import { getIdOfNextRoom } from "@/utils/helpers";

export default class GameAction extends BaseAction {
  public scene: GameScene;
  public declare sourceObject: GameRoomObject;

  constructor(
    scene: GameScene,
    sourceObject: GameRoomObject,
    score: number,
    id: string,
    ref: string,
    trigger: "onClick" | "afterPrevious" | "onItemUse",
    type:
      | "changeSelf"
      | "changeOther"
      | "displayMessage"
      | "displayMedia"
      | "displayQuestionnaire"
      | "displayChallenge"
      | "addToInventory"
      | "endRoom"
      | "endGame",
    changeObjectPayload?: IRoomObjectPropertyUpdate,
    modalPayload?: IModal,
    questionnaire?: IQuestionnaire,
    triggerItemRef?: string,
    mediaPayload?: IMediaPayload,
    repeatable?: boolean
  ) {
    super(
      scene,
      sourceObject,
      score,
      id,
      ref,
      trigger,
      type,
      changeObjectPayload,
      modalPayload,
      questionnaire,
      triggerItemRef,
      mediaPayload,
      repeatable
    );
    this.scene = scene;
  }

  executeAction() {
    // Actions which emit socket events can only be triggered in this function.
    // They cannot be executed from an executeAction chain (which can be triggered
    // via afterPrevious). Apart from endRoom / endGame.
    switch (this.type) {
      case "changeSelf":
        this.scene.game.socketEmit("updateRoomObject", {
          sessionId: this.scene.game.session._id,
          roomObjectId: this.sourceObject.gameObject._id,
          newProperties: {
            ...this.changeObjectPayload!.newProperties,
            currentAction: this.sourceObject.currentAction + 1,
          },
          executedActionId: `${this.id}-${this.sourceObject.gameObject._id}`,
        });
        break;
      case "changeOther":
        // Update target
        this.scene.game.socketEmit("updateRoomObject", {
          sessionId: this.scene.game.session._id,
          roomObjectId:
            this.scene.roomObjects[this.changeObjectPayload!.targetObjectRef]
              .gameObject._id,
          newProperties: this.changeObjectPayload!.newProperties,
          executedActionId: `${this.id}-${
            this.scene.roomObjects[this.changeObjectPayload!.targetObjectRef]
              .gameObject._id
          }`,
        });
        // Update source object currentAction
        this.scene.game.socketEmit("updateRoomObject", {
          sessionId: this.scene.game.session._id,
          roomObjectId: this.sourceObject._id,
          newProperties: {
            currentAction: this.sourceObject.currentAction + 1,
          },
          executedActionId: `${this.id}-${this.sourceObject.gameObject._id}`,
        });
        break;
      case "addToInventory":
        if (this.modalPayload != null) {
          let addToInventorypayload = {
            text: this.modalPayload.message,
            object: this.scene.roomObjects[this.sourceObject.ref],
            env: "game",
            actionId: this.id,
            executedActionId: `${this.id}`,
          };
          EventBridge.emit("ui.openItemModal", addToInventorypayload);
          break;
        }
        this.scene.game.socketEmit("pickupItem", {
          sessionId: this.scene.game.session._id,
          sourceObject: this.sourceObject.gameObject,
          executedActionId: `${this.id}`,
        });
        break;
      case "displayMessage":
        const textModalPayload = {
          text: this.modalPayload?.message || "",
          sourceObject: this.sourceObject,
          callback: () => {
            if (this.repeatable) {
              return;
            }
            this.sourceObject.completeAction(this.scene.user.id!);
          },
        } satisfies TextModalPayload;
        EventBridge.emit("ui.openMessagePopup", textModalPayload);
        break;
      case "displayMedia":
        EventBridge.emit("ui.openMediaModal", {
          type: this.mediaPayload?.type,
          mediaUrl: this.mediaPayload?.mediaUrl,
          callback: () => {
            this.sourceObject.completeAction(this.scene.user.id!);
          },
          optionalMessage: this.mediaPayload?.message,
          object: this.sourceObject,
          repeatable: this.repeatable,
        });
        break;
      case "displayQuestionnaire":
        let questionnairePayload = {
          sessionId: this.scene.session!.id,
          roomId: this.scene.session!.activeRoomId,
          objectId: this.sourceObject._id,
          actionId: this.id,
        };
        this.scene.game.socketEmit(
          "questionnaireStarted",
          questionnairePayload
        );
        break;
      case "displayChallenge":
        let challengePayload = {
          actionId: this.id,
          questions: this.questionnaire?.questions,
          sourceObject: this.sourceObject,
          continueCallback: () => {
            this.scene.game.socketEmit("updateRoomObject", {
              sessionId: this.scene.game.session._id,
              roomObjectId: this.sourceObject._id,
              newProperties: {
                currentAction: this.sourceObject.currentAction + 1,
              },
            });
          },
          exitCallback: () => {
            this.scene.game.socketEmit("closeChallenge", {
              sessionId: this.scene.session!.id,
              roomObjectRef: this.sourceObject.ref,
              actionId: this.id,
            });
          },
        };
        this.scene.game.socketEmit("openChallenge", {
          sessionId: this.scene.session!.id,
          roomObjectId: this.sourceObject._id,
          roomObjectRef: this.sourceObject.ref,
          actionId: this.id,
        });
        break;
      case "endRoom":
        let nextRoomId = getIdOfNextRoom(
          this.scene.session!.activeRoomId,
          this.scene.session!.game.rooms
        );
        if (nextRoomId == undefined) {
          console.error("No more rooms");
          // Exectue and end game action
          this.scene.endGame();
          return;
        }
        this.scene.game.socketEmit("endRoom", {
          sessionId: this.scene.session!.id,
          activeRoomId: nextRoomId,
          executedActionId: this.id,
        });
        break;
      case "endGame":
        this.scene.game.socketEmit("endSession", {
          sessionId: this.scene.session!.id,
          executedActionId: this.id,
        });
        break;
    }
  }
}
