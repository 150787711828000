interface Props {
  svgString: string;
  alt?: string;
  colors?: string[];
}

const SvgComponent = ({ svgString, colors, alt }: Props) => {
  if (svgString == undefined) {
    return <></>;
  }

  if (colors == null) {
    return (
      <>
        <img
          src={`data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`}
          alt={alt}
        />
      </>
    );
  }

  let newStr = svgString;

  colors.forEach((color, index) => {
    const regexStr = `color-${index + 1}\{fill:#([0-9a-fA-F]{6}|[0-9a-fA-F]{3});}`;
    const regex = new RegExp(regexStr);
    newStr = newStr.replace(
      regex,
      `color-${index + 1}{fill:#${colors[index]};}`
    );
  });

  return (
    <>
      <img
        src={`data:image/svg+xml;utf8,${encodeURIComponent(newStr)}`}
        alt={alt}
      />
    </>
  );
};

export default SvgComponent;
