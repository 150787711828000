import UIScene from "@/phaser/scenes/UIScene";
import { IQuestion } from "escape-rooms-types/types/game";
import IconButton from "../../ui/buttons/IconButton";
import GameScene from "@/phaser/scenes/GameScene";
import QuestionnaireModal from "../questionnaire/QuestionnaireModal";
import ChallengeModal from "../challenge/ChallengeModal";
import TextButton from "../../ui/buttons/TextButton";

const titleBackgroundMaxWidth = 1089;

export default class WordMatchQuestion extends Phaser.GameObjects.Group {
  scene: UIScene;
  parentModal: QuestionnaireModal | ChallengeModal;
  questionnaire?: QuestionnaireModal | null;
  challenge?: ChallengeModal | null;
  question: IQuestion;
  title: string;
  answer: string;
  currentInput: string;
  questionContainer: Phaser.GameObjects.Container;
  questionText: Phaser.GameObjects.Text;
  background: Phaser.GameObjects.Graphics;
  submitButtonDisabled: TextButton;
  submitButtonEnabled: TextButton;
  waitingForOtherPlayersMessage: Phaser.GameObjects.Container;
  incorrectAnswerMessage: Phaser.GameObjects.Container;
  inputDisabled: boolean;
  markAllAsFinished: () => void;
  waitingForOtherPlayers: boolean;

  charactersContainer: Phaser.GameObjects.Container;
  charactersRectanglesEmpty: Phaser.GameObjects.Group;
  charactersRectanglesCorrect: Phaser.GameObjects.Group;
  charactersRectanglesIncorrect: Phaser.GameObjects.Group;
  charactersText: Array<Phaser.GameObjects.Text>;

  isIndividualChallenge: boolean;
  assetImage?: Phaser.GameObjects.Image;

  constructor(
    scene: UIScene,
    parentModal: QuestionnaireModal | ChallengeModal,
    question: IQuestion,
    markAllAsFinished: () => void,
    assetTexture?: string
  ) {
    super(scene);
    this.parentModal = parentModal;
    this.inputDisabled = false;
    this.waitingForOtherPlayers = false;

    if (parentModal instanceof QuestionnaireModal) {
      this.questionnaire = parentModal;
      this.isIndividualChallenge = false;
    } else {
      this.challenge = parentModal;
      this.isIndividualChallenge = true;
    }

    this.scene = scene;
    this.question = question;
    this.markAllAsFinished = markAllAsFinished;
    this.title = question.title!;
    // to remove unwanted spaces.
    this.answer = question.answer!.trim();
    this.currentInput = "";

    if (assetTexture && this.isIndividualChallenge) {
      this.assetImage = new Phaser.GameObjects.Image(
        scene,
        scene.renderer.width / 2,
        150,
        assetTexture
      ).setOrigin(0.5, 0);
      const assetImageRatio = this.assetImage.width / this.assetImage.height;
      this.assetImage.setDisplaySize(200 * assetImageRatio, 200);
      this.add(this.assetImage, true);
    }

    this.questionContainer = new Phaser.GameObjects.Container(
      scene,
      this.scene.renderer.width / 2,
      (this.isIndividualChallenge && this.assetImage
        ? this.assetImage?.getBottomCenter().y + 20
        : 250) + 40
    );

    this.questionText = new Phaser.GameObjects.Text(scene, 0, 0, this.title, {
      fontFamily: "Roboto-Regular",
      fontSize: "20px",
      color: "#000000",
      wordWrap: { width: titleBackgroundMaxWidth - 30, useAdvancedWrap: true },
    })
      .setLineSpacing(8)
      .setOrigin(0.5, 0);

    this.background = new Phaser.GameObjects.Graphics(scene)
      .fillStyle(0xffffff, 1)
      .fillRoundedRect(
        this.questionText.getTopLeft().x - 20,
        this.questionText.getTopLeft().y - 20,
        this.questionText.width + 40,
        this.questionText.height + 40,
        10
      );

    this.questionContainer.add(this.background);
    this.questionContainer.add(this.questionText);
    scene.add.existing(this.questionContainer);
    this.add(this.questionContainer);

    this.charactersContainer = new Phaser.GameObjects.Container(
      scene,
      scene.renderer.width / 2 + 4,
      this.questionContainer.y + this.questionText.getBottomCenter().y + 60
    );
    this.charactersText = [];
    this.charactersRectanglesEmpty = new Phaser.GameObjects.Group(scene);
    this.charactersRectanglesCorrect = new Phaser.GameObjects.Group(scene);
    this.charactersRectanglesIncorrect = new Phaser.GameObjects.Group(scene);

    for (let i = 0; i < this.answer.length; i++) {
      if (this.answer[i] === " ") {
        continue;
      }

      const [x, y] = this.getCharacterXY(i, this.answer.length);

      let rectangleEmpty = new Phaser.GameObjects.Image(
        scene,
        x,
        y,
        "wordmatch-char-empty"
      )
        .setOrigin(0, 0)
        .setVisible(true);

      let rectangleCorrect = new Phaser.GameObjects.Image(
        scene,
        x,
        y,
        "wordmatch-char-correct"
      )
        .setOrigin(0, 0)
        .setVisible(false);

      let rectangleIncorrect = new Phaser.GameObjects.Image(
        scene,
        x,
        y,
        "wordmatch-char-incorrect"
      )
        .setOrigin(0, 0)
        .setVisible(false);

      this.charactersRectanglesEmpty.add(rectangleEmpty);
      this.charactersRectanglesCorrect.add(rectangleCorrect);
      this.charactersRectanglesIncorrect.add(rectangleIncorrect);
      this.charactersContainer.add([
        rectangleEmpty,
        rectangleCorrect,
        rectangleIncorrect,
      ]);

      let text = new Phaser.GameObjects.Text(scene, x + 25.5, y + 44, "", {
        fontFamily: "Roboto-Medium",
        color: "#000000",
        fontSize: "38px",
      }).setOrigin(0.5, 0.5);

      this.charactersContainer.add(text);
      this.charactersText.push(text);
    }

    scene.add.existing(this.charactersContainer);
    this.add(this.charactersContainer);

    this.submitButtonDisabled = new TextButton(
      scene,
      scene.renderer.width / 2,
      this.getSubmitButtonY(),
      "SUBMIT",
      "disabled",
      () => {}
    );

    this.submitButtonEnabled = new TextButton(
      scene,
      scene.renderer.width / 2,
      this.getSubmitButtonY(),
      "SUBMIT",
      "tertiary",
      () => this.submitAnswer(this)
    ).setVisible(false);
    scene.add.existing(this.submitButtonDisabled);
    scene.add.existing(this.submitButtonEnabled);
    this.add(this.submitButtonDisabled);
    this.add(this.submitButtonEnabled);

    this.waitingForOtherPlayersMessage = new Phaser.GameObjects.Container(
      scene,
      scene.renderer.width / 2,
      this.getSubmitButtonY() - 20
    );
    this.waitingForOtherPlayersMessage
      .add(
        this.isIndividualChallenge
          ? [
              new Phaser.GameObjects.Text(scene, 0, 0, "CORRECT!", {
                fontFamily: "Nunito-Bold",
                fontSize: "35px",
              }).setOrigin(0.5, 0),
            ]
          : [
              new Phaser.GameObjects.Text(scene, 0, 0, "CORRECT!", {
                fontFamily: "Nunito-Bold",
                fontSize: "35px",
              }).setOrigin(0.5, 0.5),
              new Phaser.GameObjects.Text(
                scene,
                0,
                40,
                "Waiting for all players to complete",
                { fontFamily: "Nunito-Bold", fontSize: "19px" }
              ).setOrigin(0.5, 0.5),
            ]
      )
      .setVisible(false);
    scene.add.existing(this.waitingForOtherPlayersMessage);
    this.add(this.waitingForOtherPlayersMessage);

    this.incorrectAnswerMessage = new Phaser.GameObjects.Container(
      scene,
      scene.renderer.width / 2,
      this.getSubmitButtonY() - 20
    );
    this.incorrectAnswerMessage
      .add([
        new Phaser.GameObjects.Text(scene, 0, 0, "Incorrect answer", {
          fontFamily: "Nunito-Bold",
          fontSize: "25px",
        }).setOrigin(0.5, 0),
        new Phaser.GameObjects.Text(scene, 0, 50, "Please try again", {
          fontFamily: "Roboto-Medium",
          fontSize: "19px",
        }).setOrigin(0.5, 0.5),
      ])
      .setVisible(false);
    scene.add.existing(this.incorrectAnswerMessage);
    this.add(this.incorrectAnswerMessage);

    this.enableSubmitButton();

    this.scene.input.keyboard.on("keydown", (e: KeyboardEvent) =>
      this.handleInput(e)
    );
  }

  enableSubmitButton() {
    this.submitButtonDisabled.setVisible(false);
    this.submitButtonEnabled.setVisible(true);
  }

  disableSubmitButton() {
    this.submitButtonDisabled.setVisible(true);
    this.submitButtonEnabled.setVisible(false);
  }

  hideSubmitButton() {
    this.submitButtonDisabled.setVisible(false);
    this.submitButtonEnabled.setVisible(false);
  }

  getSubmitButtonY() {
    const isAnswer2Rows = this.answer.length > 20;
    const topOfQuestionContainer = this.charactersContainer.y;
    const bottomOfQuestionContainer =
      topOfQuestionContainer + 100 * (isAnswer2Rows ? 2 : 1) + 50;
    return bottomOfQuestionContainer;
  }

  handleInput(e: KeyboardEvent) {
    if (this.inputDisabled) {
      return;
    }
    if (this.waitingForOtherPlayersMessage.visible) return;
    if (e.code === "Space") {
      return;
    }
    if (e.code === "Enter") {
      this.submitAnswer(this);
      return;
    }
    if (e.code === "Backspace") {
      this.updateInput(this.currentInput.slice(0, -1));
      return;
    }
    if (e.key.length === 1) {
      this.updateInput(this.currentInput + String(e.key).toUpperCase());
    }
  }

  updateInput(str: string) {
    const strippedAnswer = this.answer.replace(/\s+/g, "");
    this.currentInput = str.slice(0, strippedAnswer.length);
    for (
      let characterIndex = 0;
      characterIndex < strippedAnswer.length;
      characterIndex++
    ) {
      if (characterIndex > this.currentInput.length) {
        this.charactersText[characterIndex].setText("");
        continue;
      }
      this.charactersText[characterIndex].setText(
        this.currentInput[characterIndex]
      );
    }
    this.changeCharactersRectanglesColor("empty");
    this.changeCharactersTextColor("black");
    this.enableSubmitButton();
  }

  submitAnswer(question: WordMatchQuestion) {
    const strippedCurrentInput = this.currentInput
      .toUpperCase()
      .replace(/\s+/g, "");
    const strippedAnswer = this.answer.toUpperCase().replace(/\s+/g, "");
    const isCorrectAnswer = strippedCurrentInput === strippedAnswer;

    if (this.isIndividualChallenge && isCorrectAnswer) {
      question.hideSubmitButton();
      this.validateSubmitedAnswer(true);
      this.parentModal.completeChallenge();
      return;
    }
    if (this.isIndividualChallenge && !isCorrectAnswer) {
      this.handleWrongAnswer(this);
      return;
    }

    if (this.scene.env === "preview" && isCorrectAnswer) {
      question.hideSubmitButton();
      question.waitingForOtherPlayersMessage.setVisible(true);
      this.validateSubmitedAnswer(true);
      setTimeout(() => {
        question.markAllAsFinished();
      }, 1500);
      return;
    }
    if (this.scene.env === "preview" && !isCorrectAnswer) {
      this.handleWrongAnswer(this);
      return;
    }
    if (this.scene.env === "game" && isCorrectAnswer) {
      const sourceScene = this.scene.sourceScene as GameScene;
      sourceScene.game.socketEmit("markUserAsDone", {
        sessionId: sourceScene.session!.id,
        questionId: this.question._id,
        roomId: sourceScene.session!.activeRoomId,
        roomObjectId: this.questionnaire!.sourceObject._id,
        actionId: this.questionnaire!.actionId,
      });
      sourceScene.game.socket.on("markUserAsDone", (payload: any) => {
        if (
          payload.sourcePlayerId === (this.scene.sourceScene as GameScene).user!.id
        ) {
          question.hideSubmitButton();
          question.waitingForOtherPlayersMessage.setVisible(true);
          this.validateSubmitedAnswer(true);
          // TODO: this is not being hit!
          this.waitingForOtherPlayers = true;
        }
      });
      question.hideSubmitButton();
      question.disableInput();
      setTimeout(() => {
        if (this.waitingForOtherPlayers) {
          return
        }
        question.enableSubmitButton();
        question.enableInput();
      }, 5000);
      return;
    }
    if (this.scene.env === "game" && !isCorrectAnswer) {
      this.handleWrongAnswer(this);
      const sourceScene = this.scene.sourceScene as GameScene;
      sourceScene.game.socketEmit("incorrectPlayerAnswer", {
        sessionId: sourceScene.session!.id,
        playerId: sourceScene.user!.id,
        answer: this.currentInput,
      });
      return;
    }
  }

  handleWrongAnswer(question: WordMatchQuestion) {
    this.validateSubmitedAnswer(false);
    question.hideSubmitButton();
    this.parentModal.incremenentAttemptsTaken();
    question.incorrectAnswerMessage.setVisible(true);
    question.disableInput();

    setTimeout(() => {
      question.incorrectAnswerMessage.setVisible(false);
      question.disableSubmitButton();
      question.updateInput("");
      question.enableInput();
    }, 2000);
  }

  validateSubmitedAnswer(valid: boolean) {
    this.changeCharactersRectanglesColor(valid ? "correct" : "incorrect");
    this.changeCharactersTextColor(valid ? "black" : "white");
  }

  changeCharactersRectanglesColor(
    rectangleStatus: "empty" | "correct" | "incorrect"
  ) {
    switch (rectangleStatus) {
      case "empty":
        this.charactersRectanglesEmpty.setVisible(true);
        this.charactersRectanglesCorrect.setVisible(false);
        this.charactersRectanglesIncorrect.setVisible(false);
        break;
      case "correct":
        this.charactersRectanglesEmpty.setVisible(false);
        this.charactersRectanglesCorrect.setVisible(true);
        this.charactersRectanglesIncorrect.setVisible(false);
        break;
      case "incorrect":
        this.charactersRectanglesEmpty.setVisible(false);
        this.charactersRectanglesCorrect.setVisible(false);
        this.charactersRectanglesIncorrect.setVisible(true);
        break;
    }
  }

  changeCharactersTextColor(color: string) {
    this.charactersText.forEach((character) => character.setColor(color));
  }

  disableInput() {
    this.inputDisabled = true;
  }

  enableInput() {
    this.inputDisabled = false;
  }

  destroy(
    destroyChildren?: boolean | undefined,
    removeFromScene?: boolean | undefined
  ): void {
    this.assetImage?.destroy(true);
    this.questionContainer.destroy(true);
    this.charactersContainer.destroy(true);
    this.submitButtonDisabled.destroy(true);
    this.submitButtonEnabled.destroy(true);
    this.waitingForOtherPlayersMessage.destroy(true);
    this.scene.input.keyboard.removeListener("keydown");
  }

  getCharacterXY = (index: number, answerLength: number) => {
    const rowNumber = Math.floor(index / 20);
    const currentRow = index < 20 ? 0 : 1;
    const numberOfCharsInRow =
      answerLength < 20
        ? answerLength
        : currentRow === 1
        ? answerLength - 20
        : 20;
    const x = (index % 20) * 65 - (numberOfCharsInRow * 65) / 2;
    const y = rowNumber * 100;
    return [x, y];
  };
}
