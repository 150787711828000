import UIScene from "@/phaser/scenes/UIScene";
import QuestionnairePlayer from "./QuestionnairePlayer";

export default class PlayerAvatarContainer extends Phaser.GameObjects.Container {
  players;

  constructor(
    scene: UIScene,
    x: number,
    y: number,
    players: QuestionnairePlayer[]
  ) {
    super(scene, x, y);
    this.players = players;

    // instantiate avatars
    this.players.forEach((player) => this.add(player.avatar));
  }
}