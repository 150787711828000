import { create } from "zustand";
import { produce } from "immer";
import { devtools } from "zustand/middleware";
import {
  IGame,
  IRoom,
  IAction,
  IRoomObject,
  IRoomObjectPropertyUpdate,
  IAsset,
} from "escape-rooms-types/types/game";
import { IRoomEditorObject, TObjectOrderDirection } from "@/types/game";
import EventBridge from "@/utils/EventBridge";
import { Directions } from "@/constants";
import { v4 as uuidv4 } from "uuid";
import RoomObject from "@/phaser/classes/RoomObject/CreatorRoomObject";
import { IGameSession } from "escape-rooms-types/types/gameSession";

interface ReactGameSlice {
  session?: IGameSession;
  game: IGame;
  currentRoomIndex: number;
  selectedObjectIndex?: number;
  instanceCounter: number;
  currentXPos: number;
  setGame: (game: IGame) => void;
  setSession: (session?: IGameSession) => void;
  setGameField: (args: any) => void;
  setRooms: (rooms: Array<IRoom>) => void;
  setCurrentRoomIndex: (roomId: string) => void;
  setRoomFields: ({}: SetRoomFieldsArgs) => void;
  setGameColor: ({ index, color }: SetColorArgs) => void;
  addObjectToRoom: (object: IRoomEditorObject) => void;
  removeObjectFromRoom: (object: IRoomEditorObject) => void;
  setSelectedObjectIndex: (index: number) => void;
  setSelectedObjectByRef: (objectRef: string) => void;
  updateObject: (object: IRoomEditorObject) => void;
  updateObjectColor: ({
    index,
    colorHexValue,
  }: {
    index: number;
    colorHexValue: string;
  }) => void;
  updateObjectPosition: (objectRef: string, xPos: number, yPos: number) => void;
  updatePreviewObject: (object: IRoomEditorObject) => void;
  toggleObjectLock: (object: IRoomEditorObject) => void;
  changeObjectOrder: (
    object: IRoomEditorObject,
    direction: TObjectOrderDirection
  ) => void;
  unselectObject: () => void;
  updateActionChangeObjectPayload: (
    objectRef: string,
    actionRef: string,
    payload: Partial<IRoomObjectPropertyUpdate>
  ) => void;
  setCurrentXPosition: (xPos: number) => void;
  clearStore: () => void;
  previewRoom?: IRoom;
  setPreviewRoomByRoomId: (roomId: string) => void;
  setPreviewRoomByRoomIndex: (roomIndex: number) => void;
  getPreviewObjectById: (id: string) => IRoomObject | undefined;
  addObjectToItemList: (objectRef: string) => void;
  removeObjectFromItemList: (objectRef: string) => void;
  assets: IAsset[];
  setAssets: (assets: IAsset[]) => void;
}

interface SetRoomFieldsArgs {
  roomIndex: number;
  length?: number;
  isActive?: boolean;
  name?: string;
}

interface SetColorArgs {
  index: number;
  color: string;
}

const initialGame: IGame = {
  _id: "_initialGame",
  ref: uuidv4(),
  name: "Game Prototype",
  colorPalette: ["", ""],
  status: "draft",
  industry: "",
  client: "",
  rooms: [
    {
      _id: "_testRoom",
      length: 3,
      name: "First Room",
      objects: [],
      order: 0,
      isActive: true,
      ref: uuidv4(),
      items: [],
    },
  ],
};

const generateEmptyStore = () => {
  return {
    instanceCounter: 0,
    currentRoomIndex: 0,
    currentRoom: undefined,
    selectedObject: undefined,
    selectedObjectIndex: undefined,
    previewRoom: undefined,
    assets: [],
  };
};

// TODO: Get the store working with immer
export const useGameStore = create<ReactGameSlice>()(
  devtools(
    (set, get): ReactGameSlice => ({
      game: initialGame,
      currentXPos: 0,
      ...generateEmptyStore(),
      setAssets: (assets) => {
        set(
          produce((draftState) => {
            draftState.assets = assets;
          })
        );
      },
      setGame: (game) => {
        set(
          produce((draftState) => {
            // Clean up game object to match IGame
            const tidiedGame = {
              ...game,
              // createdAt: undefined,
              // updatedAt: undefined,
              // __v: undefined
            };
            draftState.game = tidiedGame;
          })
        );
      },
      setSession: (session) => {
        set(
          produce((draftState) => {
            // Clean up game object to match IGame
            const tidiedSession = {
              ...session,
              // createdAt: undefined,
              // updatedAt: undefined,
              // __v: undefined
            };
            draftState.session = tidiedSession;
          })
        );
      },
      setGameField: (args) => {
        set(
          produce((draftState: ReactGameSlice) => {
            if (args.name != null) {
              draftState.game.name = args.name;
            }
            if (args.industry != null) {
              draftState.game.industry = args.industry;
            }
            if (args.status != null) {
              draftState.game.status = args.status;
            }
          })
        );
      },
      setRooms: (rooms: Array<IRoom>) => {
        set(
          produce((draftState: ReactGameSlice) => {
            draftState.game.rooms = rooms;
          })
        );
      },
      setCurrentRoomIndex: (roomId: string) => {
        set(
          produce((draftState: ReactGameSlice) => {
            let roomIndex = draftState.game.rooms.findIndex(
              (room) => room._id === roomId
            );
            // Fix bug where room of length 1 returns undefined
            if (draftState.game.rooms.length === 1) {
              roomIndex = 0;
            }
            draftState.currentRoomIndex = roomIndex;
          })
        );
      },
      setRoomFields: ({ roomIndex, length, isActive, name }) => {
        set(
          produce((draft: ReactGameSlice) => {
            if (length != null) {
              draft.game.rooms[roomIndex].length = length;
            }
            if (isActive != null) {
              draft.game.rooms[roomIndex].isActive = isActive;
            }
            if (name != null) {
              draft.game.rooms[roomIndex].name = name;
            }
          })
        );
      },
      setGameColor: ({ index, color }: SetColorArgs) => {
        set(
          produce((draft: ReactGameSlice) => {
            draft.game.colorPalette[index] = color;
          })
        );
      },
      addObjectToRoom: (object: IRoomEditorObject) => {
        set(
          produce((draftState: ReactGameSlice) => {
            object.xPos = draftState.currentXPos + 50;
            const instances = draftState.game.rooms[
              draftState.currentRoomIndex
            ].objects.filter(
              (instance) => instance.asset.name === object.asset.name
            );
            if (instances.length > 0) {
              let counter = 0;
              instances.forEach((instance) => {
                let instanceDigits = instance.name.match(/\d+/);
                if (instanceDigits) {
                  counter = Math.max(counter, Number(instanceDigits[0]));
                }
              });

              object.name = `${object.name}_${counter + 1}`;
            }

            draftState.game.rooms[draftState.currentRoomIndex].objects.push(
              object
            );
            EventBridge.emit("creator.addObjectToRoom", object);
          })
        );
      },
      removeObjectFromRoom: (object: IRoomEditorObject) => {
        set(
          produce((draftState: ReactGameSlice) => {
            draftState.game.rooms[draftState.currentRoomIndex].objects =
              draftState.game.rooms[draftState.currentRoomIndex].objects.filter(
                (obj) => obj.ref != object.ref
              );
            draftState.game.rooms[draftState.currentRoomIndex].items =
              draftState.game.rooms[draftState.currentRoomIndex].items.filter(
                (item) => item !== object.ref
              );
            EventBridge.emit("creator.removeObjectFromRoom", object);
          })
        );
      },
      updateObject: (object: IRoomEditorObject) => {
        set(
          produce((draftState: ReactGameSlice) => {
            if (draftState.selectedObjectIndex == null) {
              console.error("No object selected.");
              return;
            }
            draftState.game.rooms[draftState.currentRoomIndex].objects[
              draftState.selectedObjectIndex
            ] = object;
          })
        );
        EventBridge.emit("creator.updateObject", object);
      },
      updateObjectColor: ({
        index,
        colorHexValue,
      }: {
        index: number;
        colorHexValue: string;
      }) => {
        set(
          produce((draftState: ReactGameSlice) => {
            if (draftState.selectedObjectIndex == null) {
              console.error("No object selected.");
              return;
            }
            const object =
              draftState.game.rooms[draftState.currentRoomIndex].objects[
                draftState.selectedObjectIndex
              ];

            draftState.game.rooms[draftState.currentRoomIndex].objects[
              draftState.selectedObjectIndex
            ].colorPalette[index] = colorHexValue;

            EventBridge.emit(`creator.object.color.update.${object.ref}`, {
              index,
              colorHexValue,
            });
          })
        );
      },
      updateObjectPosition: (objectRef: string, xPos: number, yPos: number) => {
        set(
          produce((draftState) => {
            const index = draftState.game.rooms[
              draftState.currentRoomIndex
            ].objects.findIndex(
              (object: RoomObject) => object.ref === objectRef
            );

            draftState.game.rooms[draftState.currentRoomIndex].objects[
              index
            ].xPos = xPos;
            draftState.game.rooms[draftState.currentRoomIndex].objects[
              index
            ].yPos = yPos;
          })
        );
      },
      updatePreviewObject: (object: IRoomEditorObject) => {
        set(
          produce((draftState: ReactGameSlice) => {
            const index = draftState.previewRoom?.objects.findIndex(
              (obj) => object.ref === obj.ref
            );
            if (draftState.previewRoom !== undefined && index !== undefined) {
              draftState.previewRoom.objects[index] = object;
            }
          })
        );
        EventBridge.emit("creator.updateObject", object);
      },
      toggleObjectLock: (object: IRoomEditorObject) => {
        set(
          produce((draftState) => {
            const currentRoomIndex = draftState.currentRoomIndex;
            const selectedObjectIndex = draftState.selectedObjectIndex;
            const selectedObject =
              draftState.game.rooms[currentRoomIndex].objects[
                selectedObjectIndex
              ];
            selectedObject.isDisabled = !selectedObject.isDisabled;
          })
        );
        EventBridge.emit("creator.toggleObjectLock", object);
      },
      changeObjectOrder: (
        object: IRoomEditorObject,
        direction: TObjectOrderDirection
      ) => {
        set(
          produce((draftState: ReactGameSlice) => {
            const currentIndex = draftState.game.rooms[
              draftState.currentRoomIndex
            ].objects.findIndex((obj) => obj.ref === object.ref);
            const maxIndex =
              draftState.game.rooms[draftState.currentRoomIndex].objects
                .length - 1;
            let destinationIndex = currentIndex;
            switch (direction) {
              case Directions.BACKWARD:
                if (currentIndex === 0) {
                  return;
                }
                destinationIndex = currentIndex - 1;
                break;
              case Directions.FORWARD:
                if (destinationIndex + 1 > maxIndex) {
                  return;
                }
                destinationIndex = currentIndex + 1;
                break;
              case Directions.FRONT:
                destinationIndex =
                  draftState.game.rooms[draftState.currentRoomIndex].objects
                    .length - 1;
                break;
              case Directions.BACK:
                destinationIndex = 0;
                break;
            }
            draftState.game.rooms[draftState.currentRoomIndex].objects.splice(
              destinationIndex,
              0,
              draftState.game.rooms[draftState.currentRoomIndex].objects.splice(
                currentIndex,
                1
              )[0]
            );
            draftState.selectedObjectIndex = destinationIndex;
            EventBridge.emit("creator.changeObjectOrder", {
              object,
              direction,
            });
          })
        );
      },
      setSelectedObjectByRef: (objectRef: string) => {
        set(
          produce((draftState: ReactGameSlice) => {
            const objectIndex = draftState.game.rooms[
              draftState.currentRoomIndex
            ].objects.findIndex((o) => o.ref === objectRef);
            draftState.selectedObjectIndex = objectIndex;
            objectRef =
              draftState.game.rooms[draftState.currentRoomIndex].objects[
                objectIndex
              ].ref;
          })
        );
        EventBridge.emit("creator.selectObject", objectRef);
      },
      setSelectedObjectIndex: (objectIndex: number) => {
        if (objectIndex == null) {
          return;
        }
        let objectRef;
        set(
          produce((draftState: ReactGameSlice) => {
            draftState.selectedObjectIndex = objectIndex;
            objectRef =
              draftState.game.rooms[draftState.currentRoomIndex].objects[
                objectIndex
              ].ref;
          })
        );
        EventBridge.emit("creator.selectObject", objectRef);
      },
      unselectObject: () => {
        set(
          produce((draftState) => {
            draftState.selectedObjectIndex = undefined;
          })
        );
      },
      setCurrentXPosition: (xPos: number) => {
        set(
          produce((draftState) => {
            draftState.currentXPos = xPos;
          })
        );
      },
      updateActionChangeObjectPayload: (
        objectRef: string,
        actionRef: string,
        payload: any
      ) => {
        set(
          produce((draftState) => {
            const currentRoomIndex = draftState.currentRoomIndex;
            const selectedObjectIndex = draftState.selectedObjectIndex;
            let selectedObject =
              draftState.game.rooms[currentRoomIndex].objects[
                selectedObjectIndex
              ];
            const actionIndex = selectedObject.actions.findIndex(
              (action: IAction) => action.ref == actionRef
            );
            const action = selectedObject.actions[actionIndex];
            const changeObjectPayload = action.changeObjectPayload
              ? action.changeObjectPayload
              : {
                  ref: undefined,
                  targetObjectRef: undefined,
                  newProperties: {
                    isVisible: undefined,
                    isDisabled: undefined,
                    currentState: undefined,
                  },
                };

            if (payload.targetObjectRef != null) {
              changeObjectPayload.targetObjectRef = payload.targetObjectRef;
            }
            if (payload.newProperties.isVisible != null) {
              changeObjectPayload.newProperties.isVisible =
                payload.newProperties.isVisible;
            }
            if (payload.newProperties.isDisabled != null) {
              changeObjectPayload.newProperties.isDisabled =
                payload.newProperties.isDisabled;
            }
            if (payload.newProperties.currentState != null) {
              changeObjectPayload.newProperties.currentState =
                payload.newProperties.currentState;
            }

            action.changeObjectPayload = changeObjectPayload;

            const newObject = { ...selectedObject };
            selectedObject = newObject;
          })
        );
      },
      setPreviewRoomByRoomId: (roomId: string) => {
        set(
          produce((draftState: ReactGameSlice) => {
            const roomIndex = draftState.game.rooms.findIndex(
              (room) => room._id === roomId
            );
            draftState.previewRoom = { ...draftState.game.rooms[roomIndex] };
          })
        );
      },
      setPreviewRoomByRoomIndex: (roomIndex: number) => {
        set(
          produce((draftState: ReactGameSlice) => {
            draftState.previewRoom = { ...draftState.game.rooms[roomIndex] };
          })
        );
      },
      getPreviewObjectById: (id: string) => {
        return get().previewRoom?.objects.find((object) => object.ref === id);
      },
      addObjectToItemList: (objectRef) => {
        set(
          produce((draftState: ReactGameSlice) => {
            if (
              draftState.game.rooms[
                draftState.currentRoomIndex
              ].items.findIndex((object) => object === objectRef) === -1
            ) {
              draftState.game.rooms[draftState.currentRoomIndex].items.push(
                objectRef
              );
            }
          })
        );
      },
      removeObjectFromItemList: (objectRef) => {
        set(
          produce((draftState: ReactGameSlice) => {
            draftState.game.rooms[draftState.currentRoomIndex].items =
              draftState.game.rooms[draftState.currentRoomIndex].items.filter(
                (itemRef) => itemRef != objectRef
              );
          })
        );
      },
      clearStore: () => {
        set(
          produce((draftState: ReactGameSlice) => {
            const emptyStore = generateEmptyStore();
            draftState.instanceCounter = emptyStore.instanceCounter;
            draftState.currentRoomIndex = emptyStore.currentRoomIndex;
            draftState.selectedObjectIndex = undefined;
          })
        );
        EventBridge.emit("creator.destory");
      },
    })
  )
);
