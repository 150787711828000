import EventBridge from "@/utils/EventBridge";
import UIScene from "./UIScene";
import BaseScene from "./BaseScene";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";
import GameRoomObject from "../classes/RoomObject/GameRoomObject";
import { IAsset, IRoomObject } from "escape-rooms-types/types/game";
import { loadGameSpecificAssets } from "@/utils/phaser-helpers";
import Cursor from "../classes/ui/Cursor";
import Session from "../classes/session/Session";
import { IUser } from "@/types/game";
import { getGameSession } from "@/api/session";
import GameCompleteScreen from "../classes/modals/screens/GameCompleteScreen";
import { IGameSession } from "escape-rooms-types/types/gameSession";

export default class GameScene extends BaseScene {
  public declare game: PhaserGameClass;
  public uiScene?: UIScene;
  public roomObjects: { [key: string]: GameRoomObject };
  public targetRoomObject?: GameRoomObject;
  public itemBeingUsed?: GameRoomObject;
  private userItemClickOnCooldown: boolean;
  public activeRoomIndex: number;
  public session?: Session;
  public user?: IUser;
  public clientId?: string;
  public cursors: Map<string, Cursor>;
  private cursorCooldown: number = 0;
  private lastCursorPosition: Phaser.Math.Vector2 = new Phaser.Math.Vector2(
    -500,
    -500
  );
  private gameOver: boolean;
  private gameOverScreenShown: boolean = false;
  private refetchSessionTimer?: Phaser.Time.TimerEvent;
  private updateLockedObjectsTimer?: Phaser.Time.TimerEvent;
  public blackScreen?: Phaser.GameObjects.Rectangle;

  public totalActions: number = 0;
  public actionsDone: number = 0;

  constructor() {
    super("game-scene");
    this.roomObjects = {};
    this.userItemClickOnCooldown = false;
    this.cursors = new Map([]);
    this.activeRoomIndex = 0;
    this.gameOver = false;
    this.endRoom = this.endRoom.bind(this);
  }

  async preload() {
    // Request and load session
    this.blackScreen = this.add.rectangle(0, 0, 10000, 10000, 0x000000);
    this.blackScreen.depth = 1000;

    this.activeRoomIndex = this.game.session.game.rooms.findIndex(
      (room) => room._id === this.game.session.activeRoomId
    );
  }

  async create() {
    this.user = this.game.user;
    const res = await getGameSession(this.game.session._id);
    this.session = new Session(this, res.data.data);

    this.disableInput();

    const userCanJoinSession = this.game.session.players
      .map((p) => p.playerId)
      .includes(this.user.id);

    if (!userCanJoinSession) {
      const message = new Phaser.GameObjects.Text(
        this,
        this.renderer.width / 2,
        this.renderer.height / 2,
        "You must be invited to join a game session.",
        {
          fontFamily: "Roboto-Regular",
          fontSize: "20px",
          color: "#FFFFFF",
          wordWrap: { width: 1000 },
          align: "center",
        }
      )
        .setOrigin(0.5, 0.5)
        .setLineSpacing(8);
      message.depth = 1001;
      this.add.existing(message);
      return;
    }

    this.gameOver = this.session.gameCompleted != null;
    this.activeRoomIndex = this.session.game.rooms.findIndex(
      (room) => room._id === this.session!.activeRoomId
    );
    this.loadRoom();
    this.uiScene = this.loadUI();
    this.session.addUiScene(this.uiScene);
    this.setLockedObjects(this.session.lockedObjects, true);
    this.tweens.add({
      targets: [this.blackScreen],
      alpha: 0,
      duration: 1500,
    });

    setTimeout(() => {
      this.scene.setVisible(true, "ui-scene");
      this.enableInput();
      if (this.session?.activeQuestionnaire != null) {
        this.launchActiveQuestionnaire();
      }
    }, 1000);

    // Connect to session
    this.game.socketEmit("joinSession", {
      sessionId: this.game.session._id,
      playerId: this.user.id,
    });

    this.input.on("pointerdown", () => {
      // Hack to fix stupid race condition. Basically this get executed before we can actually
      // set useItem to true
      setTimeout(() => {
        // If currently using an item and not clicking on anything cancel the useItem
        if (this.itemBeingUsed && this.userItemClickOnCooldown === false) {
          this.itemBeingUsed = undefined;
          EventBridge.emit("ui.stopUsingItem");
        }
      }, 50);
    });

    this.removeEventListeners();
    this.addEventListeners();

    this.events.once("destroy", this.removeEventListeners);

    this.refetchSessionTimer = this.time.addEvent({
      callback: this.refetchSession,
      callbackScope: this,
      delay: 10000,
      loop: true,
    });

    this.updateLockedObjectsTimer = this.time.addEvent({
      // callback: () => this.updateLockedObjects(this.session?.lockedObjects),
      callback: () => {},
      callbackScope: this,
      delay: 2000,
      loop: true,
    });

    setTimeout(() => {
      loadGameSpecificAssets(
        this,
        this.session!.game?.rooms[this.activeRoomIndex + 1].objects!
      );
    }, 20000);
  }

  addEventListeners() {
    // scrolling
    this.input.on(
      "wheel",
      (
        pointer: any,
        objects: any,
        deltaX: number,
        deltaY: number,
        deltaZ: number
      ) => {
        pointer.event.preventDefault();
        const maxScrollRange =
          (this.game.session.game.rooms[this.activeRoomIndex].length - 1) *
          this.renderer.width;

        let delta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY;

        let targetScrollX = Math.min(
          this.cameras.main.scrollX + delta,
          maxScrollRange
        );
        targetScrollX = Math.max(0, targetScrollX);

        // this.cameras.main.setScroll(targetScrollX, 0);
        // useGameStore.getState().setCurrentXPosition(targetScrollX);
        EventBridge.emit("camera.scrollX", targetScrollX);
      }
    );

    EventBridge.on("camera.scrollX", (scrollX: number) => {
      this.cameras.main.setScroll(scrollX, 0);
    });

    this.input.on("pointermove", (pointer: any) => {
      this.lastCursorPosition.set(
        pointer.x + this.cameras.main.scrollX,
        pointer.y + this.cameras.main.scrollY
      );
      if (this.cursorCooldown > 0) return;
      this.game.socketEmit("handleMessage", {
        sessionId: this.game.session._id,
        type: "cursorMove",
        playerId: this.user.id,
        x: pointer.x + this.cameras.main.scrollX,
        y: pointer.y + this.cameras.main.scrollY,
      });
      this.cursorCooldown = 30;
    });

    // EVENTBRIDGE LISTENERS
    EventBridge.on("game.addItemToInventory", (objectRef: string) => {
      EventBridge.emit(
        "ui.addItemToInventory",
        this.roomObjects[objectRef].gameObject
      );
    });

    EventBridge.on("game.addedToInventory", (sourceObject: IRoomObject) => {
      this.roomObjects[sourceObject.ref].destroy();
    });

    EventBridge.on("game.setUsingItem", (object: IRoomObject) => {
      this.itemBeingUsed = this.roomObjects[object.ref] as GameRoomObject;
      this.setUserItemClickOnCooldown(50);
    });

    EventBridge.on(
      "game.useItem",
      ({ object, itemRef }: { object: IRoomObject; itemRef: string }) => {
        (this.roomObjects[object.ref] as GameRoomObject).executeAction();
        // TODO: Get this working when BE is updated.
        const item = this.uiScene.inventory.items.find(
          (item) => item.object.ref == itemRef
        );
        this.game.socketEmit("useItem", {
          sessionId: this.game.session._id,
          playerId: this.user.id,
          itemId: item.object._id,
        });
      }
    );

    EventBridge.on("game.useHint", () => {
      this.game.socketEmit("useHint", {
        sessionId: this.game.session._id,
        playerId: this.user.id,
        roomId: this.game.session.activeRoomId,
      });
    });

    EventBridge.on("game.addToFeed", (message) => {
      this.game.socketEmit("addToFeed", {
        sessionId: this.game.session._id,
        playerId: this.user.id,
        type: "chat",
        object: message,
      });
    });

    EventBridge.on("game.completeObjectAction", (sourceObject: any) => {
      // sourceObject.completeAction();
      EventBridge.emit("game.completeAction", { objectRef: sourceObject.ref });
    });

    EventBridge.on("game.setActionsDone", (actionsDone: number) => {
      this.actionsDone = actionsDone;
      EventBridge.emit("ui.setProgress", this.actionsDone / this.totalActions);
    });

    EventBridge.on("game.incrementActionsDone", () => {
      this.actionsDone++;
      EventBridge.emit("ui.setProgress", this.actionsDone / this.totalActions);
    });

    EventBridge.on("object.hintUsed", () => {
      Object.values(this.roomObjects).forEach((object) => {
        if (
          object.actions.length > 0 &&
          object.currentAction < object.actions.length
        ) {
          (object as GameRoomObject).hintUsed();
        }
      });
    });

    // WEBSOCKET LISTENERS
    this.game.socket.on("connect", () => {
      console.log(`WebSocket connected: ${this.game.socket.id}`);
    });

    this.game.socket.on("leaveSession", async (data) => {
      await this.session?.refetchSession();
      this.session?.markPlayerInactive(data.player.playerId);
      if (this.cursors.has(data.playerId)) {
        this.cursors.get(data.playerId)?.destroy(true);
      }
      // remove avatar

      // remove from challenge

      // remove from questionnaire
      if (this.session?.activeQuestionnaire != null) {
        EventBridge.emit("quiz.removePlayer");
      }
    });

    this.game.socket.on("joinSession", (msg) => {
      const id = msg.player.playerId;
      const clientId = msg.clientId;
      const score = msg.player.playerScore;
      const firstName = msg.player.firstName;
      const lastName = msg.player.lastName;
      if (id === this.user.id) {
        this.clientId = clientId;
      }
      this.session?.markPlayerActive(id);
    });

    this.game.socket.on("breakingError", (msg) => {
      EventBridge.emit("ui.showErrorScreen");
    });

    this.game.socket.on("pickupItem", (data) => {
      if (data.error) {
        return;
      }
      if (this.user.id === data.sourcePlayerId) {
        let object = Object.values(this.roomObjects).find(
          (object) => object._id === data.objectToDeleteRef
        );
        if (object) {
          object.completeAction(data.sourcePlayerId);
        }
      }
      EventBridge.emit("ui.setInventory", data.inventory);
      EventBridge.emit("game.incrementActionsDone");
    });

    this.game.socket.on("useItem", (data) => {
      if (data.error) {
        return;
      }
      EventBridge.emit("ui.setInventory", data.inventory);
    });

    this.game.socket.on("useHint", (data) => {
      if (!data.error) {
        EventBridge.emit("ui.hintUsed");
      }
    });

    this.game.socket.on("addScore", (data) => {
      const { playerId, playerScore, teamScore, score } = data;
      EventBridge.emit("ui.setScore", {
        playerScore: playerId === this.user.id ? playerScore : undefined,
        teamScore,
      });
      if (playerId === this.user.id && score !== undefined) {
        EventBridge.emit("ui.showXPAlert", score);
      }
    });

    this.game.socket.on("addToFeed", (data) => {
      let message = this.session?.formatFeedItem(data);
      if (message !== undefined) {
        EventBridge.emit("ui.addToLiveFeed", message);
      }
    });

    this.game.socket.on("updateRoomObject", (payload) => {
      if (payload.error) {
        return;
      }
      // Update the objects state
      const object = this.roomObjects[payload.updatedRoomObject.ref];
      object.updateObject(payload.updatedRoomObject);

      if (payload.currentActionFlag === true) {
        // change this code to make sure than actions are only executed locally.
        object.setCurrentAction(
          payload.updatedRoomObject.currentAction,
          payload.sourcePlayerId
        );
        EventBridge.emit("game.incrementActionsDone");
      }
    });

    this.game.socket.on("questionnaireStarted", (payload) => {
      const sourceObject = Object.values(this.roomObjects).find(
        (obj) => obj.id === payload.objectId
      );
      if (sourceObject == null) {
        console.error("Source object not found");
        return;
      }
      const action = sourceObject.actions.find(
        (a) => a.id === payload.actionId
      );
      const questions = action.questionnaire.questions;
      if (questions == null) {
        console.error("Questions not found");
        return;
      }

      const questionnairePayload = {
        actionId: payload.actionId,
        questions,
        sourceObject,
        callback: () => {},
      };

      EventBridge.emit("ui.openQuestionnaire", questionnairePayload);
    });

    this.game.socket.on("unlockItem", ({roomObjectRef}) => {
      this.unlockObject(roomObjectRef);
    });

    this.game.socket.on("openChallenge", (payload) => {
      this.lockObject(payload.roomObjectRef, payload.playerId);
      if (payload.playerId === this.user.id) {
        const sourceObject = this.roomObjects[payload.roomObjectRef];
        if (sourceObject == null) {
          console.error(
            `No source object found. ObjectRef = ${payload.roomObjectRef}.`
          );
          return;
        }
        const action = sourceObject.actions.find(
          (a) => a.id === payload.actionId
        );
        if (action == null) {
          console.error(
            `No action found. ObjectRef=${payload.roomObjectRef}. ActionId=${payload.actionId}`
          );
          return;
        }
        const questions = action.questionnaire?.questions;
        if (questions == null) {
          console.error(
            `No questionnaire/questions found on action=${payload.actionId}.`
          );
          return;
        }

        // build challenge payload
        const challengePayload = {
          questions,
          sourceObject,
          continueCallback: () =>
            this.game.socketEmit("endChallenge", {
              sessionId: this.session!.id,
              roomObjectId: sourceObject.id,
              roomObjectRef: sourceObject.ref,
              actionId: payload.actionId,
              roomId: this.session!.activeRoomId,
            }),
          exitCallback: () =>
            this.game.socketEmit("closeChallenge", {
              sessionId: this.session!.id,
              roomObjectRef: sourceObject.ref,
              actionId: payload.actionId,
            }),
          actionId: payload.actionId,
        };
        EventBridge.emit("ui.openIndividualChallenge", challengePayload);
      }
    });

    this.game.socket.on("closeChallenge", (payload) => {
      this.unlockObject(payload.roomObjectRef);
    });

    this.game.socket.on("endChallenge", (payload) => {
      const { newSourceObjectCurrentAction, sourcePlayerId, roomObjectRef } =
        payload;
      this.roomObjects[roomObjectRef].setCurrentAction(
        newSourceObjectCurrentAction,
        sourcePlayerId
      );
      this.unlockObject(payload.roomObjectRef);
    });

    this.game.socket.on("endRoom", ({ activeRoomId }) => {
      this.tweens.add({
        targets: [this.blackScreen],
        alpha: 1,
        duration: 1000,
      });
      EventBridge.emit("ui.endRoom", () => {
        setTimeout(() => {
          this.endRoom();
        }, 1000);
      });
    });

    this.game.socket.on("endSession", () => {
      this.scene.pause("game-scene");
      EventBridge.emit("ui.endGame", this.endGame.bind(this));
    });

    this.game.socket.on("handleMessage", (data: any) => {
      if (data.type === "cursorMove") {
        if (data.sourcePlayerId === this.user.id) return;
        if (this.cursors.has(data.sourcePlayerId)) {
          const cursor = this.cursors.get(data.sourcePlayerId);
          if (!cursor) return;
          this.tweens.add({
            targets: this.cursors.get(data.sourcePlayerId),
            x: data.x,
            y: data.y,
            duration: 250,
          });
          cursor.resetDestroyTimer();
        } else {
          const player = this.session?.getPlayerFromId(data.sourcePlayerId);
          if (!player) return;
          this.cursors.set(
            data.sourcePlayerId,
            new Cursor(this, data.x, data.y, data.sourcePlayerId, player)
          );
        }
      }
    });
  }

  removeEventListeners() {
    EventBridge.remove("camera.scrollX");
    EventBridge.remove("game.addItemToInventory");
    EventBridge.remove("game.addedToInventory");
    EventBridge.remove("game.setUsingItem");
    EventBridge.remove("game.useItem");
    EventBridge.remove("game.useHint");
    EventBridge.remove("game.addToFeed");
    EventBridge.remove("game.completeObjectAction");
    EventBridge.remove("game.setActionsDone");
    EventBridge.remove("game.incrementActionsDone");
    EventBridge.remove("object.hintUsed");
    this.game.socket.off("connect");
    this.game.socket.off("joinSession");
    this.game.socket.off("leaveSession");
    this.game.socket.off("handleMessage");
    this.game.socket.off("pickupItem");
    this.game.socket.off("useItem");
    this.game.socket.off("useHint");
    this.game.socket.off("addScore");
    this.game.socket.off("addToFeed");
    this.game.socket.off("updateRoomObject");
    this.game.socket.off("questionnaireStarted");
    this.game.socket.off("lockItem");
    this.game.socket.off("unlockItem");
    this.game.socket.off("endRoom");
    this.game.socket.off("endSession");
    this.time.removeAllEvents();
  }

  destoryPhaser() {
    this.game.destroy(true);
    this.sys.game.destroy(true);
  }

  setUserItemClickOnCooldown(cooldown: number) {
    this.userItemClickOnCooldown = true;
    setTimeout(() => {
      this.userItemClickOnCooldown = false;
    }, cooldown);
  }

  update(time: number, delta: number): void {
    if (this.cursorCooldown > 0) {
      this.cursorCooldown -= 1;
      if (this.cursorCooldown === 0) {
        this.game.socketEmit("handleMessage", {
          sessionId: this.game.session._id,
          type: "cursorMove",
          clientId: this.clientId,
          playerId: this.user.id,
          x: this.lastCursorPosition.x,
          y: this.lastCursorPosition.y,
        });
      }
    }

    if (this.gameOver && !this.gameOverScreenShown) {
      this.gameOverScreenShown = true;
      getGameSession(this.game.session._id)
        .then((res) => {
          this.session?.refreshPlayers(res.data.data.players);
          const gameCompleteScreen = new GameCompleteScreen(
            this.uiScene!,
            res.data.data
          );
        })
        .catch((err) => {
          this.gameOverScreenShown = false;
        });
    }
  }

  setLockedObjects(lockedObjects: lockedObjects, force?: boolean) {
    if (!this.session || lockedObjects == null) {
      return;
    }

    const currentlyLockedObjectRefs = Object.keys(this.session.lockedObjects);
    const futureLockedObjectRefs = Object.keys(lockedObjects);

    const objectsToUnlock = currentlyLockedObjectRefs.filter(
      (objectRef) => !futureLockedObjectRefs.includes(objectRef)
    );
    const objectsToLock = force
      ? futureLockedObjectRefs
      : futureLockedObjectRefs.filter(
          (objectRef) => !currentlyLockedObjectRefs.includes(objectRef)
        );

    objectsToLock.forEach((key) => {
      const value = lockedObjects[key];
      const player = this.session!.getPlayerFromId(value.playerId);
      if (player === undefined) {
        console.error("Player not found");
        return;
      }
      this.roomObjects[key].setLockedToPlayer(player);
    });

    objectsToUnlock.forEach((key) => this.unlockObject(key));
  }

  lockObject(objectRef: string, playerId: string) {
    this.roomObjects[objectRef].setLockedToPlayer(
      this.session?.getPlayerFromId(playerId)!
    );
  }

  unlockObject(objectRef: string) {
    this.roomObjects[objectRef].unlockFromPlayer();
  }

  unloadRoom() {
    Object.values(this.roomObjects).forEach((object) => {
      object.destroy();
    });
    this.roomObjects = {};
  }

  loadRoom() {
    if (!this.session) {
      console.error("No session found on scene");
      return;
    }

    this.totalActions = 0;
    this.session.game.rooms[this.activeRoomIndex].objects.forEach((object) => {
      const roomObject = new GameRoomObject(
        this,
        object.xPos,
        object.yPos,
        object
      );
      this.roomObjects[roomObject.ref] = roomObject;
      this.totalActions += object.actions.length;
    });

    this.cameras.main.setBounds(
      0,
      0,
      this.renderer.width *
        this.game.session.game.rooms[this.activeRoomIndex].length,
      this.renderer.height
    );
  }

  loadUI(): UIScene {
    return this.scene.add("ui-scene", new UIScene(this), true) as UIScene;
  }

  endRoom(activeRoomId?: string) {
    this.uiScene?.inventory?.setInventory([]);
    this.cameras.resetAll();
    this.session = undefined;
    this.uiScene?.destroy();
    this.uiScene = undefined;
    this.scene.restart();
  }

  refetchSession() {
    this.session?.refetchSession();
    this.cursors.forEach((cursor) => {
      cursor.redrawCusor();
    });
  }

  endGame() {
    this.scene.resume("game-scene");
    this.gameOver = true;
  }

  disableInput() {
    this.input.enabled = false;
  }

  enableInput() {
    this.input.enabled = true;
  }

  leaveGame() {
    // Leaving the session with the actual websocket event then redirecting seems
    // to crash the BE due to a race condition.
    // this.game.socketEmit("leaveSession", { sessionId: this.session.id }); // BEWARE
    window.location.href = "/signout";
  }

  public async getAssets(): Promise<any> {
    let assets = await super.getAssets();
    let session = (await getGameSession(this.game.session._id)).data
      .data as IGameSession;
    let uniqueObjectsName = new Set(
      session.game.rooms
        .map((room) => room.objects.map((object) => object.asset.name))
        .flat()
    );
    return assets.filter((asset: IAsset) => uniqueObjectsName.has(asset.name));
  }

  launchActiveQuestionnaire() {
    const activeQuestionnaire = this.session?.activeQuestionnaire;
    if (activeQuestionnaire == null) {
      console.error("Active questionnaire not found");
      return;
    }
    const sourceObject = Object.values(this.roomObjects).find(
      (obj) => obj.id === activeQuestionnaire.roomObjectId
    );
    if (sourceObject == null) {
      console.error("Source object not found");
      return;
    }
    const action = sourceObject.actions.find(
      (a) => a.id === activeQuestionnaire.actionId
    );
    if (action == null) {
      console.error("Action not found");
      return;
    }
    const questions = action.questionnaire!.questions;
    if (questions == null) {
      console.error("Questions not found");
      return;
    }

    const questionnairePayload = {
      id: activeQuestionnaire._id,
      actionId: activeQuestionnaire.actionId,
      questions: questions,
      sourceObject,
      callback: () => {},
      activeQuestionnaire,
    };

    EventBridge.emit("ui.openQuestionnaire", questionnairePayload);
  }
}

interface lockedObjects {
  [key: string]: { roomObjectId: string; playerId: string };
}
