import UIScene from "@/phaser/scenes/UIScene";
import BaseModal from "../BaseModal";

import { v4 as uuidv4 } from "uuid";

export default class ImageModal extends BaseModal {
  message?;
  image;

  constructor(
    scene: UIScene,
    imageURL: string,
    optionalMessage?: string,
    continueCallback?: () => void
  ) {
    super(scene, continueCallback && "CONTINUE", continueCallback);
    const messageExists =
      optionalMessage == null || optionalMessage.length == 0 ? false : true;
    const imageSize = messageExists ? 450 : 500;

    if (messageExists) {
      this.message = new Phaser.GameObjects.Text(
        scene,
        scene.renderer.width / 2,
        100,
        optionalMessage!,
        {
          fontFamily: "Roboto-Regular",
          fontSize: "20px",
          color: "#FFFFFF",
          wordWrap: { width: 900, useAdvancedWrap: true },
          align: "center",
        }
      )
        .setOrigin(0.5, 0)
        .setLineSpacing(8);
      this.add(scene.add.existing(this.message));
    }

    const imageYStart = this.message
      ? this.message.getBottomCenter().y + 65
      : 125;

    let rectangle = new Phaser.GameObjects.Rectangle(
      scene,
      this.scene.renderer.width / 2,
      imageYStart,
      imageSize,
      imageSize,
      0
    ).setOrigin(0.5, 0);

    this.image = new Phaser.GameObjects.Image(
      scene,
      rectangle.x,
      rectangle.y,
      ""
    )
      .setOrigin(0.5, 0)
      .setVisible(false);

    // load image dynamically.
    const loader = new Phaser.Loader.LoaderPlugin(scene);
    const imageName = uuidv4();
    loader.image(imageName, imageURL);

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.image.setTexture(imageName).setVisible(true);
      const aspectRatio = this.image.height / this.image.width;
      if (aspectRatio < 1) {
        this.image.setDisplaySize(
          rectangle.width,
          rectangle.width * aspectRatio
        );
      } else {
        this.image.setDisplaySize(
          rectangle.width / aspectRatio,
          rectangle.height
        );
      }
    });

    loader.start();

    this.add(scene.add.existing(rectangle));
    this.add(scene.add.existing(this.image));
  }
}
