import EventBridge from "@/utils/EventBridge";
import TextButton from "../ui/buttons/TextButton";
import IconButton from "../ui/buttons/IconButton";
import UIScene from "@/phaser/scenes/UIScene";

export default abstract class BaseModal extends Phaser.GameObjects.Group {
  scene: UIScene;
  background: Phaser.GameObjects.Image;
  closeButton: IconButton;
  exitButton: TextButton;
  continueButton?: TextButton;

  constructor(
    scene: UIScene,
    continueBtnText?: string,
    continueCallback?: () => void
  ) {
    super(scene);
    this.scene = scene;

    this.background = new Phaser.GameObjects.Image(
      scene,
      scene.renderer.width / 2,
      scene.renderer.height / 2,
      "modalBackground"
    )
      .setDisplaySize(scene.renderer.width, scene.renderer.height)
      .setInteractive();

    this.closeButton = new IconButton(
      scene,
      scene.renderer.width - 40,
      40,
      "close-modal",
      () => this.closeModal()
    );

    this.exitButton = new TextButton(
      scene,
      scene.renderer.width / 2 - 80,
      scene.renderer.height - 100,
      "EXIT",
      "danger",
      () => {
        this.closeModal();
      }
    );

    this.add(scene.add.existing(this.background));
    this.add(scene.add.existing(this.closeButton));
    this.add(scene.add.existing(this.exitButton));

    if (continueBtnText && continueCallback) {
      this.continueButton = new TextButton(
        scene,
        scene.renderer.width / 2 + 80,
        scene.renderer.height - 100,
        continueBtnText,
        "tertiary",
        () => {
          continueCallback();
          this.closeModal();
        }
      );
      this.add(scene.add.existing(this.continueButton));
    } else {
      this.exitButton.setX(this.scene.renderer.width / 2);
    }
  }

  closeModal() {
    this.destroy(true);
  }
}
