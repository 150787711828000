import { TObjectOrderDirection } from "./types/game";
import Player from "./phaser/classes/session/Player";
import { createRandomString } from "./utils/phaser-helpers";

const env = import.meta.env.MODE;

export const API_URL = `${import.meta.env.VITE_API_URL}`;
export const WS_URL = import.meta.env.VITE_WEBSOCKETS_URL;

export const industries = [
  {
    label: "Manufacturing & Construction",
    value: "Manufacturing & Construction",
  },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Education", value: "Education" },
  { label: "Retail and Consumer", value: "Retail and Consumer" },
  {
    label: "Telecommunications (Media & Technology)",
    value: "Telecommunications (Media & Technology)",
  },
  {
    label: "Transport (Civil, Aviation)",
    value: "Transport (Civil, Aviation)",
  },
  { label: "Financial Services", value: "Financial Services" },
  {
    label: "Hotel, Tourism and Catering",
    value: "Hotel, Tourism and Catering",
  },
  {
    label: "Utilities (Oil and Gas, Agriculture)",
    value: "Utilities (Oil and Gas, Agriculture)",
  },
];

// Phaser Config
export const SCREEN_WIDTH = 1440;
export const SCREEN_HEIGHT = 830;

export const Directions: {
  FORWARD: TObjectOrderDirection;
  BACKWARD: TObjectOrderDirection;
  FRONT: TObjectOrderDirection;
  BACK: TObjectOrderDirection;
} = {
  FORWARD: "forward",
  BACKWARD: "backward",
  FRONT: "front",
  BACK: "back",
};

export const modalTextChoices: Array<string> = [
  "All rather quiet over here",
  "Nothing to see here",
  "Keep going, you're doing great",
  "Not quite, try again",
  "Have a look at…",
  "Have you considered…",
  "Oops, that's not right",
  "You're getting warmer",
];

export const avatarColors: Array<number> = [
  0x1A2A5A,
  0xC52A1A,
  0x4B4226,
  0xD04A02,
  0x6B2CDA,
  0x203570,
  0x822720,
  0x786124,
  0xE86153,
  0x4B20AB,

];

export const emailPlaceholders: Array<Partial<Player>> = [
  {
    id: "1",
    clientId: "1",
    firstName: "Mary",
    lastName: "Poppins",
    color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
  },
  {
    id: "2",
    clientId: "2",
    firstName: "Joe",
    lastName: "Smithson",
    color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
  },
  {
    id: "3",
    clientId: "3",
    firstName: "Stephen",
    lastName: "Guarriquez",
    color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
  },
];

export const generateFakePlayers = (count: number): Array<Player> => {
  const players: Array<Player> = [];
  for (let i = 0; i < count; i++) {
    players.push(
      new Player({
        id: `${i}`,
        clientId: `${i}`,
        firstName: `${createRandomString(4)}`,
        lastName: `${createRandomString(4)}`,
        color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
        score: 0,
        inactive: false,
      })
    );
  }
  return players;
};

export const autosaveInterval = 60000