import { getToken } from "@/api/auth";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

const Auth = () => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const state = params.get("state");

  const getTokenQuery = useQuery({
    queryKey: ["getToken", code],
    queryFn: () => getToken(code),
    retry: false,
  });

  if (!code) {
    return <div>No code found.</div>;
  }

  if (getTokenQuery.isLoading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center'
    }}>
        Loading...
    </div>;
  }

  if (getTokenQuery.data) {
    const { id_token } = getTokenQuery.data.data;
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("next_refresh", (Date.now() + 30 * 60 * 1000).toString());
    if (state == null || state == "null") {
      window.location.href = "/";
    } else {
      window.location.href = state;
    }
  }

  return <></>;
};

export default Auth;
