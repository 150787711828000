import {
  Input,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextArea,
  Upload,
} from "@appkit4/react-components";
import {
  IAction,
  IMediaPayload,
  IRoomObject,
} from "escape-rooms-types/types/game";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import styles from "../ObjectConfigAction.module.scss";

interface IActionDisplayMediaProps {
  action: IAction;
  selected: IRoomObject;
  updateActionProperties: (payload: Partial<IAction>) => void;
}

const ActionDisplayMedia = ({
  action,
  selected,
  updateActionProperties,
}: IActionDisplayMediaProps) => {
  const updateMediaPayload = (payload: Partial<IMediaPayload>) => {
    updateActionProperties({
      mediaPayload: {
        ref: (payload.ref ?? action.mediaPayload?.ref) || uuidv4(),
        type: (payload.type ?? action.mediaPayload?.type) || "image",
        mediaUrl: payload.mediaUrl ?? action.mediaPayload?.mediaUrl,
        message: payload.message ?? action.mediaPayload?.message,
      },
    });
  };

  return (
    <>
      <div className="col-12 ap-my-spacing-4">
        <span className="input-label">URL</span>
        <Input
          value={action.mediaPayload?.mediaUrl}
          onChange={(e) => updateMediaPayload({ mediaUrl: e })}
        />
      </div>
      <div className="col-12 ap-my-spacing-4">
        <RadioGroup
          value={action.mediaPayload?.type || "image"}
          onChange={(e) => {
            updateMediaPayload({ type: e });
          }}
        >
          <Radio value="image">Image</Radio>
          <Radio value="video">Video</Radio>
          <Radio value="audio">Audio</Radio>
        </RadioGroup>
      </div>
      <div className="col-12 ap-my-spacing-6">
        <span className="input-label">Optional Message</span>
        <TextArea
          maxLength={300}
          value={action?.mediaPayload?.message}
          onChange={(e) => {
            updateMediaPayload({ message: e });
          }}
        />
      </div>
      <div className="col-12 ap-mt-spacing-6">
        <Switch
          checked={action.repeatable ?? false}
          onChange={(value) => {
            updateActionProperties({ repeatable: value });
          }}
        >
          <span>Make repeatable (media shows on every click)</span>
        </Switch>
      </div>
    </>
  );
};

export default ActionDisplayMedia;
