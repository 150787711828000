import Player from "./session/Player";
import UIScene from "../scenes/UIScene";
import GameScene from "../scenes/GameScene";

export default class Avatar extends Phaser.GameObjects.Container {
  public player: Player;
  private image?: Phaser.GameObjects.Image;
  private initials?: Phaser.GameObjects.Text;
  private circle?: Phaser.GameObjects.Graphics;
  private overlay?: Phaser.GameObjects.Image;
  private color?: number;
  private onClick?: () => void;

  constructor(
    scene: UIScene,
    x: number,
    y: number,
    player: Player,
    size: number,
    onClick?: () => void
  ) {
    super(scene, x, y);
    this.player = player;

    if (scene.env === "game") {
      this.color =
        player.inactive && player.id != (scene.sourceScene as GameScene).user.id
          ? 0x5a5a5a
          : player.color;
    } else {
      this.color = player.color;
    }

    // if (player.imageUrl != null) {
    //   this.image = new Phaser.GameObjects.Image(
    //     scene,
    //     0,
    //     0,
    //     "avatar"
    //   ).setDisplaySize(size, size);
    //   this.add(this.image);
    //   return;
    // }

    this.circle = new Phaser.GameObjects.Graphics(scene);
    this.circle.fillStyle(this.color);
    this.circle.fillCircle(0, 0, size / 2);
    this.circle.lineStyle(2, 0x000000);
    this.circle.strokeCircle(0, 0, size / 2);
    this.add(this.circle);

    const intialsStr =
      player.firstName == ""
        ? "N/A"
        : `${player.firstName[0].toUpperCase()}${player.lastName[0].toUpperCase()}`;
    this.initials = new Phaser.GameObjects.Text(scene, -9, -7, intialsStr, {
      fontFamily: "Roboto-Medium",
      fontSize: "14px",
    });
    this.add(this.initials);

    if (onClick != null) {
      this.setInteractive(
        new Phaser.Geom.Circle(0, 0, size / 2),
        Phaser.Geom.Circle.Contains
      )
        .on("pointerover", () => {
          this.setAlpha(0.5);
        })
        .on("pointerout", () => {
          this.setAlpha(1);
        })
        .on("pointerdown", onClick);
    }
  }

  public markAsFinished() {
    this.image?.setAlpha(0.5);
    this.circle?.setAlpha(0.5);
    this.initials?.setAlpha(0.5);
    this.overlay = new Phaser.GameObjects.Image(
      this.scene,
      0,
      0,
      "avatar-overlay-finished"
    );
    this.add(this.overlay);
  }

  public markAsUnfinished() {
    this.image?.setAlpha(1);
    this.circle?.setAlpha(1);
    this.initials?.setAlpha(1);
    this.overlay?.destroy();
    this.overlay = undefined;
  }

  public destroy() {
    this.image?.destroy();
    this.initials?.destroy();
    this.circle?.destroy();
    this.overlay?.destroy();
    super.destroy();
  }
}
