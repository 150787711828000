import Phaser from "phaser";
import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import EventBridge from "@/utils/EventBridge";
import { IRoomObject } from "escape-rooms-types/types/game";
import { getThumbnail } from "@/utils/phaser-helpers";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";
import { PreviewPhaserGame } from "@/components/phaser-game/PreviewGame";

export default class Item {
  scene: UIScene;
  object: IRoomObject;
  container: Phaser.GameObjects.Container;
  itemBackground;
  itemSprite;
  tooltip: Phaser.GameObjects.Container;

  constructor(scene: UIScene, object: IRoomObject, index: number) {
    this.scene = scene;
    this.object = object;

    const tooltipText = new Phaser.GameObjects.Text(
      scene,
      0,
      0,
      object.name,
      {}
    ).setOrigin(0.5, 0.5);

    const tooltipBackground = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      tooltipText.width + 10,
      tooltipText.height + 10,
      0
    ).setOrigin(0.5, 0.5);

    this.tooltip = new Phaser.GameObjects.Container(
      scene,
      -tooltipBackground.width / 2,
      -20,
      [tooltipBackground, tooltipText]
    ).setVisible(false);

    this.itemBackground = new Phaser.GameObjects.Image(
      this.scene,
      0,
      0,
      "inventory-item-bg-filled"
    )
      .setAlpha(0.3)
      .setDisplaySize(50, 50)
      .setInteractive()
      .on("pointerdown", () => this.useItem(scene, object))
      .on("pointerover", () => {
        if (!this.scene.usingItemCursor) this.tooltip.setVisible(true);
      })
      .on("pointerout", () => {
        this.tooltip.setVisible(false);
      });

    this.itemSprite = new Phaser.GameObjects.Sprite(
      this.scene,
      0,
      0,
      scene.sourceScene
        .getRoomObject(object.ref)
        .getThumbnailFromState(object.currentState)
    ).setSize(50, 50);

    this.itemSprite.displayHeight = 30;
    this.itemSprite.scaleX = this.itemSprite.scaleY;

    const [x, y] = this.getXandYFromIndex(this.scene, index);

    this.container = new Phaser.GameObjects.Container(scene, x, y, [
      this.itemBackground,
      this.itemSprite,
      this.tooltip,
    ]);

    scene.add.existing(this.container);
  }

  private getXandYFromIndex = (scene: Phaser.Scene, index: number) => {
    let x = scene.renderer.width - 31;
    let y = scene.renderer.height / 2 - 145;
    y = y + 58 * index;
    return [x, y];
  };

  private useItem(scene: UIScene, object: IRoomObject) {
    EventBridge.emit("ui.stopUsingItem", object);
    const env = (this.scene.game as PhaserGameClass | PreviewPhaserGame).env;
    EventBridge.emit(`${env}.setUsingItem`, object);

    const usingItemCursor = new Phaser.GameObjects.Sprite(
      scene,
      scene.input.mousePointer.x,
      scene.input.mousePointer.y,
      scene.sourceScene
        .getRoomObject(object.ref)
        .getThumbnailFromState(object.currentState)
    ).setSize(50, 50);

    usingItemCursor.displayHeight = 50;
    usingItemCursor.scaleX = usingItemCursor.scaleY;
    scene.add.existing(usingItemCursor);
    scene.usingItemCursor = usingItemCursor;
  }
}
