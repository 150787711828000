import AppHeader from "@/components/app-header";
import {Outlet} from "react-router-dom";
import {UserInfoProvider} from "@/context/UserInfoContext";
import AppFooter from "@/components/app-footer";
import ProtectedRoute from "@/components/protected-route/ProtectedRoute";

const Layout = () => (
    <>
        <ProtectedRoute>
            <AppHeader/>
            <Outlet/>
            <AppFooter/>
        </ProtectedRoute>
    </>
);

export default Layout;
