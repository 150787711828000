import Phaser from "phaser";
import RoomObject from "@/phaser/classes/RoomObject/BaseRoomObject";
import { insertCustomColorsIntoSvgText } from "@/utils/helpers";
import { getAssets } from "@/api/assets";

export default class BaseScene extends Phaser.Scene {
  public roomObjects: { [key: string]: RoomObject };

  constructor(sceneName: string) {
    super(sceneName);
    this.roomObjects = {};
  }

  preload() {
  }

  destoryPhaser() {
    this.game.destroy(true);
    this.sys.game.destroy(true);
  }

  loadSvgFromText(
    key: string,
    svgText: string,
    colors: string[],
    config?: any
  ) {
    // TODO: Get the backend to return the svg as a string.
    const newSvg = insertCustomColorsIntoSvgText(svgText, colors);

    const blob = new Blob([newSvg], { type: "image/svg+xml" });
    const svgUrl = URL.createObjectURL(blob);
    return this.load.svg(key, svgUrl, config);
  }

  public getRoomObject(objectRef: string) {
    return this.roomObjects[objectRef];
  }

  public async getAssets() {
    let getAssetRes =  await getAssets();
    return getAssetRes.data.data;
  }
}
