import { useEffect, useRef } from "react";
import Phaser from "phaser";
import gameConfig from "../../phaser/creator-config";
import styles from "@/components/phaser-game/CreatorGame.module.scss";
import EventBridge from "@/utils/EventBridge";
import { IAsset } from "escape-rooms-types/types/game";

export class CreatorPhaserGame extends Phaser.Game {
  assets: IAsset[];
  env: string;

  constructor(gameConfig: Phaser.Types.Core.GameConfig, assets: IAsset[]) {
    super(gameConfig);
    this.input.mouse.preventDefaultWheel = false;
    this.assets = assets;
    this.env = "creator";
  }
}

const CreatorGame = ({ assets }: { assets: IAsset[] }) => {
  const phaserGameRef = useRef<CreatorPhaserGame | null>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const initialisePhaserGameFromCurrentRoom = (scene: Phaser.Scene) => {
    if (phaserGameRef.current == null) {
      return;
    }
  };

  useEffect(() => {
    // Set up
    if (assets.length == 0) {
      return
    }
    if (phaserGameRef.current) {
      return;
    }

    phaserGameRef.current = new CreatorPhaserGame(gameConfig, assets);

    phaserGameRef.current.events.on("ready", () => {
      if (phaserGameRef.current == null) {
        return;
      }
      initialisePhaserGameFromCurrentRoom(
        phaserGameRef.current.scene.scenes[0]
      );
    });

    // Add event listeners
    EventBridge.on("creator.destory", () => {
      phaserGameRef.current?.destroy(true);
      phaserGameRef.current = null;
    });

    return () => {
      phaserGameRef.current?.destroy(true);
      phaserGameRef.current = null;
    };
  }, [assets]);

  return (
    <div
      id="phaser-creator-container"
      className={styles.phaserContainer}
      ref={divRef}
    ></div>
  );
};

export default CreatorGame;
