import GameScene from "@/phaser/scenes/GameScene";
import Player from "../session/Player";

const timerDuration = 5;

export default class Cursor extends Phaser.GameObjects.Container {
  public scene: GameScene;
  public playerId: string;
  public cursorSprite: Phaser.GameObjects.Sprite;
  public cursorText: Phaser.GameObjects.Text;
  public cursorCircle: Phaser.GameObjects.Graphics;
  public player: Player;
  private timer: number;
  private timerInterval?: NodeJS.Timeout;

  constructor(
    scene: GameScene,
    x: number,
    y: number,
    playerId: string,
    player: Player
  ) {
    super(scene, x, y);
    this.scene = scene;
    this.playerId = playerId;
    this.player = player;
    this.timer = timerDuration;

    this.cursorSprite = new Phaser.GameObjects.Sprite(
      scene,
      0,
      0,
      "avatar-cursor"
    );

    this.cursorText = new Phaser.GameObjects.Text(
      scene,
      0,
      -6,
      `${player.firstName[0]?.toUpperCase()}${player.lastName[0]?.toUpperCase()}`,
      {
        fontFamily: "Roboto-Medium",
        color: "white",
        fontSize: "12px",
      }
    ).setOrigin(0.5, 0.5);

    this.cursorCircle = new Phaser.GameObjects.Graphics(scene);
    this.redrawCusor();

    this.add(this.cursorSprite);
    this.add(this.cursorCircle);
    this.add(this.cursorText);
    scene.add.existing(this);
    this.startDestroyTimer();
  }

  redrawCusor() {
    this.cursorCircle.clear();
    this.cursorCircle.fillStyle(this.player.color);
    this.cursorCircle.fillCircle(this.cursorText.x, this.cursorText.y, 14);
  }

  startDestroyTimer() {
    this.timerInterval = setInterval(() => {
      if (this.timer < 0) {
        this.destroyAndRemove();
      }
      this.timer--;
    }, 1000);
  }

  resetDestroyTimer() {
    this.timer = timerDuration;
  }

  destroyAndRemove(): void {
    clearInterval(this.timerInterval!);
    this.scene.cursors.delete(this.playerId);
    this.destroy();
  }
}
