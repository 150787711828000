import Phaser from "phaser";
import gameConfig from "../../phaser/preview-config";
import { useEffect, useRef } from "react";
import { IRoom } from "escape-rooms-types/types/game";
import styles from "./PreviewGame.module.scss";
import LoadingScene from "@/phaser/scenes/LoadingScene";

export class PreviewPhaserGame extends Phaser.Game {
  public previewRoomObject: IRoom;
  public env: string;
  public assets: any[];

  constructor(
    gameConfig: Phaser.Types.Core.GameConfig,
    roomObject: IRoom,
  ) {
    super(gameConfig);
    this.previewRoomObject = roomObject;
    this.env = "preview";
    this.assets = [];

    const processedAssets = new Set();
    roomObject.objects.forEach((object) => {
      if (processedAssets.has(object.asset._id)) {
        return;
      }
      this.assets.push(object.asset);
      processedAssets.add(object.asset._id);
    })
  }
}

const PreviewGame = ({
  roomObject,
}: {
  roomObject: IRoom;
}) => {
  const phaserGameRef = useRef<PreviewPhaserGame | null>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set up
    if (phaserGameRef.current) {
      return;
    }

    phaserGameRef.current = new PreviewPhaserGame(
      gameConfig,
      roomObject,
    );

    phaserGameRef.current.events.on("ready", () => {
      if (phaserGameRef.current == null) {
        return;
      }
    });

    return () => {
      phaserGameRef.current?.destroy(true);
      phaserGameRef.current = null;
    };
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={styles.phaserContainer}
        id="phaser-preview-container"
        ref={divRef}
      ></div>
    </div>
  );
};

export default PreviewGame;
