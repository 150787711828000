import { createGame, getClientList, getGames } from "@/api/game";
import {
  Avatar,
  Button,
  Column,
  Input,
  Pagination,
  PaginationRef,
  Search,
  Select,
  Table,
} from "@appkit4/react-components";
import { IGame } from "escape-rooms-types/types/game";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./GameCreator.module.scss";
import { industries } from "@/constants";
import { capitalizeFirstLetter } from "@/utils/helpers";

const GameCreator = () => {
  const paginationRef = useRef<PaginationRef>(null);
  const navigate = useNavigate();
  const [games, setGames] = useState<IGame[]>([]);

  const [searchString, setSearchString] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>("_id");
  const [sortOrder, setSortOrder] = useState<number>(1);

  const [totalPages, setTotalPages] = useState<number>(1);

  const [clientList, setClientList] = useState([]);

  const resetFilters = useCallback(() => {
    setSearchString("");
    setStatus("");
    setClient("");
    setIndustry("");
  }, []);

  const getGamesQuery = useQuery({
    queryKey: ["getGames", searchString],
    queryFn: () =>
      getGames(page, limit, sortBy, sortOrder, searchString, status, client, industry),
    onSuccess: (res) => {
      setGames(res.data.data);
      const totalPages = Math.max(Math.ceil(res.data.count / limit), 1);
      setTotalPages(totalPages);
      setPage(Math.min(page, totalPages));
      if (paginationRef.current) {
        paginationRef.current.setPageNumber(Math.min(page, totalPages));
      }
    },
  });

  const getClients = useQuery({
    queryKey: ["getClients"],
    queryFn: getClientList,
    onSuccess: (res) => {
      const OGClients = [{ value: "", label: "All" }];
      const clients = OGClients.concat(res.data.gameClients.map((client) => {
        return { value: client, label: client };
      }));
      setClientList(clients)
    },
  });

  const createGameQuery = useQuery({
    queryKey: ["createGame"],
    queryFn: () =>
      createGame({
        name: "Untitled Game",
        rooms: [
          {
            name: "New Room",
            ref: uuidv4(),
            objects: [],
            items: [],
            isActive: true,
            length: 1,
            order: 0,
          },
        ],
      }),
    onSuccess: (res) => {
      navigate(`/games/${res.data._id}`);
      getGamesQuery.refetch();
    },
    enabled: false,
  });

  const openGame = (game: IGame) => {
    return navigate(`/games/${game._id}`);
  };

  useEffect(() => {
    getGamesQuery.refetch();
  }, [page, limit, sortOrder, sortBy, searchString, status, client, industry]);

  const renderDate = (row: any, field: string) => {
    if (!row[field]) return "";

    let date = new Date(row[field]);
    return (
      <div className="ap-font-weight-2">
        {date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </div>
    );
  };

  const renderClient = (row: any, field: string) => {
    if (row["client"]) {
      return row["client"];
    }
    return "N/A";
  };

  const renderIndustry = (row: any, field: string) => {
    if (row["industry"]) {
      return row["industry"];
    }
    return "N/A";
  };

  const renderCreator = (row: any, field: string) => {
    const creator = row["creator"] || "N/A";
    const label =
      creator === "N/A"
        ? "NA"
        : creator
            .split(" ")
            .map((word: string) => word[0])
            .join("")
            .toUpperCase();
    return (
      <div className="flex gap-1 align-center">
        <Avatar label={label} />
        <div>{creator}</div>
      </div>
    );
  };

  const renderAction = (row: any, field: string) => {
    return (
      <Button
        className="Appkit4-icon icon-setting-outline"
        kind="primary"
        onClick={() => {
          openGame(row);
        }}
      />
    );
  };

  const renderStatus = (row: any, field: string) => {
    if (!row[field]) return "";

    return (
      <div className="flex align-center gap-1">
        <div
          className={`circle ap-bg-color-background-${
            row[field] === "published" ? "success" : "warning"
          }`}
        ></div>
        <div>{capitalizeFirstLetter(row[field])}</div>
      </div>
    );
  };

  const onSort = (sortKey: string, sortingPhase: number) => {
    if (sortingPhase == 0) {
      setSortOrder(1);
      return;
    }
    setSortOrder(sortingPhase === 1 ? -1 : 1);
  };

  return (
    <div className="ap-container">
      <h2 className="ap-typography-4 ap-font-weight-3 ap-mt-spacing-6 ap-mb-spacing-6">
        Games
      </h2>
      <div className="row">
        <div className="col-12 flex gap-1 align-center">
          <Search
            className="max-content"
            searchValue={searchString}
            onChange={(e) => {
              setSearchString(e);
            }}
            placeholder="Search by name or creator"
          />
          <Select
            data={[
              { value: "", label: "All" },
              { value: "published", label: "Published" },
              { value: "draft", label: "Draft" },
            ]}
            value={status}
            onSelect={(e) => setStatus(e)}
            placeholder="Status"
          />
          <Select
            data={clientList}
            value={client}
            onSelect={(e) => setClient(e)}
            placeholder="Client"
          />
          <Select
            data={[{ value: "", label: "All" }, ...industries]}
            placeholder="Industry"
            value={industry}
            onSelect={(e) => setIndustry(e)}
          />
          <Button kind="secondary" className="ml-auto" onClick={resetFilters}>
            Reset filters
          </Button>
        </div>
        <div className="col-12">
          <Table
            originalData={games}
            hasTitle
            className="data-table"
            onSort={onSort}
          >
            <Column field="name">Name</Column>
            <Column field="status" renderCell={renderStatus}>
              Status
            </Column>
            <Column field="_id" renderCell={renderClient}>
              Client
            </Column>
            <Column field="industry" renderCell={renderIndustry}>
              Industry
            </Column>
            <Column field="_id" renderCell={renderCreator}>
              Creator
            </Column>
            <Column
              field="createdAt"
              // sortKey="createdAt"
              renderCell={renderDate}
            >
              Date created
            </Column>
            <Column field="_id" renderCell={renderAction}>
              Actions
            </Column>
          </Table>
        </div>
        <div className="col-12 ap-mt-spacing-4 flex justify-end">
          <Button
            icon="icon-plus-outline"
            onClick={() => {
              createGameQuery.refetch();
            }}
          >
            Create new game
          </Button>
        </div>
        <div className="col-12 flex justify-center ap-my-spacing-6">
          <Pagination
            ref={paginationRef}
            current={page}
            onPageChange={(page) => {
              setPage(page);
            }}
            total={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default GameCreator;
