import EventBridge from "@/utils/EventBridge";
import { ScrollBar } from "./ScrollBar";

export default class LiveFeed extends Phaser.GameObjects.Container {
  private openHeight: number = 300;
  private closedHeight: number = 100;

  private background: Phaser.GameObjects.Rectangle;
  private title: Phaser.GameObjects.Text;
  private toggleBtn: Phaser.GameObjects.Image;
  private isOpen: boolean = false;

  public messages: Array<string>;
  public messagesContainer: Phaser.GameObjects.Container;

  public inputRectangle: Phaser.GameObjects.Rectangle;
  public inputStr: string;
  public inputText: Phaser.GameObjects.Text;
  public isFocused: boolean = false;

  public feedCounter: number = 0;
  public feedCounterBadge: Phaser.GameObjects.Graphics;
  public feedCounterText: Phaser.GameObjects.Text;

  public maskShape?: Phaser.GameObjects.Graphics;
  public scrollHeight: number = 0;

  public scrollBar: ScrollBar;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);

    this.scene = scene;

    this.background = new Phaser.GameObjects.Rectangle(
      scene,
      0,
      0,
      300,
      this.closedHeight,
      0,
      0.5
    ).setOrigin(0, 1);
    this.add(this.background);

    this.title = new Phaser.GameObjects.Text(
      scene,
      16,
      -this.background.height + 24,
      "Live feed and team chat",
      { color: "white", fontFamily: "Roboto-Medium", fontSize: "16px" }
    ).setOrigin(0, 0.5);
    this.add(this.title);

    this.toggleBtn = new Phaser.GameObjects.Image(
      scene,
      0,
      -this.background.displayHeight + 24,
      "chevron-circle"
    )
      .setRotation(3.14)
      .setInteractive()
      .on("pointerdown", () => {
        this.toggle();
      });
    this.toggleBtn.setX(
      this.background.displayWidth - this.toggleBtn.width / 2 - 16
    );
    this.add(this.toggleBtn);

    this.inputRectangle = new Phaser.GameObjects.Rectangle(
      scene,
      16,
      -16,
      this.background.displayWidth - 32,
      32,
      0xffffff
    )
      .setOrigin(0, 1)
      .setInteractive();

    this.inputStr = "";
    this.inputText = new Phaser.GameObjects.Text(
      scene,
      this.inputRectangle.x + 8,
      this.inputRectangle.y - this.inputRectangle.height / 2,
      "",
      { color: "black", fontSize: "12px" }
    );
    this.inputText.setOrigin(0, 0.5);

    this.add(this.inputRectangle);
    this.add(this.inputText);

    scene.add.existing(this);

    this.messages = [];

    this.messagesContainer = new Phaser.GameObjects.Container(
      scene,
      0,
      0
    ).setVisible(this.isOpen);
    this.add(this.messagesContainer);

    this.feedCounterText = new Phaser.GameObjects.Text(
      scene,
      this.toggleBtn.x + 26,
      this.toggleBtn.y - 26,
      "0",
      { color: "white", fontSize: "12px", fontFamily: "Roboto-Medium" }
    );
    this.feedCounterText.setOrigin(0.5, 0.5);

    this.feedCounterBadge = new Phaser.GameObjects.Graphics(scene);
    this.feedCounterBadge.fillStyle(0xc52a1a);
    this.feedCounterBadge.fillCircle(0, 0, 14);
    this.feedCounterBadge.setPosition(
      this.feedCounterText.x,
      this.feedCounterText.y
    );

    this.add(this.feedCounterBadge);
    this.add(this.feedCounterText);

    this.showFeedCounter(false);

    this.scrollBar = new ScrollBar(scene, 0, -60, 170);
    this.scrollBar.setX(
      16 + this.inputRectangle.width - this.scrollBar.background.width
    );
    this.scrollBar.setVisible(this.isOpen);
    this.scrollBar.setOnScroll((y: number) => {
      this.setScroll(y);
    });
    this.add(this.scrollBar);

    this.drawFeed();

    this.addEventListeners();
  }

  private toggle() {
    if (this.isOpen) {
      this.toggleBtn.setRotation(3.14);
      this.background.setDisplaySize(
        this.background.displayWidth,
        this.closedHeight
      );
    } else {
      this.toggleBtn.setRotation(0);
      this.background.setDisplaySize(
        this.background.displayWidth,
        this.openHeight
      );
      this.feedCounter = 0;
      this.scroll(-this.messagesContainer.y);
    }

    this.toggleBtn.setY(-this.background.displayHeight + 24);
    this.title.setY(this.toggleBtn.y);
    this.isOpen = !this.isOpen;
    this.messagesContainer.setVisible(this.isOpen);
    this.scrollBar.setVisible(this.isOpen);

    this.setFeedCounterPosition(this.toggleBtn.y - 26);
    this.redrawFeedCounter();
  }

  private addMessage(msg: string) {
    this.messages.push(msg);
    this.drawFeed();
    this.feedCounter++;
    this.redrawFeedCounter();
  }

  private populate(messages: string[]) {
    this.messages = [];
    messages.forEach((message) => {
      this.messages.push(message);
    });
    this.drawFeed();
    this.feedCounter = 0;
    this.redrawFeedCounter();
  }

  private redrawFeedCounter() {
    this.feedCounterText.setText(
      `${this.feedCounter <= 20 ? this.feedCounter : "20+"}`
    );
    this.showFeedCounter(!(this.feedCounter === 0));
  }

  private drawFeed() {
    this.messagesContainer.removeAll(true);
    this.messagesContainer.height = 0;

    let message: string = "";
    for (let i = this.messages.length - 1; i >= 0; i--) {
      message = `${this.messages[i]}\r\n${message}`;
    }

    let feedText = new Phaser.GameObjects.Text(this.scene, 16, -70, message, {
      color: "white",
      fontFamily: "Arial",
      wordWrap: {
        width: this.background.displayWidth - 48,
        useAdvancedWrap: true,
      },
      lineSpacing: 12,
    }).setOrigin(0, 0);
    feedText.setY(-70 - feedText.height);
    this.messagesContainer.add(feedText);

    if (this.messages.length > 0) {
      this.messagesContainer.height = feedText.height + 12;
    }

    this.scrollBar.setBounds(
      0,
      Math.max(0, this.messagesContainer.height - this.scrollHeight)
    );
  }

  private addEventListeners() {
    EventBridge.on("ui.addToLiveFeed", (msg: string) => {
      if (this.scene.game.env === "game") {
        this.addMessage(msg);
        return;
      }

      let time = new Date().toLocaleTimeString("en-uk", {
        hour: "2-digit",
        minute: "2-digit",
      });
      this.addMessage(`${time}: ${msg}`);
    });

    EventBridge.on("ui.populateLiveFeed", (messages: string[]) => {
      this.populate(messages);
    });

    this.inputRectangle.on("pointerdown", () => {
      this.toggleFocus(true);
    });
    this.scene.input.on("pointerdown", (pointer: any, objects: [any]) => {
      if (!objects.some((object) => this.inputRectangle)) {
        this.toggleFocus(false);
      }
    });

    this.scene.input.keyboard?.on("keydown", (e: KeyboardEvent) =>
      this.handleKeyboardInput(e)
    );
  }

  private removeEventListeners() {
    EventBridge.remove("ui.addToLiveFeed");
    EventBridge.remove("ui.populateLiveFeed");
    this.scene.input.removeListener("pointerdown");
    this.scene.input.keyboard?.removeListener("keydown");
  }

  public destroy(fromScene?: boolean | undefined): void {
    this.removeEventListeners();
    super.destroy(fromScene);
  }

  private toggleFocus(focus?: boolean) {
    this.isFocused = focus ?? !this.isFocused;

    if (this.isFocused) {
      this.inputRectangle.alpha = 0.5;
    } else {
      this.inputRectangle.alpha = 1;
    }
  }

  handleKeyboardInput(e: KeyboardEvent) {
    if (this.isFocused) {
      e.preventDefault();
      if (e.key === "Backspace") {
        this.inputStr = this.inputStr.slice(0, -1);
        return;
      }
      if (e.key === "Enter") {
        // TODO: sending msg
        if (this.scene.game.env === "preview") {
          EventBridge.emit("ui.addToLiveFeed", `You: ${this.inputStr}`);
        } else {
          EventBridge.emit("game.addToFeed", this.inputStr);
        }
        this.inputStr = "";
        return;
      }
      if (e.key.length !== 1) {
        return;
      }
      this.inputStr += e.key;
    }
  }

  setY(value?: number | undefined): this {
    super.setY(value);
    if (this.maskShape) {
      this.maskShape.destroy();
    }

    let width = this.background.displayWidth;
    let height = 170;
    let x = 20;
    let y = value! - height - 60;
    this.scrollHeight = height;
    this.maskShape = new Phaser.GameObjects.Graphics(this.scene)
      .fillStyle(0xffffff, 0.1)
      .fillRect(x, y, width, height);

    // uncomment to enable scrolling with the mouse wheel.
    // but has a problem with not being able to click object under the livefeed.

    // this.maskShape.setInteractive(new Phaser.Geom.Rectangle(x, y, width, height), Phaser.Geom.Rectangle.Contains);

    // this.maskShape.on("wheel", (pointer: any, deltaX:number, deltaY: number) => {
    //   this.scroll(-deltaY);
    // });

    // this.scene.input.setDraggable(this.maskShape);

    // uncomment to debug the maskshape.
    // this.scene.add.existing(this.maskShape);
    let mask = this.maskShape.createGeometryMask();
    this.messagesContainer.setMask(mask);

    return this;
  }

  showFeedCounter(show: boolean) {
    this.feedCounterBadge.setVisible(show && this.feedCounter > 0);
    this.feedCounterText.setVisible(!this.isOpen && this.feedCounter > 0);
  }

  setFeedCounterPosition(y: number) {
    this.feedCounterText.setY(y);
    this.feedCounterBadge.setY(this.feedCounterText.y);
  }

  scroll(y: number) {
    let scrollY = this.messagesContainer.y + y;

    // bounds
    scrollY = Math.min(
      scrollY,
      this.messagesContainer.height - this.scrollHeight
    );
    scrollY = Math.max(scrollY, 0);

    this.messagesContainer.setY(scrollY);
    this.scrollBar.setScroll(-scrollY);
  }

  setScroll(y: number) {
    if (y < 20) {
      this.feedCounter = 0;
      this.redrawFeedCounter();
    }
    this.messagesContainer.setY(y);
  }

  updateInputText() {
    if (this.inputStr.length > 32) {
      const slicedStr = this.inputStr.slice(this.inputStr.length - 32);
      const displayStr = `...${slicedStr.slice(2)}`;
      this.inputText.setText(displayStr);
      return;
    }
    this.inputText.setText(this.inputStr);
  }
}
