import { Link, useNavigate } from "react-router-dom";

const SignedOut = () => {
  setTimeout(() => {
    window.location.href = "/login";
  }, 1000);

  return (
    <>
      <h1>You have been signed out.</h1>
      <p>Redirecting to login page...</p>
    </>
  );
};
export default SignedOut;
