import {
  Badge,
  Button,
  Input,
  Modal,
  Panel,
  Select,
  TextArea,
} from "@appkit4/react-components";
import { IRoom } from "escape-rooms-types/types/game";
import { useLocation, useNavigate } from "react-router-dom";
import { Draggable } from "react-drag-reorder";
import styles from "./GameSettings.module.scss";
import { useCallback, useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { useGameStore } from "@/store/game-store";
import { createRoom, getGame, updateGame, deleteRoom } from "@/api/game";
import { isValidColorInput } from "@/utils/helpers";
import { SketchPicker } from "react-color";
import useOnOutsideClick from "@/hooks/useOnOutsideClick";
import { colorPickerIndex } from "@/types/game";
import { createGameSession } from "@/api/session";
import { industries } from "@/constants";
import { c } from "msw/lib/glossary-de6278a9";

function delay(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

interface DeleteRoomInputs {
  gameId: string;
  roomId: string;
}

const GameSettings = () => {
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const game = useGameStore((state) => state.game);
  const id = useGameStore((state) => state.game._id);
  const name = useGameStore((state) => state.game.name);
  const industry = useGameStore((state) => state.game.industry);
  const rooms = useGameStore((state) => state.game.rooms);
  const colors = useGameStore((state) => game.colorPalette);
  const {
    setGame,
    setGameField,
    setRooms,
    setRoomFields,
    setCurrentRoomIndex,
    setGameColor,
  } = useGameStore((state) => state);
  const queryClient = useQueryClient();
  const colorPickerRef = useRef(null);
  const [showRoomDeleteModal, setShowRoomDeleteModal] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [targetRoomId, setTargetRoomId] = useState<string | undefined>(
    undefined
  );
  const [roomBeingEditedIndex, setRoomBeingEditedIndex] = useState<
    number | undefined
  >(undefined);
  const [oldName, setOldName] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorPickerIndex, setColorPickerIndex] = useState<number>(0);
  const [popupCoords, setPopupCoords] = useState({ x: 0, y: 0 });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const gameId = location.pathname.split("/").at(-1);
  const published = game.status === "published";

  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(gameId!),
    onSuccess: (res) => {
      loadGame(res.data.data);
    },
  });

  const createSessionMutation = useMutation({
    mutationKey: "createSession",
    mutationFn: () => createGameSession({ gameId: game._id }),
    onSuccess: (res) => {
      return navigate(`/sessions/${res.data.data._id}`);
    },
  });

  const saveGameMutation = useMutation({
    mutationKey: "saveGame",
    mutationFn: () => updateGame(gameId!, game),
    onSuccess: (res) => {
      setUnsavedChanges(false);
      return queryClient.invalidateQueries({ queryKey: ["getGame"] });
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const createRoomMutation = useMutation({
    mutationKey: "createRoom",
    mutationFn: () => createRoom(gameId!, generateRoom()),
    onSuccess: (res) => {
      getGame(gameId!).then((res) => {
        if (res.data.data) {
          setGame({ ...game, rooms: res.data.data.rooms });
        }
      });
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const deleteRoomMutation = useMutation({
    mutationKey: "deleteRoom",
    mutationFn: (variables: DeleteRoomInputs) =>
      deleteRoom(variables.gameId, variables.roomId),
    onSuccess: (res) => {
      getGame(gameId!).then((res) => {
        if (res.data.data) {
          setGame({ ...game, rooms: res.data.data.rooms });
        }
      });
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const loadGame = (gameData: any) => {
    if (gameData == null) {
      return;
    }
    setGame(gameData);
  };

  const saveGame = () => {
    saveGameMutation.mutate();
  };

  const generateRoom = (): IRoom => {
    let roomName = "New Room";
    let roomCounter = 0;

    if (rooms.some((room) => room.name.startsWith(roomName))) {
      rooms.forEach((room) => {
        let roomDigits = room.name.match(/\d+/);
        if (roomDigits) {
          roomCounter = Math.max(roomCounter, Number(roomDigits[0]));
        }
      });

      roomName = `${roomName} (${roomCounter + 1})`;
    }

    return {
      name: roomName,
      ref: `${uuidv4()}`,
      objects: [],
      length: 1,
      order: rooms.length,
      isActive: true,
      items: [],
    };
  };

  const onAddRoom = () => {
    createRoomMutation.mutate();
  };

  const onDeleteRoom = (gameId: string, roomId: string) => {
    deleteRoomMutation.mutate({ gameId, roomId });
  };

  const openRoom = (roomId: string) => {
    return navigate(`/games/${id}/rooms/${roomId}`);
  };

  const onReorderRoom = (oldPosition: number, newPosition: number) => {
    const roomsWithChangedIndex = [...rooms];
    roomsWithChangedIndex.splice(newPosition, 0, roomsWithChangedIndex.splice(oldPosition, 1)[0]);

    const roomsWithChangedIndexAndOrderProp = roomsWithChangedIndex.map((room, index) => ({ ...room, order: index }));
    setRooms(roomsWithChangedIndexAndOrderProp);
  };

  const toggleRoomIsActive = (room: IRoom) => {
    const roomIndex = rooms.findIndex((r) => r._id === room._id);
    setRoomFields({ roomIndex, isActive: !rooms[roomIndex].isActive });
  };

  const editRoomName = (index: number, oldName: string) => {
    setOldName(oldName);
    setRoomBeingEditedIndex(index);
  };

  const updateRoomName = (index: number, name: string) => {
    setRoomFields({ roomIndex: index, name });
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Escape") {
      updateRoomName(index, oldName);
      setRoomBeingEditedIndex(undefined);
      blur();
    }
    if (event.key === "Enter") {
      updateRoomName(index, event.target.value);
      setRoomBeingEditedIndex(undefined);
      blur();
    }
  };

  const publishGame = () => {
    setGameField({ status: "published" });
  };

  useOnOutsideClick(colorPickerRef, () => setShowColorPicker(false));

  const DraggableRooms = useCallback(
    ({ published }: { published: boolean }) => {
      return (
        <Draggable
          onPosChange={(oldPosition, newPosition) => {
            if (published) {
              return;
            }
            onReorderRoom(oldPosition, newPosition);
            setUnsavedChanges(true);
          }}
        >
          {rooms.map((room: IRoom, index: number) => (
            <div
              key={room._id}
              className={`${styles.room_card} ${
                // TODO: fix this line of code once they add room.isActive to BE
                room.isActive != null && !room.isActive ? styles.disabled : ""
              }`}
            >
              <div className={styles.room_card_header}>
                <img src="/icons/drag.svg" />
                <span className="ap-typography-3 ap-font-weight-2">
                  {roomBeingEditedIndex == index ? (
                    <div className={styles.room_card_buttons}>
                      <input
                        className={`${styles.roomNameInput} ap-typography-3`}
                        defaultValue={room.name}
                        title="Room Name"
                        autoFocus
                        onBlur={(e) => {
                          updateRoomName(index, e.target.value);
                          setRoomBeingEditedIndex(undefined);
                        }}
                        maxLength={40}
                        onKeyDown={(e) => {
                          handleKeyDown(e, index);
                          setUnsavedChanges(true);
                        }}
                        disabled={published}
                      />
                    </div>
                  ) : (
                    <>{room.name}</>
                  )}
                </span>
              </div>
              <div className={styles.room_card_buttons}>
                {roomBeingEditedIndex != index && (
                  <Button
                    className="Appkit4-icon icon-edit-outline"
                    kind="secondary"
                    onClick={() => {
                      editRoomName(index, room.name);
                      setUnsavedChanges(true);
                    }}
                    disabled={published}
                  />
                )}
                {/* <Button
                className="Appkit4-icon icon-view-off-outline"
                kind="secondary"
                onClick={() => {
                  toggleRoomIsActive(room);
                  setUnsavedChanges(true)
                }}
                disabled={rooms.length === 1}
              /> */}
                <Button
                  className="Appkit4-icon icon-delete-fill"
                  kind="negative"
                  onClick={() => {
                    setShowRoomDeleteModal(true);
                    setTargetRoomId(room._id);
                    setUnsavedChanges(true);
                  }}
                  disabled={published}
                ></Button>
                <Button
                  className="Appkit4-icon icon-setting-fill"
                  onClick={() => {
                    if (unsavedChanges == false) {
                      setShowUnsavedChangesModal(true);
                      openRoom(room._id);
                      return;
                    }
                    setTargetRoomId(room._id);
                    setShowUnsavedChangesModal(true);
                  }}
                ></Button>
              </div>
            </div>
          ))}
        </Draggable>
      );
    },
    [rooms, roomBeingEditedIndex]
  );

  useEffect(() => {
    let fn = (e: DragEvent) => {
      e.preventDefault();
    };

    document.addEventListener("dragover", fn);
    document.addEventListener("dragenter", fn);

    return () => {
      document.removeEventListener("dragover", fn);
      document.removeEventListener("dragstart", fn);
    };
  }, []);

  useEffect(() => {
    if (published) {
      saveGameMutation.mutate();
    }
  }, [published]);

  const createSession = () => {
    createSessionMutation.mutate();
  };

  const generateColorPickerRow = (index: number, published: boolean) => {
    const btnStyle = { backgroundColor: "buttonface" };
    if (isValidColorInput(colors[index])) {
      btnStyle.backgroundColor = `#${colors[index]}`;
    }
    if (colors[index] === "") {
      btnStyle.backgroundColor = "buttonface";
    }

    return (
      <div className="row ap-my-spacing-6">
        <div className={`col-6 ${styles.colorContainer}`}>
          <button
            className={`${styles.btn} ${published ? styles.disabled : ""}`}
            onClick={(e) => {
              setShowColorPicker(true);
              setColorPickerIndex(index);
              setPopupCoords({ x: e.clientX, y: e.clientY });
            }}
            style={btnStyle}
            disabled={published}
          />
          <Input
            className={styles.input}
            title={intl.formatMessage({ id: `game.form.color${index + 1}` })}
            value={`${colors[index]}`}
            onChange={(value: string) => {
              setGameColor({ index, color: value });
              setUnsavedChanges(true);
            }}
            disabled={published}
            error={!isValidColorInput(colors[index])}
            errorNode={
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                <FormattedMessage id="game.form.color.error" />
              </div>
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="ap-container">
      <div className="row">
        <h2 className="ap-typography-4 ap-font-weight-3 ap-mt-spacing-6 ap-mb-spacing-6">
          {intl.formatMessage({ id: "game.configure.game" })}
        </h2>
        <div className="col-6">
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
            <div className="row ap-my-spacing-6">
              <div className="col-6">
                <Input
                  title={intl.formatMessage({ id: "game.form.name" })}
                  value={name}
                  onChange={(value: string) => {
                    setUnsavedChanges(true);
                    setGameField({ name: value });
                  }}
                  disabled={published}
                />
              </div>
              <div className="col-6">
                <Select
                  placeholder={intl.formatMessage({ id: "game.form.industry" })}
                  data={industries}
                  onSelect={(value: string) => {
                    setGameField({ industry: value });
                  }}
                  value={industry}
                  disabled={published}
                />
              </div>
            </div>
            <div className="row ap-my-spacing-6">
              <div className="col-6">
                <Input
                  title={intl.formatMessage({ id: "game.form.client" })}
                  value={game.client}
                  onChange={(e) => {
                    setGame({ ...game, client: e });
                    setUnsavedChanges(true);
                  }}
                  disabled={published}
                />
              </div>
              {/* <div className="col-6">
                <Select
                  placeholder={intl.formatMessage({ id: "game.form.status" })}
                  data={[
                    { label: "Published", value: "published" },
                    { label: "Draft", value: "draft" },
                  ]}
                  value={game.status}
                  onSelect={(e) => setGame({ ...game, status: e })}
                />
              </div> */}
            </div>

            <div className="row">
              <div className="col-12">
                <TextArea
                  maxLength={420}
                  title="Additional notes"
                  value={game.description}
                  onChange={(e) => {
                    setGame({ ...game, description: e });
                    setUnsavedChanges(true);
                  }}
                  disabled={published}
                />
              </div>
            </div>

            <div className="row ap-my-spacing-6">
              <h3>Brand colours (optional)</h3>
            </div>
            {game?.colorPalette?.map((color, index) =>
              generateColorPickerRow(index, published)
            )}
            <div className="row ap-my-spacing-6">
              <div className="col-12">
                <div className={styles.footerConfigureGame}>
                  <p>
                    Created on{" "}
                    {new Date(game?.createdAt!).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <p>
                    Last edited on{" "}
                    {new Date(game?.updatedAt!).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                </div>
              </div>
            </div>
          </Panel>
          <div className={`ap-my-spacing-4 ${styles.buttonContainer}`}>
            <Button
              icon="icon-gameroom-outline"
              onClick={() => setShowPublishModal(true)}
              disabled={published}
            >
              <FormattedMessage id="game.button.publish_game" />
            </Button>
            <Button
              icon="icon-play-outline"
              onClick={createSession}
              disabled={!published}
            >
              <FormattedMessage id="game.button.create_session" />
            </Button>
          </div>
        </div>
        <div className="col-6">
          <div className={styles.room_container}>
            <DraggableRooms published={published} />
          </div>
          <div className={`ap-my-spacing-4 ${styles.buttonContainer}`}>
            <Button
              icon="icon-plus-outline"
              onClick={() => {
                onAddRoom();
              }}
              disabled={published}
            >
              <FormattedMessage id="game.button.add_room" />
            </Button>
          </div>
        </div>
      </div>
      <Modal
        visible={showRoomDeleteModal}
        title={"Delete this room?"}
        ariaLabel={"Delete this room?"}
        onCancel={() => {
          setShowRoomDeleteModal(false);
          setTargetRoomId(undefined);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        header={<Badge type="danger" value="Warning"></Badge>}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowRoomDeleteModal(false);
                setTargetRoomId(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                if (targetRoomId) {
                  onDeleteRoom(gameId!, targetRoomId);
                }
                setShowRoomDeleteModal(false);
                setTargetRoomId(undefined);
              }}
            >
              Delete Room
            </Button>
          </div>
        }
      >
        <p>
          Removing this room will delete all objects and interactions created in
          it. This cannot be undone. Are you sure you want to proceed?
        </p>
      </Modal>
      <Modal
        visible={showUnsavedChangesModal}
        title={"Leave this page?"}
        ariaLabel={"Leave this page?"}
        onCancel={() => {
          setShowUnsavedChangesModal(false);
          setTargetRoomId(undefined);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        header={<Badge type="primary" value="Warning"></Badge>}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                if (targetRoomId) {
                  openRoom(targetRoomId);
                }
              }}
            >
              Continue
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                setShowUnsavedChangesModal(false);
                setTargetRoomId(undefined);
              }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <p>
          Are you sure you want to continue? Any unsaved changes will be lost.
        </p>
      </Modal>
      {showColorPicker && (
        <div
          className={styles.colorPicker}
          style={{ top: popupCoords.y, left: popupCoords.x }}
          ref={colorPickerRef}
        >
          <SketchPicker
            color={game.colorPalette[colorPickerIndex]}
            onChangeComplete={(color: any) => {
              setGameColor({
                index: colorPickerIndex,
                color: color.hex.split("#")[1],
              });
              setUnsavedChanges(true);
            }}
            disableAlpha={true}
          />
        </div>
      )}
      <Modal
        visible={showPublishModal}
        title={intl.formatMessage({ id: "game.publish.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "game.publish.modal.title" })}
        onCancel={() => {
          setShowPublishModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowPublishModal(false);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              kind="primary"
              icon="icon-gameroom-outline"
              onClick={() => {
                publishGame();
                setShowPublishModal(false);
              }}
            >
              <FormattedMessage id="game.publish.modal.confirm" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="game.publish.modal.text" />
        </p>
      </Modal>
    </div>
  );
};

export default GameSettings;
