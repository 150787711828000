import ObjectActions from "./ObjectActions";

const ObjectConfig = () => {
  return (
    <>
      <ObjectActions />
    </>
  );
};

export default ObjectConfig;
