import CreatorGame from "@/components/phaser-game/CreatorGame";
import styles from "@/components/room-editor/RoomEditor.module.scss";
import ObjectConfig from "@/components/object-config/ObjectConfig";
import ObjectProperties from "@/components/object-properties/ObjectProperties";
import AssetsPanel from "../assets-panel/AssetsPanel";
import {
  Button,
  Badge,
  Input,
  Panel,
  Modal,
  Loading,
} from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { useGameStore } from "@/store/game-store";
import { useParams } from "react-router-dom";
import RoomSlider from "../room-slider/RoomSlider";
import EventBridge from "@/utils/EventBridge";
import { useMutation, useQuery } from "react-query";
import { IRoom } from "escape-rooms-types/types/game";
import { getGame, updateGame } from "@/api/game";
import { SCREEN_WIDTH, autosaveInterval } from "@/constants";
import { refreshToken } from "@/api";
import { renderNotification } from "../notification/renderFunctions";

interface UpdateRoomInputs {
  gameId: string;
  roomId: string;
  room: IRoom;
}

const RoomEditor = () => {
  const params = useParams();
  const {
    game,
    currentRoomIndex,
    assets,
    setGame,
    setRoomFields,
    setCurrentRoomIndex,
    setSelectedObjectIndex,
    updateObjectPosition,
    removeObjectFromRoom,
  } = useGameStore((state) => state);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [showDeleteUnitModal, setShowDeleteUnitModal] = useState(false);
  const [autosaveEnabled, setAutosaveEnabled] = useState(true);
  const gameId = params.gameId;
  const roomId = params.roomId;

  const getGameQuery = useQuery({
    queryKey: ["getGame", gameId],
    queryFn: () => getGame(gameId!),
    enabled: false,
    onSuccess: (res) => {
      setGame(res.data.data);
      setCurrentRoomIndex(roomId!);
    },
  });

  const saveGameMutation = useMutation({
    mutationKey: ["saveGame", game._id],
    mutationFn: () => updateGame(game._id, game),
    onSuccess: (res) => {
      renderNotification("Game has been saved (Autosave).", "success");
    },
    onError: (err) => {
      console.error(err);
    },
  });

  useEffect(() => {
    const refreshTokenEvery10Mins = setInterval(() => {
      refreshToken();
    }, 1000 * 10 * 60);

    // NOTE: temp autofix disable for demo - to investigate why not working later

    // let autosaveTimer: NodeJS.Timeout;
    //
    // autosaveTimer = setInterval(() => {
    //     saveGameMutation.mutate();
    //     }, autosaveInterval);

    return () => {
      clearInterval(refreshTokenEvery10Mins);
      // clearInterval(autosaveTimer);
    };
  }, []);

  useEffect(() => {
    if (game.name === "_initialGame") {
      return;
    }
    // Add event listeners
    EventBridge.on(
      "roomEditor.updateObjectPosition",
      ({ objectRef, xPos, yPos }) => {
        updateObjectPosition(objectRef, xPos, yPos);
      }
    );

    EventBridge.on("roomEditor.selectObject", ({ objectRef }) => {
      // get object index
      const objectIndex = game.rooms[currentRoomIndex].objects.findIndex(
        (object) => object.ref == objectRef
      );
      setSelectedObjectIndex(objectIndex, objectRef);
    });

    // Don't fetch game unless it doesn exist. This stop coming back from
    // preview deleting changes.
    if (game == null || game._id == "_initialGame") {
      getGameQuery.refetch();
    }

    return () => {
      EventBridge.remove("roomEditor.selectObject");
      EventBridge.remove("roomEditor.updateObjectPosition");
    };
  }, [game]);

  useEffect(() => {
    if (roomId != game.rooms[currentRoomIndex]?._id) {
      getGameQuery.refetch();
    }
  }, [roomId]);

  const onAddUnit = () => {
    const newMaxLength = game.rooms[currentRoomIndex].length + 1;
    setRoomFields({
      roomIndex: currentRoomIndex,
      length: newMaxLength,
    });
    setSliderValue(newMaxLength - SCREEN_WIDTH);
  };

  const onDeleteUnit = () => {
    const newMaxLength = game.rooms[currentRoomIndex].length - 1;
    deleteObjectsInDeletedUnit();
    setRoomFields({
      roomIndex: currentRoomIndex,
      length: newMaxLength,
    });
    setSliderValue(newMaxLength - SCREEN_WIDTH);
  };

  const deleteObjectsInDeletedUnit = () => {
    const room = game.rooms[currentRoomIndex];
    const length = game.rooms[currentRoomIndex].length;
    const xPosOfLengthStart = (length - 1) * SCREEN_WIDTH;
    const xPosOfLengthEnd = length * SCREEN_WIDTH;

    room.objects.forEach((object) => {
      if (object.xPos > xPosOfLengthStart && object.xPos < xPosOfLengthEnd) {
        removeObjectFromRoom(object);
      }
    });
  };

  if (game.rooms[currentRoomIndex] == null) {
    return (
      <>
        <Loading loadingType="circular" indeterminate={true} />
      </>
    );
  }

  if (getGameQuery.isFetching) {
    return (
      <>
        <Loading loadingType="circular" indeterminate={true} />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div className="ap-container">
        <div className="row">
          <div className={`col-2 ${styles["pr-0"]}`}>
            <AssetsPanel />
          </div>
          <div className={`col-8 ${styles.flex}`}>
            <div className={`row`}>
              <div className={`col-12 ${styles.flex}`}>
                <CreatorGame assets={assets} />
                <RoomSlider
                  sliderValue={sliderValue}
                  setSliderValue={setSliderValue}
                />
                <Panel
                  className={`ap-my-spacing-6 ${styles.removeBottomMargin}`}
                  title="Set room size (1 unit = 1 screen width)"
                >
                  <div className={styles.unitContainer}>
                    <Input
                      className={styles.unitInput}
                      value="Unit 1 (default)"
                      readonly
                    />
                    {Array.from({
                      length: game.rooms[currentRoomIndex].length - 1,
                    }).map((room, index) => (
                      <Input
                        key={index}
                        className={styles.unitInput}
                        value={`Unit ${index + 2}`}
                        readonly
                        suffix={
                          index == game.rooms[currentRoomIndex].length - 2 && (
                            <span
                              className={`Appkit4-icon icon-delete-fill ${styles.unitInputButton}`}
                              onClick={() => setShowDeleteUnitModal(true)}
                            ></span>
                          )
                        }
                      />
                    ))}

                    <Button
                      icon="icon-plus-outline"
                      onClick={onAddUnit}
                      disabled={game.rooms[currentRoomIndex].length >= 5}
                    >
                      Add Unit
                    </Button>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
          <div className={`col-2 ${styles["pl-0"]}`}>
            <ObjectProperties />
          </div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-10">
            <ObjectConfig />
          </div>
        </div>
      </div>
      <Modal
        visible={showDeleteUnitModal}
        title={"Delete this room?"}
        ariaLabel={"Delete this room?"}
        onCancel={() => {
          setShowDeleteUnitModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        header={<Badge type="danger" value="Warning"></Badge>}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowDeleteUnitModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              kind="negative"
              onClick={() => {
                setShowDeleteUnitModal(false);
                onDeleteUnit();
              }}
            >
              Delete Unit
            </Button>
          </div>
        }
      >
        <p>
          This will delete the last length unit from this room. All objects in
          this section of the room will be deleted. Are you sure you want to
          proceed?
        </p>
      </Modal>
    </div>
  );
};

export default RoomEditor;
