import UIScene from "@/phaser/scenes/UIScene";
import IconButton from "../../ui/buttons/IconButton";
import QuestionnairePlayer from "./QuestionnairePlayer";
import PlayerAvatarContainer from "./PlayerAvatarContainer";
import { IQuestion } from "escape-rooms-types/types/game";
import MultipleChoiceQuestion from "../question/MultipleChoiceQuestion";
import WordMatchQuestion from "../question/WordMatchQuestion";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import { generateFakePlayers } from "@/constants";
import GameScene from "@/phaser/scenes/GameScene";
import PreviewScene from "@/phaser/scenes/PreviewScene";
import config from "@/../config";
import EventBridge from "@/utils/EventBridge";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";

export default class QuestionnaireModal extends Phaser.GameObjects.Group {
  scene: UIScene;
  sourceScene: GameScene | PreviewScene;
  actionId: string;
  background: Phaser.GameObjects.Image;
  modalIcon: IconButton;
  title: Phaser.GameObjects.Text;
  subheader: Phaser.GameObjects.Text;
  players: QuestionnairePlayer[];
  playerAvatarContainer: PlayerAvatarContainer;
  questions: IQuestion[];
  currentQuestion: number;
  //@ts-ignore
  questionnaireLocation: Phaser.GameObjects.Text;
  currentQuestionContent?: MultipleChoiceQuestion | WordMatchQuestion;
  questionnaireCompletedMessage?: Phaser.GameObjects.Container;
  sourceObject: PreviewRoomObject | GameRoomObject;
  callback: () => void;
  attemptsTaken: number = 1;

  constructor(
    scene: UIScene,
    actionId: string,
    questions: IQuestion[],
    sourceObject: PreviewRoomObject | GameRoomObject,
    callback: () => void,
    activeQuestionnaire?: any
  ) {
    super(scene);
    this.scene = scene;
    this.sourceScene = scene.sourceScene;
    this.actionId = actionId;
    this.questions = questions;
    this.sourceObject = sourceObject;
    this.players = [];
    this.callback = callback;
    this.setDepth(1002);
    this.renderPlayers = this.renderPlayers.bind(this);
    this.completeQuestion = this.completeQuestion.bind(this);

    if (activeQuestionnaire && activeQuestionnaire.currentActiveQuestionId) {
      this.currentQuestion = questions.findIndex(
        (q) => q._id == activeQuestionnaire.currentActiveQuestionId
      );
    } else {
      this.currentQuestion = 0;
    }

    this.background = new Phaser.GameObjects.Image(
      scene,
      scene.renderer.width / 2,
      scene.renderer.height / 2,
      "modalBackground"
    );
    this.background
      .setDisplaySize(scene.renderer.width, scene.renderer.height)
      .setInteractive();
    this.scene.add.existing(this.background);
    this.add(this.background);

    this.modalIcon = new IconButton(
      scene,
      scene.renderer.width - 40,
      40,
      "help",
      () => this.scene.openUserMenu() // TODO: What do we want this help button to do?
    );
    this.scene.add.existing(this.modalIcon);
    this.add(this.modalIcon);

    this.title = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      75,
      "TEAM QUIZ!",
      { fontFamily: "Nunito-Bold", fontSize: "24px" }
    );
    Phaser.Display.Align.In.TopCenter(this.title, this.background, 0, -50);
    this.scene.add.existing(this.title);
    this.add(this.title);

    this.subheader = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      150,
      "All players need to participate by submitting their answers in order to proceed.",
      { fontFamily: "Roboto-Medium", fontSize: "19px" }
    );
    Phaser.Display.Align.In.TopCenter(this.subheader, this.background, 0, -85);
    this.scene.add.existing(this.subheader);
    this.add(this.subheader);

    this.renderPlayers();

    this.renderQuestionLocationHeader();
    // @ts-ignore
    this.add(this.questionnaireLocation);
    this.addEventListeners();
    this.renderQuestion();

    if (activeQuestionnaire && activeQuestionnaire.finishedPlayers) {
      activeQuestionnaire.finishedPlayers.forEach((playerId) => {
        this.players.find((player) => player.id === playerId)?.markAsFinished();
      });

      if (
        activeQuestionnaire.finishedPlayers.includes(
          this.sourceScene.session.myPlayer.id
        )
      ) {
        this.currentQuestionContent?.disableInput();
      }
    }
  }

  renderPlayers() {
    const players =
      this.scene.env === "preview"
        ? generateFakePlayers(3)
        : this.sourceScene.session.players;

    const x = this.scene.renderer.width / 2;
    const y = this.scene.renderer.height / 2 - 300;

    this.players = players
      .filter((p) => p.inactive === false)
      .map((player, index) => {
        return new QuestionnairePlayer(this.scene, player, index * 42, 0);
      });

    this.playerAvatarContainer = new PlayerAvatarContainer(
      this.scene,
      x,
      y,
      this.players
    ).setDepth(1003);

    Phaser.Display.Align.In.TopCenter(
      this.playerAvatarContainer,
      this.background,
      (this.players.length - 1) * -20,
      -150
    );

    // TODO: don't create any thing about players in the individual challenge
    this.scene.add.existing(this.playerAvatarContainer);
    this.add(this.playerAvatarContainer);

    if (this.scene.env === "game") {
      if (this.sourceScene.session == null) {
        return;
      }
      if (this.sourceScene.session.activeQuestionnaire == null) {
        return;
      }

      const finishedPlayers =
        this.sourceScene.session.activeQuestionnaire.finishedPlayers;

      if (finishedPlayers.length > 0) {
        finishedPlayers.forEach((playerId) => {
          this.players
            .find((player) => player.id === playerId)
            ?.markAsFinished();
        });
      }
    }
  }

  rerenderPlayers() {
    this.playerAvatarContainer.destroy();
    this.renderPlayers();
  }

  renderQuestionLocationHeader() {
    this.questionnaireLocation?.destroy(true);
    this.questionnaireLocation = new Phaser.GameObjects.Text(
      this.scene,
      this.scene.renderer.width / 2,
      180,
      `Question ${this.currentQuestion + 1} of ${this.questions.length}`,
      { fontFamily: "Nunito-Bold", fontSize: "24px" }
    ).setDepth(1002);

    Phaser.Display.Align.In.TopCenter(
      this.questionnaireLocation,
      this.background,
      0,
      -200
    );
    this.add(this.scene.add.existing(this.questionnaireLocation));
  }

  renderQuestion() {
    const question = this.questions[this.currentQuestion];

    if (question.type === "multipleChoice") {
      this.currentQuestionContent = new MultipleChoiceQuestion(
        this.scene,
        this,
        question,
        this.completeQuestion
      ).setDepth(1004);
    }
    if (question.type === "textAnswer") {
      this.currentQuestionContent = new WordMatchQuestion(
        this.scene,
        this,
        question,
        this.completeQuestion
      ).setDepth(1004);
    }

    this.markAllAsUnfinished(this.players);
  }

  markPlayerAsFinished(players: QuestionnairePlayer[], playerId: string) {
    // Mark all as finished for now
    const player = players.find((p) => p.id === playerId);
    if (!player) {
      console.error("Player not found");
      return;
    }
    player.markAsFinished();
  }

  markAllAsFinished(players: QuestionnairePlayer[]) {
    players.forEach((player) => player.markAsFinished());
  }

  markAllAsUnfinished(players: QuestionnairePlayer[]) {
    players.forEach((player) => player.markAsUnfinished());
  }

  completeQuestion() {
    this.markAllAsFinished(this.players);
    setTimeout(() => this.goToNextQuestion(), 1500);
  }

  goToNextQuestion() {
    if (this.currentQuestion === this.questions.length - 1) {
      this.completeQuestionnaire();
      return;
    }
    this.currentQuestionContent!.destroy(true, true);
    this.currentQuestion += 1;
    this.renderQuestionLocationHeader();
    this.renderQuestion();
    this.rerenderPlayers();
  }

  completeQuestionnaire(sourcePlayerId?: string) {
    this.removeEventListerners();
    this.currentQuestionContent!.destroy(true);
    this.questionnaireLocation.destroy(true);
    this.questionnaireCompletedMessage = new Phaser.GameObjects.Container(
      this.scene,
      this.scene.renderer.width / 2, // test this
      250
    ).setDepth(1002);
    this.questionnaireCompletedMessage.add([
      new Phaser.GameObjects.Text(this.scene, 0, 0, "TEAM QUIZ COMPLETED!", {
        fontFamily: "Nunito-Bold",
        fontSize: "35px",
      }).setOrigin(0.5, 0.5),
      new Phaser.GameObjects.Text(
        this.scene,
        0,
        50,
        "All players  have submitted their answers",
        {
          fontFamily: "Nunito-Bold",
          fontSize: "19px",
        }
      ).setOrigin(0.5, 0.5),
    ]);
    this.scene.add.existing(this.questionnaireCompletedMessage);
    this.add(this.questionnaireCompletedMessage);

    const leaveButton = new IconButton(
      this.scene,
      this.scene.renderer.width / 2,
      this.scene.renderer.height / 2,
      "back-to-game",
      () => this.closeModal(this)
    );
    Phaser.Display.Align.In.Center(leaveButton, this.background);
    this.scene.add.existing(leaveButton);
    this.add(leaveButton);
    leaveButton.setDepth(1002);

    this.title.destroy();
    this.subheader.destroy();

    this.markAllAsFinished(this.players);

    this.sourceObject.removeGlow();

    // @ts-ignore
    if (this.scene.game.env === "preview") {
      this.awardXP();
      (this.sourceObject as PreviewRoomObject).completeAction();
      return;
    }

    if (this.scene.game.env === "game") {
      (this.sourceObject as GameRoomObject).completeAction(sourcePlayerId!);
      return;
    }
  }

  completeChallenge() {
    this.callback();
    this.closeChallenge();
  }

  closeChallenge() {
    this.currentQuestionContent!.destroy(true);
    this.questionnaireLocation.destroy(true);
    this.closeModal(this);
  }

  closeModal(questionnaire: QuestionnaireModal) {
    this.destroyAvatars();
    this.scene.activeQuestionnaire = undefined;
    this.destroy(true, true);
  }

  destroyAvatars() {
    this.playerAvatarContainer.destroy();
  }

  incremenentAttemptsTaken() {
    this.attemptsTaken++;
  }

  awardXP() {
    const scoringArray = config.scoring.questionnaire;
    let score: number;

    if (this.attemptsTaken > scoringArray.length) {
      score = 0;
    } else {
      score = scoringArray[this.attemptsTaken - 1];
    }

    if (this.scene.env == "preview") {
      EventBridge.emit("ui.showXPAlert", score);
      EventBridge.emit("ui.addToTeamScore", score);
      EventBridge.emit("ui.addToPlayerScore", score);
    }
  }

  addEventListeners() {
    if (this.sourceScene instanceof GameScene) {
      const scene = this.sourceScene as GameScene;

      scene.game.socket.on("markUserAsDone", (payload) => {
        this.rerenderPlayers();
        const playersDone = payload.playersDone;
        Object.entries(playersDone).forEach(([playerId, isDone]) => {
          if (isDone) {
            this.markPlayerAsFinished(this.players, playerId);
          }
        });
      });

      scene.game.socket.on("leaveSession", async (payload) => {
        await scene.session!.refetchSession();
      });

      scene.game.socket.on("joinSession", async (payload) => {
        await scene.session!.refetchSession();
      });

      EventBridge.on("session.refetched", () => {
        this.rerenderPlayers();
      });

      scene.game.socket.on("nextQuestion", (payload) => {
        this.currentQuestionContent!.waitingForOtherPlayers = true
        this.players.forEach((player) => player.markAsFinished());
        setTimeout(() => {
          this.goToNextQuestion();
        }, 1000);
      });

      scene.game.socket.once("questionnaireFinished", (payload) => {
        this.currentQuestionContent!.waitingForOtherPlayers = true
        if (payload.actionId !== this.actionId) {
          return;
        }
        const scene = this.sourceScene as GameScene;
        const myScore = payload.scores.playerScores[scene.user.id!];
        const increase = payload.scores.playerScoreIncreases[scene.user.id!];
        EventBridge.emit("ui.setScore", {
          playerScore: myScore,
          teamScore: payload.scores.teamScore,
        });
        this.players.forEach((player) => player.markAsFinished());

        setTimeout(() => {
          this.completeQuestionnaire(payload.sourcePlayerId);
          if (increase > 0) {
            EventBridge.emit("ui.showXPAlert", increase);
          }
        }, 1000);
      });
    }
  }

  removeEventListerners() {
    if (this.sourceScene instanceof GameScene) {
      const game = this.sourceScene.game as PhaserGameClass;
      game.socket.off("markUserAsDone");
      game.socket.off("incorrectPlayerAnswer");
      game.socket.off("nextQuestion");
      game.socket.off("questionnaireFinished");
      EventBridge.remove("session.refetched");
    }
  }
}
