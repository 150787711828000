import UIScene from "@/phaser/scenes/UIScene";
import IconButton from "../../ui/buttons/IconButton";
import { IQuestion } from "escape-rooms-types/types/game";
import MultipleChoiceQuestion from "../question/MultipleChoiceQuestion";
import WordMatchQuestion from "../question/WordMatchQuestion";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import config from "@/../config";
import EventBridge from "@/utils/EventBridge";
import GameScene from "@/phaser/scenes/GameScene";

export default class ChallengeModal extends Phaser.GameObjects.Group {
  scene: UIScene;
  background: Phaser.GameObjects.Image;
  modalIcon: IconButton;
  title: Phaser.GameObjects.Text;
  subheader: Phaser.GameObjects.Text;
  question: IQuestion;
  sourceObject: PreviewRoomObject | GameRoomObject;
  actionId?: string;
  continueCallback: () => void;
  exitCallback: () => void;
  currentQuestionContent?: MultipleChoiceQuestion | WordMatchQuestion;
  attemptsTaken: number = 1;

  constructor(
    scene: UIScene,
    question: IQuestion,
    sourceObject: PreviewRoomObject | GameRoomObject,
    continueCallback: () => void,
    exitCallback: () => void = () => {},
    actionId?: string,
  ) {
    super(scene);
    this.scene = scene;
    this.question = question;
    this.sourceObject = sourceObject;
    this.actionId = actionId;
    this.continueCallback = continueCallback;
    this.exitCallback = exitCallback;

    this.background = new Phaser.GameObjects.Image(
      scene,
      scene.renderer.width / 2,
      scene.renderer.height / 2,
      "modalBackground"
    );
    this.background
      .setDisplaySize(scene.renderer.width, scene.renderer.height)
      .setInteractive();
    this.scene.add.existing(this.background);
    this.add(this.background);

    this.modalIcon = new IconButton(
      scene,
      scene.renderer.width - 40,
      40,
      "close-modal",
      () => this.exitChallenge()
    );
    this.scene.add.existing(this.modalIcon);
    this.add(this.modalIcon);

    this.title = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      75,
      "INDIVIDUAL CHALLENGE",
      { fontFamily: "Nunito-Bold", fontSize: "24px" }
    );
    Phaser.Display.Align.In.TopCenter(this.title, this.background, 0, -50);
    this.scene.add.existing(this.title);
    this.add(this.title);

    this.subheader = new Phaser.GameObjects.Text(
      scene,
      scene.renderer.width / 2,
      150,
      "Answer the question to proceed",
      { fontFamily: "Roboto-Medium", fontSize: "19px" }
    );
    Phaser.Display.Align.In.TopCenter(this.subheader, this.background, 0, -85);
    this.scene.add.existing(this.subheader);
    this.add(this.subheader);

    this.renderQuestion();
  }

  renderQuestion() {
    if (this.question.type === "multipleChoice") {
      this.currentQuestionContent = new MultipleChoiceQuestion(
        this.scene,
        this,
        this.question,
        () => this.completeChallenge(),
        this.sourceObject.getThumbnailFromState(this.sourceObject.currentState)
      );
    }
    if (this.question.type === "textAnswer") {
      this.currentQuestionContent = new WordMatchQuestion(
        this.scene,
        this,
        this.question,
        () => this.completeChallenge(),
        this.sourceObject.getThumbnailFromState(this.sourceObject.currentState)
      );
    }
  }

  incremenentAttemptsTaken() {
    this.attemptsTaken++;
  }

  awardXP() {
    const scoringArray = config.scoring.challenge;
    let score: number;

    if (this.attemptsTaken > scoringArray.length) {
      score = 0;
    } else {
      score = scoringArray[this.attemptsTaken - 1];
    }

    if (this.scene.env == "preview") {
      EventBridge.emit("ui.showXPAlert", score);
      EventBridge.emit("ui.addToTeamScore", score);
      EventBridge.emit("ui.addToPlayerScore", score);
    }

    if (this.scene.env === "game") {
      const gameScene = this.scene.sourceScene as GameScene;
      gameScene.game.socketEmit("addToPlayerScore", {
        sessionId: gameScene.game.session._id,
        playerId: gameScene.user.id,
        score,
      });
    }
  }

  completeChallenge() {
    this.awardXP();
    this.continueCallback();
    this.closeChallenge();
  }

  exitChallenge() {
    this.exitCallback();
    this.closeChallenge();
  }

  closeChallenge() {
    this.currentQuestionContent!.destroy(true);
    this.closeModal(this);
  }

  closeModal(modal: ChallengeModal) {
    this.destroy(true, true);
  }
}
