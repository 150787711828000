import UIScene from "@/phaser/scenes/UIScene";
import MessagePopup from "../MessagePopup";

export default class RoomCompleteScreen extends Phaser.GameObjects.Group {
  scene: UIScene;
  modal: MessagePopup;
  callback: () => void;

  constructor(scene: UIScene, callback: () => void, lastRoom: boolean = false) {
    super(scene);
    this.scene = scene;
    this.callback = callback;

    const modalText = lastRoom
      ? "CONGRATULATIONS!\n\nYou have escaped the final room and completed the game.\nWait here to see your results!"
      : "LEVEL COMPLETED!\n\nWell done, you have cleared this room!\nGet ready for the next one...";

    this.modal = new MessagePopup(
      scene,
      modalText,
      null,
      callback,
      true,
      true,
      "endRoom"
    );

    this.modal.depth = 2000;
    this.add(scene.add.existing(this.modal));
  }
}
