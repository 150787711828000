import { Directions } from "@/constants";
import {
  IAssetState,
  IRoomObject,
  IAsset,
} from "escape-rooms-types/types/game";
import EventBridge from "@/utils/EventBridge";
import CreatorRoomObject from "@/phaser/classes/RoomObject/CreatorRoomObject";
import { IRoomEditorObject, TObjectOrderDirection } from "@/types/game";
import { useGameStore } from "@/store/game-store";
import BaseScene from "./BaseScene";
import { loadRoomObjectsIntoSceneWithCustomColors } from "@/utils/phaser-helpers";
import { CreatorPhaserGame } from "@/components/phaser-game/CreatorGame";

export default class CreatorScene extends BaseScene {
  public roomObjects: { [key: string]: CreatorRoomObject };
  public targetRoomObject?: CreatorRoomObject;
  public selectedObject?: CreatorRoomObject;
  public blackScreen?: Phaser.GameObjects.Rectangle;

  constructor() {
    super("creator-scene");
    this.roomObjects = {};
    this.selectedObject = undefined;
  }

  async preload() {
    this.blackScreen = this.add.rectangle(0, 0, 10000, 10000, 0x000000);
    this.blackScreen.depth = 1000;

    // Load other non-object assets
    this.load.svg("lock", "/icons/lock-closed.svg");
  }

  create() {
    useGameStore.getState().setSelectedObjectIndex(undefined);
    useGameStore.getState().setCurrentXPosition(0);

    this.input.on(
      "drag",
      function (pointer: any, gameObject: any, dragX: any, dragY: any) {
        gameObject.x = dragX;
        gameObject.y = dragY;
      }
    );

    useGameStore
      .getState()
      .game.rooms[useGameStore.getState().currentRoomIndex].objects.forEach(
        (object) => {
          const roomObject = new CreatorRoomObject(
            this,
            object.xPos,
            object.yPos,
            object
          );
          this.roomObjects[roomObject.ref] = roomObject;
        }
      );

    this.addEventListeners();
    this.events.once("destroy", this.removeEventListeners);

    this.tweens.add({
      targets: [this.blackScreen],
      alpha: 0,
      duration: 1500,
    });
  }

  update() {}

  addEventListeners() {
    EventBridge.on("creator.addObjectToRoom", (object: IRoomObject) => {
      const roomObject = new CreatorRoomObject(
        this,
        object.xPos,
        object.yPos,
        object
      );
      this.roomObjects[roomObject.ref] = roomObject;
      this.selectObject(object.ref);
    });

    EventBridge.on("creator.selectObject", (objectRef: string) => {
      this.selectObject(objectRef);
    });

    EventBridge.on("creator.unselectObject", (objectRef: string) => {
      this.targetRoomObject = this.roomObjects[objectRef];
      this.targetRoomObject.removeGlow();
      this.targetRoomObject = undefined;
    });

    EventBridge.on("creator.removeObjectFromRoom", (object: IRoomObject) => {
      this.targetRoomObject = this.roomObjects[object.ref];
      this.targetRoomObject.destroy();
    });

    EventBridge.on(
      "creator.changeObjectOrder",
      (payload: { object: IRoomObject; direction: TObjectOrderDirection }) => {
        this.targetRoomObject = this.roomObjects[payload.object.ref];
        switch (payload.direction) {
          case Directions.FORWARD:
            this.children.moveUp(this.targetRoomObject.sprite);
            break;
          case Directions.BACKWARD:
            this.children.moveDown(this.targetRoomObject.sprite);
            break;
          case Directions.FRONT:
            this.children.bringToTop(this.targetRoomObject.sprite);
            break;
          case Directions.BACK:
            this.children.sendToBack(this.targetRoomObject.sprite);
            break;
        }
      }
    );

    EventBridge.on("creator.updateObject", (object: IRoomEditorObject) => {
      this.targetRoomObject = this.roomObjects[object.ref];
      this.targetRoomObject.updateObject(object);
    });

    EventBridge.on("creator.toggleObjectLock", (object: IRoomObject) => {
      this.targetRoomObject = this.roomObjects[object.ref];
      this.targetRoomObject.toggleIsDisabled();
    });

    EventBridge.on("cameraMove", ({ xScroll }: { xScroll: number }) => {
      if (this.cameras.main == null) {
        console.error("No camera");
        return;
      }
      const yScroll = 0;
      this.cameras.main.setScroll(xScroll, yScroll);
      useGameStore.getState().setCurrentXPosition(xScroll);
    });
  }

  removeEventListeners() {
    EventBridge.remove("creator.addObjectToRoom");
    EventBridge.remove("creator.removeObjectFromRoom");
    EventBridge.remove("creator.selectObject");
    EventBridge.remove("creator.unselectObject");
    EventBridge.remove("creator.changeObjectOrder");
    EventBridge.remove("creator.updateObject");
    EventBridge.remove("creator.toggleObjectLock");
    EventBridge.remove("cameraMove");
  }

  selectObject(objectRef: string) {
    // If reselecting same object
    if (objectRef === this.targetRoomObject?.ref) {
      return;
    }

    // If something was previously selected blur it
    if (this.targetRoomObject != null) {
      this.targetRoomObject.blur();
    }

    this.targetRoomObject = this.roomObjects[objectRef];
    this.targetRoomObject.focus();
  } 
}
