import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextArea,
} from "@appkit4/react-components";
import {
  IAction,
  IQuestion,
  IChoices,
  IRoomObject,
} from "escape-rooms-types/types/game";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../ObjectConfigAction.module.scss";

interface IActionDisplayChallengeProps {
  action: IAction;
  selected: IRoomObject;
  updateActionProperties: (payload: Partial<IAction>) => void;
  setWarning: (warning: boolean) => void;
}

const ActionDisplayChallenge = ({
  action,
  selected,
  updateActionProperties,
  setWarning,
}: IActionDisplayChallengeProps) => {
  const intl = useIntl();
  const [showDeleteObjectModal, setShowDeleteObjectModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<number | undefined>(
    undefined
  );

  const addQuestion = () => {
    let ref = action.questionnaire?.ref ?? uuidv4();
    updateActionProperties({
      questionnaire: {
        ref,
        questions: [
          ...(action.questionnaire?.questions || []),
          {
            ref: uuidv4(),
            type: "textAnswer",
            choices: {
              ref: uuidv4(),
              answers: ["", ""],
              correctAnswer: 0,
            },
          },
        ],
      },
    });
  };

  const removeQuestion = (index: number) => {
    if (!action.questionnaire?.questions) return;
    const newQuestions = [...action.questionnaire.questions];
    newQuestions.splice(index, 1);

    updateActionProperties({
      questionnaire: {
        ref: action.questionnaire.ref,
        questions: [...newQuestions],
      },
    });
  };

  const updateQuestion = (payload: Partial<IQuestion>, index: number) => {
    if (!action.questionnaire?.questions) return;
    const oldQuestion = action.questionnaire.questions[index];
    if (!oldQuestion) return;

    const newQuestion: IQuestion = {
      ref: oldQuestion.ref,
      type: payload.type ?? oldQuestion.type,
      choices: payload.choices ?? oldQuestion.choices,
      answer: payload.answer ?? oldQuestion.answer,
      title: payload.title ?? oldQuestion.title,
    };

    const newQuestionsList = action.questionnaire?.questions.map(
      (question, targetIndex) =>
        index === targetIndex ? newQuestion : question
    );

    updateActionProperties({
      questionnaire: {
        ref: action.questionnaire.ref,
        questions: newQuestionsList,
      },
    });
  };

  const updateAnswer = (
    newAnswer: string,
    answerIndex: number,
    questionIndex: number
  ) => {
    const choices = action.questionnaire?.questions?.[questionIndex].choices;
    if (!choices) return;

    const answer = choices.answers[answerIndex];

    const newAnswers = [...choices.answers];
    let newCorrectAnswer = undefined;

    if (answer === undefined) {
      newAnswers.push(newAnswer);
    } else {
      if (newAnswer === "") {
        if (answerIndex > 1) {
          newAnswers.splice(answerIndex, 1);
          if (choices.correctAnswer >= answerIndex) {
            newCorrectAnswer = choices.correctAnswer - 1 || 0;
          }
        } else {
          newAnswers[answerIndex] = "";
        }
      } else {
        newAnswers[answerIndex] = newAnswer;
      }
    }

    updateQuestion(
      {
        choices: {
          ref: choices.ref,
          correctAnswer: newCorrectAnswer ?? choices.correctAnswer,
          answers: newAnswers,
        },
      },
      questionIndex
    );
  };

  useEffect(() => {
    if ((action.questionnaire?.questions?.length || 0) == 0) {
      addQuestion();
    }
  }, []);

  useEffect(() => {
    // validate action.
    let invalid = false;

    if (action.questionnaire?.questions?.length == 0) {
      invalid = true;
    }

    action.questionnaire?.questions?.forEach((question) => {
      if (question.type === "multipleChoice") {
        question.choices?.answers.forEach((answer) => {
          if (answer.replace(/\s+/g, "") === "") {
            invalid = true;
          }

          if (question.choices?.correctAnswer === undefined) {
            invalid = true;
          }
        });
      } else {
        if (!question.answer || question.answer?.replace(/\s+/g, "") === "") {
          invalid = true;
        }
      }
    });

    setWarning(invalid);
  }, [action]);

  return (
    <>
      {action.questionnaire?.questions?.map((question, index) => (
        <>
          <div className="ap-typography-5 ap-font-weight-2 ap-my-spacing-6">
            Question {index + 1}
          </div>
          <div className={styles.divider}></div>
          <div className="col-6">
            <span className="input-label">What kind of questionnaire?</span>
            <Select
              placeholder=""
              data={[
                { label: "Word Match", value: "textAnswer" },
                { label: "Multiple choice", value: "multipleChoice" },
              ]}
              value={question.type}
              onSelect={(e) => updateQuestion({ type: e }, index)}
            />
          </div>
          {/* <div className="ap-my-spacing-6 flex gap-1">
            <Switch />
            <span>Display asset image</span>
          </div> */}
          <div className="col-12 ap-my-spacing-4">
            <span className="input-label">Question</span>
            <TextArea
              value={question.title}
              onChange={(e) => {
                updateQuestion({ title: e }, index);
              }}
              maxLength={500}
            />
          </div>
          <div className="col-12 ap-my-spacing-4">
            {question.type === "multipleChoice" && (
              <>
                <div className="ap-mb-spacing-4">
                  Answers (select correct answer)
                </div>
                <RadioGroup
                  className={styles.answersContainer}
                  value={question.choices?.correctAnswer}
                  onChange={(e: number) =>
                    updateQuestion(
                      {
                        choices: {
                          ref: question.choices?.ref || uuidv4(),
                          correctAnswer: e,
                          answers: question.choices?.answers || [],
                        },
                      },
                      index
                    )
                  }
                >
                  {Array.from({ length: 6 }).map((_, answerIndex) => (
                    <div className="answer">
                      <Radio
                        value={answerIndex}
                        disabled={
                          answerIndex >= (question.choices?.answers.length || 0)
                        }
                      >
                        <span className="input-label">
                          {String.fromCharCode(65 + answerIndex)}
                        </span>
                      </Radio>
                      <TextArea
                        className={styles.answerField}
                        maxLength={20}
                        disabled={
                          answerIndex > (question.choices?.answers.length || 0)
                        }
                        value={question.choices?.answers[answerIndex] || ""}
                        onChange={(e: string) => {
                          updateAnswer(e, answerIndex, index);
                        }}
                      />
                    </div>
                  ))}
                </RadioGroup>
              </>
            )}
            {question.type === "textAnswer" && (
              <TextArea
                className={styles.answerField}
                maxLength={40}
                value={question.answer || ""}
                onChange={(e: string) => {
                  updateQuestion({ answer: e }, index);
                }}
              />
            )}
          </div>
        </>
      ))}
    </>
  );
};

export default ActionDisplayChallenge;
