import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Register.module.scss";
import { Button, Input } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import ClassNames from "classnames";
import { useMutation } from "react-query";
import { RegisterUserData, registerUser } from "@/api/auth";

const renderInput = (
  title: string,
  onChange: (e) => void,
  value: string,
  error: string | undefined,
  disabled: boolean = false,
) => {
  return (
    <Input
      type={"text"}
      title={title}
      required={true}
      value={value.trim()}
      onChange={onChange}
      disabled={disabled}
      error={error}
      onKeyDown={(event: any) => { if (event.code === 'Space') event.preventDefault()}}
      errorNode={
        <div
          id="errormessage"
          aria-live="polite"
          className={`ap-field-email-validation-error ${styles.error}`}
        >
          {error}
        </div>
      }
    ></Input>
  );
};

const renderPasswordInput = (
  title: string,
  onChange: (e) => void,
  submit: () => void,
  value: string,
  showPrompts: boolean,
  showErrors: boolean,
  passwordError?: string
) => {
  return (
    <div
      className={`ap-field-demo-wrapper ap-field-password ${styles.password}`}
    >
      <Input
        type={"password"}
        title={title}
        value={value.trim()}
        onChange={onChange}
        required={true}
        onKeyDown={(event: any) => {
          if (event.code === 'Space') event.preventDefault()
          if (event.code === "Enter") {
            submit();
          }
        }
      }
        errorNode={
          showErrors && (
            <div
              id="errormessage"
              aria-live="polite"
              className={`ap-field-email-validation-error ${styles.error}`}
            >
              {passwordError}
            </div>
          )
        }
        error={passwordError}
      >
        {showPrompts && (
          <div
            aria-hidden="true"
            className={`ap-field-password-creator ${styles.dark}`}
            data-mode="dark"
          >
            <span
              className={ClassNames({
                highlight: value && value.match(/[A-Z]/g),
              })}
            >
              <span className="Appkit4-icon icon-circle-checkmark-fill"></span>
              <span className="ap-field-password-condition">Capital</span>
            </span>
            <span
              className={ClassNames({
                highlight: value && value.match(/[0-9]/g),
              })}
            >
              <span className="Appkit4-icon icon-circle-checkmark-fill"></span>
              <span className="ap-field-password-condition">Number</span>
            </span>
            <span
              className={ClassNames({
                highlight: value && value.match(/[\.\@\$\!\%*\#\_\~\?\&\^]/g),
              })}
            >
              <span className="Appkit4-icon icon-circle-checkmark-fill"></span>
              <span className="ap-field-password-condition">
                Special character
              </span>
            </span>
            <span
              className={ClassNames({
                highlight: value && value.match(/^.{8,}$/g),
              })}
            >
              <span className="Appkit4-icon icon-circle-checkmark-fill"></span>
              <span className="ap-field-password-condition">Length</span>
            </span>
          </div>
        )}
        <div aria-live="polite" className="ap-field-password-condition-sr-only">
          <span>
            {value && value.match(/[A-Za-z]/g) ? "Not " : ""}Contains letter
          </span>
          <span>
            {value && value.match(/[0-9]/g) ? "Not " : ""}contains number
          </span>
          <span>
            {value && value.match(/[\.\@\$\!\%\*\#\_\~\?&\^]/g) ? "Not " : ""}
            contains special character
          </span>
        </div>
      </Input>
    </div>
  );
};

const Register = () => {
  const [params] = useSearchParams();
  const registrationToken = params.get("registrationToken");
  const emailParam = params.get("email");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [surnameError, setSurnameError] = useState<string | undefined>(
    undefined
  );
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  );

  const registerUserMutation = useMutation({
    mutationKey: "registerUser",
    mutationFn: (data: RegisterUserData) => registerUser(data),
    onSuccess: (res) => {
      navigate("/login");
    },
    onError: (err) => {
      navigate(
        `/error?status=${err.response.status}&message=${err.response.data.message}&title=${err.response.data.error}`
      );
    },
  });

  const register = () => {
    if (name === "") {
      setNameError("Name is required");
      return;
    }
    if (surname === "") {
      setSurnameError("Surname is required");
      return;
    }
    if (email === "") {
      setEmailError("Email is required");
      return;
    }
    if (password1 !== password2) {
      setPasswordError("Passwords do not match");
      return;
    }
    if (/^(?=.*[A-Z])/.test(password1) === false) {
      setPasswordError("Password must contain at least one capital letter");
      return;
    }
    if (/^(?=.*[!@#$%^&*])/.test(password1) === false) {
      setPasswordError("Password must contain at least one special character");
      return;
    }
    if (/^(?=.*[0-9])/.test(password1) === false) {
      setPasswordError("Password must contain at least one number");
      return;
    }
    if (password1.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return;
    }

    return registerUserMutation.mutate({
      firstName: name,
      lastName: surname,
      password: password1,
      email: email!,
      registrationToken: registrationToken!,
    });
  };

  useEffect(() => {
    if (!registrationToken) {
      navigate("/error?message=Invalid registration token");
    }
    if (!emailParam) {
      navigate("/error?message=Invalid email");
    }
    setEmail(emailParam);
  }, []);

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img src="/pwc-splash-logo.png" alt="" />
        </div>
        <div className={styles.containerTwo}>
          <div className={styles.containerThree}>
            <div className={styles.form}>
              <h2 className={styles.subheader}>Escape!</h2>
              <h1 className={styles.header}>Welcome! Please register.</h1>
              <div className={styles.buttons}>
                <div className={styles.input}>
                  {renderInput(
                    "Name",
                    (e) => {
                      setName(e);
                      setNameError(undefined);
                    },
                    name,
                    nameError
                  )}
                </div>
                <div className={styles.input}>
                  {renderInput(
                    "Surname",
                    (e) => {
                      setSurname(e);
                      setSurnameError(undefined);
                    },
                    surname,
                    surnameError
                  )}
                </div>
                <div className={styles.input}>
                  {renderInput(
                    "Email",
                    (e) => {
                      setEmail(e);
                    },
                    email,
                    emailError,
                    true
                  )}
                </div>
                <div className={styles.password}>
                  {renderPasswordInput(
                    "Password",
                    (e) => {
                      setPassword1(e);
                      setPasswordError(undefined);
                    },
                    register,
                    password1,
                    true,
                    false,
                    passwordError
                  )}
                </div>
                <div className={styles.password}>
                  {renderPasswordInput(
                    "Confirm Password",
                    (e) => {
                      setPassword2(e);
                      setPasswordError(undefined);
                    },
                    register,
                    password2,
                    false,
                    true,
                    passwordError
                  )}
                </div>
                <Button
                  kind="tertiary"
                  onClick={register}
                  className={styles.button}
                >
                  Sign Up
                </Button>
              </div>
            </div>
            <div className={styles.door}>
              <img src="/door-closed.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
